import { useEffect, useRef, Dispatch, SetStateAction } from 'react';
import cx from 'classnames';
import { ButtonBase, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import {
  minus as MinusIcon,
  plus as PlusIcon,
  trash as TrashIcon,
  plusV2 as PlusIconV2,
} from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import { useDelayedOpen } from '@hooks/useDelayedOpen';
import styles from './ProductTileCounter.module.scss';

interface Props {
  value: string;
  step?: number;
  couldBeRemoved?: boolean;
  plusDisabled?: boolean;
  onRemoveClick?: () => void;
  onMinusClick?: () => void;
  onPlusClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  size?: 'small' | 'medium' | 'large' | 'extraLarge';
  isCollapseAnimate?: boolean;
  isCollapsed?: boolean;
  isFullWidth?: boolean;
  className?: string;
  min?: number;
  max?: number;
  buttonArialLabel?: string;
  nonFocusable?: boolean;
  addBtnRef?: React.RefObject<HTMLButtonElement>;
  disableDelete?: boolean;
  counterInputId?: string;
  setLastAction?: Dispatch<SetStateAction<string>>;
  isV2?: boolean;
  disableRemoveButton?: boolean;
  isReplaceProduct?: boolean;
}

export const ProductTileCounter = ({
  value,
  couldBeRemoved,
  plusDisabled,
  isCollapseAnimate,
  isCollapsed,
  className,
  size = 'small',
  onRemoveClick,
  onMinusClick,
  onPlusClick,
  onChange,
  onBlur,
  step = 1,
  min = 1,
  max = 99,
  buttonArialLabel,
  nonFocusable,
  addBtnRef,
  disableDelete = false,
  counterInputId,
  setLastAction,
  isV2 = false,
  disableRemoveButton,
  isReplaceProduct,
}: Props) => {
  const { t } = useLocalization('components');
  const quantityInputRef = useRef<HTMLInputElement>(null);
  const minusBtnRef = useRef<HTMLButtonElement>(null);
  const productTileCounterRef = useRef<HTMLDivElement>(null);
  const disabled = nonFocusable || disableDelete;

  const isOpen = useDelayedOpen({ value, needsDelay: isV2 && !isReplaceProduct });

  const classNames = cx(styles.wrapper, styles[size], className, {
    [styles.collapsed]: isCollapseAnimate,
    [styles.collapse_animate]: isCollapseAnimate && !isCollapsed && isOpen,
    [styles.moreThen99]: Number(value) > 99,
  });

  useEffect(() => {
    if (isCollapsed) quantityInputRef.current?.blur();
  }, [isCollapsed]);

  const handleClick = (event: React.MouseEvent) => {
    (event.target as HTMLInputElement).focus();
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    onBlur?.(event);
  };

  const handleMouseLeave = () => {
    quantityInputRef.current?.focus();
    quantityInputRef.current?.blur();
  };

  const trashIconHeight = isV2 ? 11 : undefined;

  return (
    <div
      className={cx(classNames, {
        [styles.v2]: isV2,
      })}
      data-testid="simple-counter-wrapper"
      onMouseLeave={handleMouseLeave}
      ref={productTileCounterRef}
    >
      <Typography component="span" sx={visuallyHidden} aria-live="polite">
        {t('quantityCounter.ariaLabels.productQuantity', { productQuantity: value })}
      </Typography>
      {couldBeRemoved ? (
        <ButtonBase
          type="button"
          disableRipple={true}
          className={styles.button}
          aria-label={t('simpleCounter.ariaLabels.remove', { buttonArialLabel })}
          data-testid="simple-counter-remove"
          onClick={() => {
            setLastAction?.('remove');
            onRemoveClick?.();
          }}
          disabled={disabled || disableRemoveButton}
          ref={minusBtnRef}
        >
          <TrashIcon height={trashIconHeight} />
        </ButtonBase>
      ) : (
        <ButtonBase
          type="button"
          disableRipple={true}
          className={styles.button}
          aria-label={t('simpleCounter.ariaLabels.minus', { buttonArialLabel })}
          data-testid="simple-counter-reduce"
          onClick={() => {
            setLastAction?.('minus');
            onMinusClick?.();
          }}
          disabled={disabled}
          ref={minusBtnRef}
        >
          <MinusIcon />
        </ButtonBase>
      )}

      <div className={styles.input_wrapper}>
        <input
          id={counterInputId}
          value={value}
          min={min}
          max={max}
          ref={quantityInputRef}
          onChange={onChange}
          onClick={handleClick}
          onBlur={handleBlur}
          type="number"
          step={step}
          inputMode="numeric"
          aria-label={t('simpleCounter.ariaLabels.value', { buttonArialLabel })}
          data-testid="simple-counter-input"
          className={styles.input}
          disabled={nonFocusable}
        />
      </div>
      <ButtonBase
        ref={addBtnRef}
        id={counterInputId}
        type="button"
        disableRipple={true}
        className={cx(styles.button, styles.plus, {
          [styles.plus_buton]: isV2,
        })}
        aria-label={t('simpleCounter.ariaLabels.plus', { buttonArialLabel })}
        data-testid="simple-counter-add"
        disabled={plusDisabled || nonFocusable}
        onClick={() => {
          setLastAction?.('plus');
          onPlusClick?.();
        }}
      >
        {!isV2 && <PlusIcon />}
        {isV2 && <PlusIconV2 width={11} height={11} className={styles.plus_icon} />}
      </ButtonBase>
    </div>
  );
};
