import { useRouter } from 'next/router';
import { useAuthContext } from '@modules/auth/context';
import { Params, ParamsContext } from '@context/AddToCartContext/AddToCartContext.utils';
import { addTask } from '@modules/queue/queue';
import { routing } from '@constants/routing';
import { useHandleAddToCartAndTrack } from '@context/AddToCartContext/hooks/useAddToCartAndTrack';
import { usePrepareAndAddToCartWithPendingOrdersCheck } from '@context/AddToCartContext/hooks/usePrepareAndAddToCartWithPendingOrdersCheck';

export function useHandleAddToCart() {
  const { isKnownUser: isLoggedIn } = useAuthContext();
  const router = useRouter();
  const pageFromWhiteList =
    router.pathname === routing.cart ||
    router.pathname === routing.modifyOrder ||
    router.pathname?.includes(routing.orderConfirmation);

  const addToCartAndTrack = useHandleAddToCartAndTrack();
  const prepareAndAddToCartWithPendingOrdersCheck = usePrepareAndAddToCartWithPendingOrdersCheck();

  return async (params: Params & { paramsContext: ParamsContext }, callback?: () => void) => {
    await addTask(async () => {
      if (pageFromWhiteList || !isLoggedIn) {
        await addToCartAndTrack(params);
      } else {
        await prepareAndAddToCartWithPendingOrdersCheck(params);
      }
      if (callback) callback();
    });
  };
}
