/* eslint-disable @typescript-eslint/naming-convention */
import {
  getProductMetrics,
  ProductMetrics,
} from '@modules/ga/eventBodyGetters/utils/eCommerceEvent';
import { Product } from '@commons/product';
import { IGtmEvent } from '@modules/ga/gtm-event';

interface AtpReplaceOperation extends IGtmEvent {
  event: string;
  atp_replace: {
    items: ProductMetrics[];
    title_of_button: string;
    reason: string;
  };
}

interface AtpViewOperation extends IGtmEvent {
  event: string;
  atp_view: {
    items: ProductMetrics[];
    reason: string;
    subtotal: number;
  };
}

export interface AtpReplaceOptions {
  cartLineProduct: Product[];
  buttonTitle: string;
  reason: string;
}

export interface AtpViewOptions {
  cartLines: Product[];
  reason: string;
  subtotal: number;
}

export const getAtpReplaceEvent = ({
  cartLineProduct,
  buttonTitle,
  reason,
}: AtpReplaceOptions): AtpReplaceOperation => {
  return {
    event: 'atp_replace',
    atp_replace: {
      items: cartLineProduct?.map((product) => getProductMetrics({ ...product })) || [],
      title_of_button: buttonTitle,
      reason,
    },
  };
};

export const getAtpViewEvent = ({
  cartLines,
  reason,
  subtotal,
}: AtpViewOptions): AtpViewOperation => {
  return {
    event: 'atp_view',
    atp_view: {
      items: cartLines?.map((product) => getProductMetrics({ ...product })) || [],
      reason,
      subtotal,
    },
  };
};
