import { routing } from '@constants/routing';

// TODO: STAFF_PICKS, CANCEL_ORDER, STANDING_ORDER

/* eslint-disable @typescript-eslint/naming-convention */
export type PAGE_TYPE =
  | 'VIEW_CART'
  | 'SEARCH'
  | 'PRODUCT_DETAIL'
  | 'HOMEPAGE'
  | 'CHECKOUT'
  | 'RECEIPT'
  | 'SUPERDEPARTMENT_LIST'
  | 'DEPARTMENT_LIST'
  | 'CATEGORY_LIST'
  | 'TOP_ITEMS'
  | 'PRODUCT_LIST'
  | 'ORDER_DETAILS'
  | 'SECTION'
  | 'ORDER_HISTORY'
  | 'FRESH_DEALS'
  | 'HELP'
  | 'PAST_ORDERS'
  | 'ACCOUNT'
  | 'NEW_PRODUCTS'
  | 'FEED'
  | 'SALE'
  | 'E_COUPONS'
  | 'TOP_RATED'
  | 'DELIVERYPASS'
  | 'LOCAL'
  | 'ORDER_MODIFY'
  | 'SHOPPING_LISTS'
  | 'GIFT_CARDS'
  | 'NOT_RECOGNIZED';

export const PAGE_TYPE_ROUTES: { [key in PAGE_TYPE]: string[] } = {
  VIEW_CART: [routing.cart, '/expressco/view_cart'],
  SEARCH: [routing.search],
  PRODUCT_DETAIL: [routing.product, '/p/'],
  HOMEPAGE: [routing.home],
  CHECKOUT: [routing.checkout, '/expressco/checkout'],
  RECEIPT: [routing.orderConfirmation, '/expressco/success/'],
  SUPERDEPARTMENT_LIST: ['/superdepartment', '/sd/'],
  DEPARTMENT_LIST: [routing.department, '/d/'],
  CATEGORY_LIST: [routing.category, '/c/'],
  TOP_ITEMS: [routing.reorderItems],
  PRODUCT_LIST: [routing.products, routing.subcategory, '/sc/'],
  ORDER_DETAILS: [routing.orderDetails],
  SECTION: ['/section'],
  ORDER_HISTORY: [routing.orderHistory],
  FRESH_DEALS: [routing.freshDeals],
  HELP: [routing.help],
  PAST_ORDERS: [routing.reorderOrders],
  ACCOUNT: [
    routing.preferences,
    routing.delivery,
    routing.payment,
    routing.accountDeliveryPass,
    routing.credits,
    routing.account,
  ],
  NEW_PRODUCTS: [
    '/c/newproducts',
    '/c/new_products',
    '/category/newproducts',
    '/category/new_products',
    '/newproducts',
  ],
  FEED: ['/feed/'],
  SALE: ['/sale/', routing.allSales, routing.dealsAndSale],
  E_COUPONS: ['/ecoupon', routing.coupons],
  TOP_RATED: ['/c/top_rated', '/category/top_rated', '/subcategory/top_rated'],
  DELIVERYPASS: [routing.deliveryPass],
  LOCAL: ['/c/local', '/category/local', '/department/local'],
  ORDER_MODIFY: [routing.modifyOrder],
  SHOPPING_LISTS: ['/quickshop/shopping_lists'],
  GIFT_CARDS: [routing.giftCards, routing.giftCard],
  NOT_RECOGNIZED: [],
};
