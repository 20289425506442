import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { on } from '@modules/emitter';
import { TOpen } from '@modules/open';
import { TClose } from '@modules/close';
import { OptionalProductsData } from '@api';
import { ProductListTitleProvider } from '@modules/ga/context/title';
import { ProductListLocationProvider } from '@modules/ga/context/location';
import { OPTIONAL_ADDITIONS_MESSAGE } from './optionalProductsHelper';

const OptionalAdditionsModal = dynamic(() =>
  import('./components/OptionalAdditionsModal/OptionalAdditionsModal').then(
    (mod) => mod.OptionalAdditionsModal,
  ),
);

export interface OptionalAdditionsProps {
  product?: OptionalProductsData;
  channel?: string;
  location?: string;
  title?: string;
}

export const OptionalAdditionsHandler = () => {
  const [isOpen, setOpen] = useState(false);
  const [productOptionalAdditions, setProductOptionalAdditions] =
    useState<OptionalAdditionsProps>();

  useEffect(
    () =>
      on((action) => {
        if (action.type === TOpen && action.message === OPTIONAL_ADDITIONS_MESSAGE) {
          setOpen(true);
          setProductOptionalAdditions(action.data);
        }
        if (action.type === TClose && action.message === OPTIONAL_ADDITIONS_MESSAGE) {
          setOpen(false);
        }
      }),
    [],
  );

  const onClose = () => {
    setOpen(false);
  };

  return (
    <ProductListLocationProvider location={productOptionalAdditions?.location}>
      <ProductListTitleProvider title={productOptionalAdditions?.title}>
        <OptionalAdditionsModal
          title={productOptionalAdditions?.product?.title ?? ''}
          products={productOptionalAdditions?.product?.items ?? []}
          isOpen={isOpen}
          toggleClose={onClose}
          gaValues={{ channel: productOptionalAdditions?.channel }}
        />
      </ProductListTitleProvider>
    </ProductListLocationProvider>
  );
};
