import { RefObject, useContext, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { Popper, Skeleton } from '@mui/material';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import { useRouter } from 'next/router';
import { AutosuggestProduct, ProductTile, SelectedConfiguration } from '@commons/product';
import { TileCustomOrder } from '@components/Tiles/components/TileCustomOrder/TileCustomOrder';
import { isRestrictedProduct } from '@utils/isRestrictedProduct';
import { useDeleteFromCartLight } from '@hooks/cart/useDeleteFromCartLight';
import { ProductQuantityTooltip } from '@components/ProductQuantityTooltip/ProductQuantityTooltip';
import { AddToCartContext } from '@context/AddToCartContext/AddToCartContext';
import { Alert } from '@components/UI';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { useProductListContext } from '@context/ProductListContext';
import { sendBeacon } from '@hooks/criteo/useCriteoBeacon.utils';
import { COMPONENTGROUP_MEAL, PERISHABLE } from '@constants/product';
import { routing } from '@constants/routing';
import { GIFT_CARD_SKU_CODE } from '@components/ProductQuantitySelector/constants';
import { GaValuesProps } from '@modules/ga/context/channel';
import { ReplaceProductProps } from '@components/OrderList/components/ReplaceModal/ReplaceModal';
import { useReplaceCartItem } from '@hooks/cart/useReplaceCartItem';
import { getProductConfigurationVariables } from '@utils/getProductConfigurationVariables';
import { getAddToCartVariables } from '@hooks/cart/useAddToCart';
import { SALES_UNIT_ID_FIELD } from '@constants/salesUnits';
import { Cart } from '@commons/cart';
import { ProductTileCollapsableCounter } from './ProductTileCollapsableCounter';
import styles from './ProductTileQuantitySelector.module.scss';

interface ProductQuantitySelectorProps {
  product: ProductTile | AutosuggestProduct;
  productContainerRef?: RefObject<HTMLDivElement>;
  className?: string;
  onAddToCart?: () => void;
  onChangeQuantity?: (options: { delta: number; changeQuantityEventId: string }) => void;
  isReorder?: boolean;
  nonFocusable?: boolean;
  freeItem?: boolean;
  productCarouselType?: string;
  onChange?: (product?: ProductTile | AutosuggestProduct) => void;
  skipAddToCart?: boolean;
  isReorderItem?: boolean;
  isV2?: boolean;
  gaValues?: GaValuesProps;
  replaceProductInfo?: ReplaceProductProps;
}

const DEFAULT_QUANTITY = 0;

export const ProductTileQuantitySelector = ({
  product,
  className,
  onAddToCart,
  onChangeQuantity,
  isReorder,
  nonFocusable,
  freeItem = false,
  onChange,
  productCarouselType,
  skipAddToCart = false,
  isReorderItem,
  isV2 = false,
  gaValues,
  replaceProductInfo,
}: ProductQuantitySelectorProps) => {
  const router = useRouter();

  const setProductQuantityAbortController = useRef<AbortController | null>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  const {
    handleAddToCart: addToCart,
    cart: cartData,
    getProductInfoFromCart,
    setProductQuantityInCart,
    isSettingProductQuantity,
    addedProductId,
    setAddedProductId,
    lastAction,
    setLastAction,
  } = useContext(AddToCartContext);

  const { getProductPosition, fireRWProductAddToCartEvent } = useProductListContext();
  const [replaceCartItem] = useReplaceCartItem();

  const { getListName } = usePageListName();
  const [deleteFromCart, { loading: deleteFromCartLoading }] = useDeleteFromCartLight();

  const [cartLoading, setCartLoading] = useState(false);
  const [disabledProductId, setDisabledProductId] = useState('');
  const [atcErrors, setAtcErrors] = useState<string[]>([]);

  const { salesUnits } = product;
  const bundleType = product?.bundle?.type;
  const shouldTriggerReplaceEvent = !!replaceProductInfo && !replaceProductInfo.isReplaced;

  const uniqueProductId = useMemo(() => {
    return `${productCarouselType}_${product?.productId}_${getProductPosition(product?.productId)}`;
  }, [getProductPosition, product?.productId, productCarouselType]);

  const isCustomisableProduct =
    (!!product.variations?.length || salesUnits?.length > 1) &&
    (!bundleType || bundleType === PERISHABLE || bundleType === COMPONENTGROUP_MEAL);

  const { minQuantity: minQty, maxQuantity: maxQty } = product.quantity;

  const existProduct = useMemo(
    () => cartData.productCounter.find(({ productId }) => product.productId === productId),
    [cartData.productCounter, product.productId],
  );

  const [productExistsInCart, setProductExistsInCart] = useState(!!existProduct?.count);
  const productQuantityInCart = (existProduct?.count || DEFAULT_QUANTITY).toString();

  const { data: productCartInfo, loading } = getProductInfoFromCart(
    product.productId,
    product.soldBySalesUnit,
  );

  const [productQuantity, setProductQuantity] = useState(productQuantityInCart);
  const addBtnRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (productQuantityInCart.toString() === productQuantity) return;
    setProductQuantity(productQuantityInCart);
    // eslint-disable-next-line
  }, [productQuantityInCart]);

  const productQuantityDebounced = useDebounce<string>(productQuantity, 1000);

  useEffect(() => {
    setProductExistsInCart(!!existProduct?.count);
  }, [existProduct]);

  const shouldUpdateQuantity = () => {
    if (productQuantityDebounced === productQuantityInCart) return false;
    if (productQuantityDebounced === '') return false;
    if (productQuantity === '0') return false;
    if (Number(productQuantityDebounced) < minQty) return false;

    return Number(productQuantityDebounced) <= maxQty;
  };

  const refetchCartForReplaceUpdate = () => {
    if (!!replaceProductInfo) {
      replaceProductInfo?.setShouldRefetchCart?.(true);
    }
  };

  useUpdateEffect(() => {
    if (disabledProductId === product.productId && !isSettingProductQuantity) {
      setDisabledProductId('');
    }
  }, [isSettingProductQuantity]);

  useUpdateEffect(() => {
    if (!shouldUpdateQuantity()) return;
    if (!productCartInfo.cartLineId) return;

    setProductQuantityAbortController.current?.abort();
    setProductQuantityAbortController.current = new window.AbortController();
    const salesUnit = salesUnits?.[0]?.alternateSalesUnit ?? '';

    setDisabledProductId(product.productId);

    if (skipAddToCart) {
      onChange?.(product);
      setDisabledProductId('');
      return;
    }

    setProductQuantityInCart({
      variables: {
        productDataInput: {
          categoryId: product.categoryId,
          productId: product.productId,
          skuCode: product.skuCode,
          quantity: Number(productQuantityDebounced),
          itemListName: getListName(product, gaValues?.channel),
          itemPosition: getProductPosition(product.productId),
          salesUnit,
        },
      },
      context: { fetchOptions: { signal: setProductQuantityAbortController.current?.signal } },
      onCompleted: ({ changeCartItemQuantityLight }) => {
        if (changeCartItemQuantityLight) {
          setAtcErrors([]);
          onChange?.();
          onChangeQuantity?.({
            delta: Number(productQuantityDebounced) - Number(productQuantityInCart),
            changeQuantityEventId: changeCartItemQuantityLight.changeQuantityEventId ?? '',
          });
          setCartLoading(false);
          setDisabledProductId('');
        }

        refetchCartForReplaceUpdate();
      },
      onError: (err) => {
        setAtcErrors(
          err.graphQLErrors.map((e) => {
            if (Array.isArray(e.extensions?.messageArgs)) {
              return e.extensions?.messageArgs[0];
            }
            return e.message;
          }),
        );
        setProductQuantity(productQuantityInCart);
        setCartLoading(false);
        setDisabledProductId('');
      },
    });
  }, [productQuantityDebounced]);

  useUpdateEffect(() => {
    if (!shouldUpdateQuantity()) return;
    if (productCartInfo.cartLineId) return;

    setProductQuantityAbortController.current?.abort();
    setProductQuantityAbortController.current = new window.AbortController();

    setCartLoading(true);
    addBtnRef.current?.focus();

    if (skipAddToCart) {
      onChange?.(product);
      setCartLoading(false);
      return;
    }

    if (shouldTriggerReplaceEvent) {
      return handleReplaceEvent();
    }

    addToCart(
      {
        quantity: Number(productQuantityDebounced),
        product,
        eventSource: freeItem ? 'ps_carousel_view_cart' : 'pdp_main',
        paramsContext: {
          listName: getListName(product, gaValues?.channel),
          productIds: [product.productId],
          itemPosition: getProductPosition(product.productId),
        },
      },
      () => {
        refetchCartForReplaceUpdate();

        fireRWProductAddToCartEvent(
          {
            productId: product.productId,
            variantId: product.variantId,
          },
          gaValues?.channel,
        );
        setAddedProductId?.(uniqueProductId);

        if (product?.clickBeacon) {
          sendBeacon(product.clickBeacon);
        }
        setAtcErrors([]);
        onChange?.(product);
        setProductExistsInCart(true);
      },
    )
      .catch((err) => {
        setAtcErrors([err.message]);
        setProductExistsInCart(false);
      })
      .finally(() => {
        setCartLoading(false);
      });
  }, [productQuantityDebounced]);

  const actionHandler = () => {
    setAddedProductId?.(uniqueProductId);
    setCartLoading(true);
  };

  const handleQuantityChange = (quantity: string) => {
    setProductQuantityAbortController.current?.abort();
    setProductQuantity(quantity);
    actionHandler();
  };

  const handleClickRemove = () => {
    setProductQuantityAbortController.current?.abort();
    deleteFromCart({ variables: { cartLineId: productCartInfo.cartLineId } }).then(() => {
      onChange?.();

      refetchCartForReplaceUpdate();
    });
    setProductQuantity('0');
    actionHandler();
  };

  const handleClickMinus = () => {
    if (isSettingProductQuantity) {
      setDisabledProductId(product.productId);
    } else {
      setProductQuantityAbortController.current?.abort();
      setProductQuantity((Number(productQuantity) - product.quantity.quantityIncrement).toString());
      actionHandler();
    }
  };

  const handleClickPlus = () => {
    if (isSettingProductQuantity) {
      setDisabledProductId(product.productId);
    } else {
      setProductQuantityAbortController.current?.abort();
      setProductQuantity((Number(productQuantity) + product.quantity.quantityIncrement).toString());
      actionHandler();
    }
  };

  const handleBlur = (quantity: string) => {
    const min = minQty;
    const max = maxQty;
    let newQuantity = quantity;

    if (newQuantity === '') {
      newQuantity = min.toString();
    } else if (Number(quantity) < min) {
      newQuantity = min.toString();
    } else if (Number(quantity) > max) {
      newQuantity = max.toString();
    }

    setProductQuantity(newQuantity);
  };

  const handleOnAddEvent = (isCollapsed?: boolean) => {
    // TODO: temp fix before we handle through CMS
    if (product.skuCode === GIFT_CARD_SKU_CODE) {
      router.push(routing.giftCard);
      return;
    }

    onAddToCart?.();

    setProductQuantity((prevQuantity) => {
      return prevQuantity === '0' ? minQty.toString() : prevQuantity;
    });

    if (isCollapsed) {
      setLastAction?.('plus');
    }

    if (!productExistsInCart) {
      setProductExistsInCart(true);
    }
  };

  const handleReplaceEvent = (configs: SelectedConfiguration[] = [], replacedQnty?: number) => {
    const itemListName = getListName(product, gaValues?.channel);
    const itemPosition = getProductPosition(product.productId);
    const salesUnit = salesUnits?.[0]?.alternateSalesUnit ?? '';

    const selectedSalesUnit =
      configs.find(({ name }) => name === SALES_UNIT_ID_FIELD)?.value ?? salesUnit;

    const replace = replaceCartItem({
      variables: {
        ...getAddToCartVariables({
          productId: product.productId,
          categoryId: product.categoryId,
          skuCode: product.skuCode,
          quantity: replacedQnty ?? minQty,
          configuration: getProductConfigurationVariables(configs),
          itemPosition,
          itemListName,
          salesUnit: selectedSalesUnit,
        }),
        cartLineId: replaceProductInfo?.lineToReplace,
      },
    }).then(() => {
      replaceProductInfo?.onReplaceCompleted?.(
        product.productId,
        (cartData as Cart).nonExpressSection?.cartLines?.find(
          ({ id }) => id === replaceProductInfo?.lineToReplace,
        ),
      );
      replaceProductInfo?.setShouldUpdate(true);
      setCartLoading(false);
    });
    replaceProductInfo?.onReplace?.(replace);
  };

  const handleReplaceForTileCustomOrder = (
    replaceConfigs?: SelectedConfiguration[],
    replacedQuantity?: number,
  ) => {
    return handleReplaceEvent(replaceConfigs, replacedQuantity);
  };

  const clearAtcErrors = () => {
    setAtcErrors([]);
  };

  const displaySkeleton = loading || deleteFromCartLoading;

  const renderCounter = () =>
    isCustomisableProduct ? (
      <TileCustomOrder
        className={cx(styles.custom_order, className)}
        initialValue={productCartInfo}
        product={product}
        productCount={Number(productQuantity)}
        isReorder={isReorder}
        nonFocusable={nonFocusable}
        onClose={onChange}
        skipAddToCart={skipAddToCart}
        isReorderItem={isReorderItem}
        isV2={isV2}
        gaValues={gaValues}
        {...(shouldTriggerReplaceEvent && { onReplace: handleReplaceForTileCustomOrder })}
      />
    ) : (
      <ProductTileCollapsableCounter
        className={cx(styles.quantity, className)}
        size="small"
        min={minQty}
        max={maxQty}
        buttonArialLabel={product.productName}
        step={product.quantity.quantityIncrement}
        onChange={handleQuantityChange}
        onBlur={handleBlur}
        onRemoveClick={handleClickRemove}
        onMinusClick={handleClickMinus}
        onPlusClick={handleClickPlus}
        onAdd={handleOnAddEvent}
        isCollapsedInitial={!productExistsInCart}
        value={productQuantity}
        isReorder={isReorder}
        isCollapseAnimate
        isRestricted={isRestrictedProduct(product)}
        nonFocusable={nonFocusable}
        addBtnRef={addBtnRef}
        product={product}
        disableDelete={freeItem}
        uniqueProductId={uniqueProductId}
        addedProductId={addedProductId}
        setAddedProductId={setAddedProductId}
        lastAction={lastAction}
        setLastAction={setLastAction}
        cartLoading={cartLoading}
        setCartLoading={setCartLoading}
        isV2={isV2}
        disabledProductId={disabledProductId}
      />
    );

  return (
    <>
      <ProductQuantityTooltip availability={product.availability} isV2={isV2}>
        {displaySkeleton ? (
          <Skeleton
            className={cx(styles.skeleton, className)}
            variant="circular"
            width={28}
            height={28}
          />
        ) : (
          renderCounter()
        )}
      </ProductQuantityTooltip>

      {atcErrors.length > 0 && (
        <div ref={popupRef}>
          <Popper
            open={!!atcErrors.length}
            anchorEl={popupRef.current}
            placement="top"
            style={{ zIndex: 100 }}
            disablePortal
          >
            <Alert type="error" size="medium" onClose={clearAtcErrors}>
              <ul>
                {atcErrors.map((error, i) => (
                  <li key={i}>{error}</li>
                ))}
              </ul>
            </Alert>
          </Popper>
        </div>
      )}
    </>
  );
};
