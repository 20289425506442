import {
  AtpError as AptErrorApi,
  AtpLine as AtpLineApi,
  Cart as CartAPI,
  CartLine as CartLineAPI,
  CartProductCounter as CartProductCounterAPI,
  CartSection as CartSectionAPI,
  CartSubtotalBox as CartSubtotalBoxAPI,
  CartTotalSectionData as TotalSectionAPI,
  CouponStatusName,
  GetCartMinimumProductsQuery,
  LightweightCart as CartLightAPI,
  LightweightCartLine as CartLineLightAPI,
  LightweightCartLineWithGa,
  Maybe,
  SingleStoreCartLine as SingleStoreCartLineAPI,
  SingleStoreSection,
  OptionalProductsData,
  OrderCartLineInfo,
  ProductBasicData,
  Promotion as PromotionApi,
  PromotionType,
  TimeslotType,
  ValidationResult as ValidationResultApi,
  BillingReferenceInfo as BillingReferenceInfoAPI,
  SectionsContainer,
} from '@api';
import {
  AtpError,
  AtpLine,
  BillingReferenceInfo,
  Cart,
  CartGaLineLight,
  CartLight,
  CartLine,
  CartLineInfo,
  CartLineLight,
  CartLineLightWithGA,
  CartSection,
  Coupon,
  COUPON_CLIPPABLE,
  Discount,
  LightCoupon,
  MinimumCartLine,
  SingleStoreCartLine,
  PriceIndicators,
  ProductCounter,
  Promotion,
  PromotionCode,
  Subtotal,
  Tips,
  TotalSection,
  ValidationError,
  ValidationResult,
  Variant,
} from '@commons/cart';
import { Product } from '@commons/product';
import { productAdapter } from '@adapters/productAdapter';
import { getBreadcrumbs } from '@adapters/breadcrumbAdapter';
import { CsrData } from '@modules/masquerade/types/csr-data';
import { ComplaintReason } from '@modules/masquerade/types/complaint-reason';
import { getProductTile } from '@adapters/product/getProductTile';
import { priceAdapter } from './priceAdapter';

const { getPrice, getBasicPrice } = priceAdapter();
const { getProduct, getProductQuantity, getProductBasic } = productAdapter();

type CartApi = DeepPartial<CartAPI> | null | undefined;
type CartLightApi = DeepPartial<CartLightAPI>;
type CartLineApi = DeepPartial<CartLineAPI>;
type CartProductCounterApi = DeepPartial<CartProductCounterAPI>;
type CartSectionApi = DeepPartial<CartSectionAPI>;
type CartSubtotalBoxApi = DeepPartial<CartSubtotalBoxAPI>;
type TotalSectionApi = DeepPartial<TotalSectionAPI>;
type CartLineLightApi = DeepPartial<CartLineLightAPI>;
type SingleStoreCartLineApi = DeepPartial<SingleStoreCartLineAPI>;
type BillingReferenceInfoApi = DeepPartial<BillingReferenceInfoAPI>;

const getConfOptions = (cartLine?: CartLineApi): Variant[] => {
  const salesUnits = cartLine?.productExtendedData?.productBasicData?.salesUnits || [];
  const salesUnitsMap =
    salesUnits.length > 1
      ? salesUnits
          .filter((su) => su?.alternateSalesUnit === cartLine?.selectedSalesUnit)
          .map((option) => ({
            characteristicName: 'alternateSalesUnit',
            characteristicValue: option?.alternateSalesUnit || '',
          }))
      : [];
  const confOptionsMap = cartLine?.confOptions?.length
    ? cartLine.confOptions.map((option) => ({
        characteristicName: option?.characteristicName || '',
        characteristicValue: option?.characteristicValue || '',
      }))
    : [];
  return [...salesUnitsMap, ...confOptionsMap];
};

const getCouponAdditionalInfo = (
  coupon: CartLineApi['coupon'] | CartLineLight['coupon'],
): Pick<
  Coupon,
  'context' | 'detailedDescription' | 'expirationDate' | 'offerType' | 'status' | 'version'
> => {
  return {
    context: {
      description: coupon?.context?.description ?? null,
      name: coupon?.context?.name ?? null,
    },
    detailedDescription: coupon?.detailedDescription ?? null,
    expirationDate: coupon?.expirationDate || '',
    offerType: {
      description: coupon?.offerType?.description ?? null,
      name: coupon?.offerType?.name ?? null,
    },
    status: {
      description: coupon?.status?.description ?? null,
      displayMessage: !!coupon?.status?.displayMessage,
      name: coupon?.status?.name ?? null,
    },
    version: coupon?.version ?? null,
  };
};

const getCoupon = (cartLine: CartLineApi | SingleStoreCartLineApi): Coupon => {
  let isCouponClipped = (cartLine as CartLineApi).couponClipped;
  if (typeof isCouponClipped !== 'boolean') {
    isCouponClipped = cartLine.productCouponClipped ?? false;
  }

  const { coupon } = cartLine;
  return {
    ...getCouponAdditionalInfo(coupon),
    couponId: coupon?.couponId ?? '',
    couponProductInfo: {
      catId: coupon?.couponProductInfo?.catId ?? null,
      productId: coupon?.couponProductInfo?.productId ?? null,
      skuCode: coupon?.couponProductInfo?.skuCode ?? null,
      upc: coupon?.couponProductInfo?.upc ?? null,
    },
    displayDescription: coupon?.displayDescription ?? null,
    displayStatus: {
      description: coupon?.displayStatus?.description ?? null,
      name: coupon?.displayStatus?.name ?? null,
    },
    displayStatusMessage: !!coupon?.displayStatusMessage,
    quantity: coupon?.quantity ?? null,
    value: coupon?.value ?? null,
    isActive: !!isCouponClipped,
    hasError:
      coupon?.status?.name === CouponStatusName.COUPON_MIN_QTY_NOT_MET &&
      coupon?.displayStatus?.name !== COUPON_CLIPPABLE,
  };
};

const getLightCoupon = (cartLine: CartLineLightApi | LightweightCartLineWithGa): LightCoupon => {
  const { coupon } = cartLine;
  return {
    ...getCouponAdditionalInfo(coupon),
    couponId: coupon?.couponId ?? '',
    couponProductInfo: {
      catId: coupon?.couponProductInfo?.catId ?? null,
      productId: coupon?.couponProductInfo?.productId ?? null,
      skuCode: coupon?.couponProductInfo?.skuCode ?? null,
      upc: coupon?.couponProductInfo?.upc ?? null,
    },
    displayDescription: coupon?.displayDescription ?? null,
    displayStatus: {
      description: coupon?.displayStatus?.description ?? null,
      name: coupon?.displayStatus?.name ?? null,
    },
    displayStatusMessage: !!coupon?.displayStatusMessage,
    quantity: coupon?.quantity ?? null,
    value: coupon?.value ?? null,
  };
};

const getCartLineDiscount = (discount?: CartLineApi['discount']): Discount => {
  return {
    description: discount?.promotionDescription ?? '',
    discountPrice: getPrice(discount?.amount),
    skuLimit: discount?.skuLimit ?? 0,
  };
};

const getPriceIndicators = (priceIndicators?: CartLineApi['priceIndicators']): PriceIndicators => {
  return {
    hasDepositValue: !!priceIndicators?.hasDepositValue,
    hasEstimatedPrice: !!priceIndicators?.hasEstimatedPrice,
    hasScaledPricing: !!priceIndicators?.hasScaledPricing,
    hasTax: !!priceIndicators?.hasTax,
  };
};

const getMinimumCartLine = (cartLine?: CartLineApi): MinimumCartLine => {
  return {
    id: cartLine?.id ?? '',
    quantitySelector: {
      quantity: cartLine?.quantitySelector?.quantity ?? 0,
    },
    confOptions: getConfOptions(cartLine),
    selectedSalesUnit: cartLine?.selectedSalesUnit ?? '',
    productId: cartLine?.productExtendedData?.productBasicData?.productId ?? '',
  };
};
const getCartLine = (cartLine: CartLineApi, modified: boolean): CartLine => {
  return {
    // todo fix adapter types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    atpLine: getSingleAtpLine(cartLine?.atpLine),
    categoryId: cartLine?.categoryId ?? null,
    configurationDescription: cartLine?.configurationDescription ?? null,
    confOptions: getConfOptions(cartLine),
    coupon: getCoupon(cartLine),
    promotionCode: cartLine?.promotionCode ?? '',
    promotionApplied: !!cartLine?.promotionApplied,
    promotionDescription: cartLine?.promotionDescription ?? '',
    discount: getCartLineDiscount(cartLine?.discount),
    displayPrice: getPrice(cartLine?.displayPrice),
    unscaledPrice: getPrice(cartLine?.unscaledPrice),
    freeSample: !!cartLine?.freeSample,
    id: cartLine?.id ?? '',
    priceIndicators: getPriceIndicators(cartLine?.priceIndicators),
    product: getProduct(cartLine?.productExtendedData),
    productCouponClipped: !!cartLine?.productCouponClipped,
    quantitySelector: getProductQuantity(cartLine?.quantitySelector),
    selectedSalesUnit: cartLine?.selectedSalesUnit ?? null,
    unitPrice: getPrice(cartLine?.unitPrice),
    advanceOrderAvailable: !!cartLine.advanceOrderAvailable,
    modified,
    freeItem: cartLine.freeItem ?? false,
    complaintReason: cartLine.complaintReason ? ComplaintReason(cartLine.complaintReason) : null,
    itemListName: cartLine.itemListName ?? '',
    itemPosition: cartLine?.itemPosition ?? 0,
    discountPerUnit: cartLine?.discountPerUnit?.value ?? 0,
    departmentLabel: cartLine?.departmentLabel ?? '',
  };
};

const getCartLineLight = (cartLine: CartLineLightApi): CartLineLight => {
  return {
    id: cartLine?.id ?? '',
    expressEligible: !!cartLine?.expressEligible,
    isRecent: !!cartLine?.isRecent,
    isNewItem: !!cartLine?.isNewItem,
    skuCode: cartLine?.skuCode ?? '',
    productId: cartLine?.productId ?? '',
    categoryId: cartLine?.categoryId ?? '',
    quantitySelector: getProductQuantity(cartLine?.quantitySelector),
    coupon: getLightCoupon(cartLine),
    selectedSalesUnit: cartLine?.selectedSalesUnit ?? '',
    discount: getCartLineDiscount(cartLine?.discount),
    displayPrice: getPrice(cartLine?.displayPrice),
    itemListName: cartLine.itemListName ?? '',
    itemPosition: cartLine?.itemPosition ?? 0,
    discountPerUnit: getCartLineDiscount(cartLine?.discount).discountPrice.value,
  };
};

const getCartLines = (
  cartLines?: DeepPartial<Array<CartLineApi | null>>,
  modified?: boolean,
): CartLine[] => {
  const preparedCartlines = cartLines?.length
    ? (cartLines.filter((cartLine) => cartLine) as CartLineApi[])
    : [];
  return cartLines?.length
    ? preparedCartlines.map((cartLine) => getCartLine(cartLine, !!modified))
    : [];
};

export const getCartGaLineLight = (
  cartLine: Partial<LightweightCartLineWithGa>,
): CartGaLineLight => {
  return {
    brandName: cartLine.brandName ?? '',
    productName: cartLine.productName ?? '',
    quantity: getProductQuantity(cartLine.quantitySelector),
    variantId: cartLine.variantId ?? '',
    available: cartLine?.available ?? false,
    price: getPrice(cartLine?.price),
    discountPerUnit: cartLine.discountPerUnit?.value ?? 0,
    typename: 'CartGaLineLight',
  };
};

const getCartLinesLight = (
  cartLines?: Array<CartLineLightApi | null | undefined>,
  gaCartLines?: Array<Partial<LightweightCartLineWithGa> | null | undefined>,
): CartLineLightWithGA[] => {
  const preparedCartLines = cartLines?.length
    ? (cartLines.filter((cartLine) => cartLine) as CartLineLightApi[])
    : [];
  const preparedGaCartLines = gaCartLines?.length ? gaCartLines.filter((cartLine) => cartLine) : [];

  return cartLines?.length
    ? preparedCartLines.map((cartLine) => {
        const currentGaCartLine = preparedGaCartLines.find(
          (gaCartLine) => cartLine.id === gaCartLine?.id,
        );
        return {
          ...getCartLineLight(cartLine),
          ...(currentGaCartLine && getCartGaLineLight(currentGaCartLine)),
          price: getPrice(currentGaCartLine?.price),
          typename: 'CartGaLineLight',
        };
      })
    : [];
};

const isSectionModified = (section?: DeepPartial<Maybe<CartSectionApi>>): boolean =>
  !!section?.sectionInfo?.sectionTitle?.includes('Modified');

export const getCartSections = (cart?: DeepPartial<SectionsContainer> | null): CartSection[] => {
  return cart?.cartSections?.length
    ? cart.cartSections.map((section) => ({
        name: section?.name ?? '',
        cartLines: getCartLines(section?.cartLines),
        sectionImage: section?.sectionImage ?? '',
        sectionInfo: {
          sectionTitle: section?.sectionInfo?.sectionTitle ?? '',
          wine: !!section?.sectionInfo?.wine,
          subTotal: getPrice(section?.sectionInfo?.subTotal),
          modified: isSectionModified(section),
        },
      }))
    : [];
};

const getSectionPriceIndicators = (
  section: DeepPartial<CartSectionApi> | DeepPartial<CartAPI['nonExpressSection']> | null,
): PriceIndicators => {
  let hasDepositValue = false;
  let hasEstimatedPrice = false;
  let hasScaledPricing = false;
  let hasTax = false;

  section?.cartLines?.forEach((cartLine) => {
    if (cartLine?.priceIndicators?.hasDepositValue) hasDepositValue = true;
    if (cartLine?.priceIndicators?.hasEstimatedPrice) hasEstimatedPrice = true;
    if (cartLine?.priceIndicators?.hasScaledPricing) hasScaledPricing = true;
    if (cartLine?.priceIndicators?.hasTax) hasTax = true;
  });

  return {
    hasDepositValue,
    hasEstimatedPrice,
    hasScaledPricing,
    hasTax,
  };
};

export const getNonExpressSectionCartTotalPriceIndicator = (cart?: CartApi): PriceIndicators => {
  const section = cart?.nonExpressSection;

  return getSectionPriceIndicators(section);
};

export const getCartTotalPriceIndicators = (
  cart?: DeepPartial<SectionsContainer> | null,
): PriceIndicators[] => {
  const cartSections = cart?.cartSections;

  const cartTotalPriceIndicators: PriceIndicators[] = [];

  cartSections?.forEach((section) => {
    cartTotalPriceIndicators.push(getSectionPriceIndicators(section));
  });

  return cartTotalPriceIndicators;
};

const getCartSubtotal = (subtotal?: CartSubtotalBoxApi | null): Subtotal => {
  return {
    id: subtotal?.id ?? '',
    text: subtotal?.text ?? '',
    value: getBasicPrice(subtotal?.value),
    mark: subtotal?.other?.mark ?? '',
    deliveryPassApplied: !!subtotal?.other?.isDeliveryPassApplied,
    deliveryPassFreeTrialEligible: !!subtotal?.other?.isDeliveryPassFreeTrialEligible,
  };
};

const getCartAtpLineProducts = (
  products: DeepPartial<AtpLineApi['recommendedProducts']> = [],
): Product[] => {
  if (!products?.length) return [];
  const preparedProducts = products.filter((product) => product);
  return preparedProducts.map((product) => getProduct(product));
};

const getCartAtpLine = (
  atpLine:
    | DeepPartial<AptErrorApi['nonReplaceableLines']>
    | DeepPartial<AptErrorApi['replaceableLines']>,
): AtpLine[] => {
  const preparedAtpLine = atpLine?.length ? atpLine?.filter((item) => item) : [];
  return preparedAtpLine?.length
    ? preparedAtpLine.map((item) => ({
        availableQuantity: item?.availableQuantity ?? null,
        cartLineId: item?.cartLineId ?? '',
        description: item?.description ?? null,
        recommendedProducts: getCartAtpLineProducts(item?.recommendedProducts),
      }))
    : [];
};

const getSingleStoreGaAttributes = (
  cartLine: SingleStoreCartLineApi & { productExtendedData: CartLineApi['productExtendedData'] },
) => {
  return {
    itemListName: cartLine?.itemListName ?? '',
    itemPosition: cartLine?.itemPosition ?? 0,
    discountPerUnit: cartLine?.discountPerUnit?.value ?? 0,
  };
};

export const getSingleStoreCartLine = (
  cartLine: SingleStoreCartLineApi & { productExtendedData: CartLineApi['productExtendedData'] },
): SingleStoreCartLine => {
  return {
    id: cartLine?.id ?? '',
    productId: cartLine?.productId ?? '',
    categoryId: cartLine?.categoryId ?? '',
    priceIndicators: getPriceIndicators(cartLine?.priceIndicators),
    confOptions: getConfOptions(cartLine as CartLineApi),
    advanceOrderAvailable: cartLine.advanceOrderAvailable ?? false,
    coupon: getCoupon(cartLine),
    description: cartLine?.description ?? '',
    descriptionExtended: cartLine.descriptionExtended ?? '',
    discount: getCartLineDiscount(cartLine?.discount),
    expressEligible: !!cartLine?.expressEligible,
    hasRecommendedReplacement: !!cartLine?.hasRecommendedReplacement,
    recommendedProducts: cartLine?.recommendedProducts?.map(getProductBasic) ?? [],
    product: cartLine?.productBasicData ? getProduct(cartLine.productBasicData) : null,
    productCouponClipped: cartLine?.productCouponClipped ?? false,
    productImage: cartLine?.productImage ?? '',
    productPageUrl: cartLine?.productPageUrl ?? '',
    promotionApplied: cartLine?.promotionApplied ?? false,
    promotionCode: cartLine?.promotionCode ?? '',
    promotionDescription: cartLine?.promotionDescription ?? '',
    quantitySelector: getProductQuantity(cartLine?.quantitySelector),
    unitPrice: getPrice(cartLine?.unitPrice),
    unscaledPrice: getPrice(cartLine?.unscaledPrice),
    displayPrice: getPrice(cartLine?.displayPrice),
    breadcrumbs: getBreadcrumbs(cartLine.breadcrumbs),
    ...getSingleStoreGaAttributes(cartLine),
  };
};

const getSingleAtpLine = (atpLine: AtpLineApi): AtpLine | undefined => {
  return {
    availableQuantity: atpLine?.availableQuantity ?? null,
    cartLineId: atpLine?.cartLineId ?? '',
    description: atpLine?.description ?? null,
    recommendedProducts: getCartAtpLineProducts(atpLine?.recommendedProducts),
  };
};

const getCartAtpError = (atpErrors?: DeepPartial<AptErrorApi> | null): AtpError => {
  return {
    deliveryDate: atpErrors?.deliveryDate ?? null,
    deliveryTimeSlot: atpErrors?.deliveryTimeSlot ?? null,
    nonReplaceableLines: getCartAtpLine(atpErrors?.nonReplaceableLines ?? []),
    notMetMinAmount: atpErrors?.notMetMinAmount ?? null,
    replaceableLines: getCartAtpLine(atpErrors?.replaceableLines ?? []),
  };
};

const getCartPromotionErrors = (
  errors?: DeepPartial<ValidationResultApi['errors']>,
): ValidationError[] => {
  return errors?.length
    ? errors.map((item) => ({
        error: item?.error ?? null,
        name: item?.name ?? null,
        field: { name: item?.field?.name ?? '', value: item?.field?.value ?? '' } ?? null,
      }))
    : [];
};

const getPromotionInfo = (promoInfo?: DeepPartial<Maybe<PromotionApi>>): Promotion => ({
  description: promoInfo?.description ?? '',
  name: promoInfo?.name ?? '',
  terms: promoInfo?.terms ?? '',
  promotionCode: promoInfo?.promotionCode ?? '',
  promotionType: promoInfo?.promotionType as PromotionType,
  offerType: {
    name: promoInfo?.offerType?.name ?? '',
    description: promoInfo?.offerType?.description ?? '',
  },
});

export const getCartPromotionCode = (
  promotionCode?: DeepPartial<CartAPI['promotionCode']>,
): PromotionCode => {
  return {
    promotionApplied: !!promotionCode?.promotionApplied,
    promotionCode: promotionCode?.promotion?.promotionCode ?? '',
    promotionInfo: getPromotionInfo(promotionCode?.promotion),
  };
};

const getCartProductCounter = (
  productCounter:
    | DeepPartial<CartAPI['productCounter']>
    | DeepPartial<CartLightApi['productCounter']> = [],
): ProductCounter[] => {
  if (!productCounter?.length) return [];
  const preparedCounter = productCounter.filter((item) => item) as CartProductCounterApi[];
  return preparedCounter.map((item) => ({
    count: item?.count ?? 0,
    productId: item.productId ?? '',
  }));
};

const getCartTips = (tips: DeepPartial<CartAPI['tips']>): Tips => {
  return {
    tipAmounts: tips?.tipAmounts?.length
      ? tips.tipAmounts.filter((item): item is string => !!item)
      : [],
    tipApplied: tips?.tipApplied ?? '',
  };
};

const getCartValidationResult = (
  validationResult: DeepPartial<CartAPI['validationResult']>,
): ValidationResult => {
  return {
    atpErrors: getCartAtpError(validationResult?.atpErrors),
    errors: getCartPromotionErrors(validationResult?.errors),
    success: !!validationResult?.success,
  };
};

const getCartTotalItems = (productCounter: ProductCounter[]): number => {
  return productCounter.reduce((total, currentProduct) => {
    return total + currentProduct.count;
  }, 0);
};

const getMinimumCartLinesList = (data?: GetCartMinimumProductsQuery): MinimumCartLine[] => {
  const cartLines =
    data?.cartForCheckout?.cartSections?.map((section) => section?.cartLines)?.flat() ?? [];

  return cartLines.map((line) => getMinimumCartLine(line as CartLineApi)) ?? [];
};

const getCartLinesList = (cart?: CartApi | null): CartLine[] => {
  const cartSections = getCartSections(cart);
  const cartLines = cartSections.map((section) => section?.cartLines)?.flat();

  return cartLines?.length ? cartLines : [];
};

export const getCartTotalSection = (
  totalSection?: TotalSectionApi | null,
  cartHasOnlyDeliveryPass?: boolean,
): TotalSection => {
  const deliveryFee = cartHasOnlyDeliveryPass ? undefined : totalSection?.deliveryFee;

  return {
    credits: getCartSubtotal(totalSection?.credits),
    deliveryFee: getCartSubtotal(deliveryFee),
    fuelSurcharge: getCartSubtotal(totalSection?.fuelSurcharge),
    giftCardBalance: getCartSubtotal(totalSection?.giftCardBalance),
    stateBottleDeposit: getCartSubtotal(totalSection?.stateBottleDeposit),
    subTotal: getCartSubtotal(totalSection?.subTotal),
    promotion: getCartSubtotal(totalSection?.promotion),
    automaticHeaderDiscount: getCartSubtotal(totalSection?.automaticHeaderDiscount),
    tip: getCartSubtotal(totalSection?.tip),
    totalAvalaraTax: getCartSubtotal(totalSection?.totalAvalaraTax),
    totalTax: getCartSubtotal(totalSection?.totalTax),
    premiumFee: getCartSubtotal(totalSection?.premiumFee),
  };
};

const getSingleStoreSection = (
  singleStoreSection?: DeepPartial<SingleStoreSection> | undefined | null,
) => {
  if (singleStoreSection?.cartLines) {
    const cartLines = singleStoreSection?.cartLines?.filter(
      (cartLine) => cartLine !== null,
    ) as (SingleStoreCartLineApi & { productExtendedData: CartLineApi['productExtendedData'] })[];
    return {
      subTotal: getBasicPrice(singleStoreSection?.subTotal),
      cartLines: cartLines.map(getSingleStoreCartLine),
    };
  } else {
    return undefined;
  }
};

const getAtcEventIds = (atcEventIds: Partial<Partial<Maybe<string>>[] | null | undefined>) => {
  const mappedAtcEventIds = atcEventIds?.map((item) => item ?? '') ?? [];
  return mappedAtcEventIds.filter((item) => !!item);
};

const getBillingReferenceInfo = (
  info: BillingReferenceInfoApi | undefined | null,
): BillingReferenceInfo => {
  return {
    billingReference: info?.billingReference ?? '',
    isCorporateUser: info?.isCorporateUser ?? false,
  };
};

const getSampleProducts = (sampleProducts: DeepPartial<ProductBasicData[]>) => {
  return sampleProducts?.map((sampleProduct) => {
    return getProductTile(sampleProduct);
  });
};

const getCart = (cart?: CartApi | null): Cart => {
  const productCounter = getCartProductCounter(cart?.productCounter);
  const cartHasOnlyDeliveryPass = !!cart?.deliveryPassCartOnly;
  const csrData = cart?.csrData ?? {};
  return {
    cartLinesCount: cart?.cartLinesCount ?? 0,
    hasCouponError: !!cart?.couponMessage,
    cartSections: getCartSections(cart),
    estimatedTotal: getCartSubtotal(cart?.estimatedTotal),
    productCounter,
    totalItems: getCartTotalItems(productCounter),
    cartLinesList: getCartLinesList(cart),
    promotionCode: getCartPromotionCode(cart?.promotionCode),
    tips: getCartTips(cart?.tips),
    validationResult: getCartValidationResult(cart?.validationResult),
    minOrder: getPrice(cart?.minOrder),
    alcoholRestricted: !!cart?.alcoholRestricted,
    totalWithoutDiscount: getPrice(cart?.totalWithoutDiscount),
    containsWineSection: !!cart?.containsWineSection,
    deliveryPassCartOnly: cartHasOnlyDeliveryPass,
    totalSection: getCartTotalSection(cart?.totalSection, cartHasOnlyDeliveryPass),
    cartLinePromotionApplied: !!cart?.cartLinePromotionApplied,
    cartTotalPriceIndicators: getCartTotalPriceIndicators(cart),
    nonExpressCartTotalPriceIndicators: getNonExpressSectionCartTotalPriceIndicator(cart),
    nonExpressSection: getSingleStoreSection(cart?.nonExpressSection),
    csrData: CsrData(csrData),
    timeslotTypeSuggestion: cart?.timeslotTypeSuggestion ?? TimeslotType.STANDARD,
    changeQuantityEventId: cart?.changeQuantityEventId ?? '',
    atcEventIds: getAtcEventIds(cart?.atcEventIds),
    billingReferenceInfo: getBillingReferenceInfo(cart?.billingReferenceInfo),
    sampleProducts: getSampleProducts(cart?.sampleProducts ?? []),
    expressOnlySection: getSingleStoreSection(cart?.expressOnlySection),
    nonExpressUniqueCartLinesCount: cart?.nonExpressUniqueCartLinesCount ?? 0,
    nonExpressCartLinesThreshold: cart?.nonExpressCartLinesThreshold ?? 0,
  };
};

const getLightCart = (cart?: CartLightApi | null): CartLight => {
  const productCounter = getCartProductCounter(cart?.productCounter);
  return {
    cartLinesCount: cart?.cartLinesCount ?? 0,
    nonExpressProductCount: cart?.nonExpressProductCount ?? 0,
    modifyOrder: cart?.modifyOrder ?? false,
    cartLines: getCartLinesLight(cart?.cartLines, cart?.gaCartLines as LightweightCartLineWithGa[]),
    subTotal: getBasicPrice(cart?.subTotal),
    changeQuantityEventId: cart?.changeQuantityEventId ?? '',
    atcEventIds: getAtcEventIds(cart?.atcEventIds),
    productCounter,
  };
};

const getCartLineInfo = (cartLine: OrderCartLineInfo): CartLineInfo => {
  return {
    description: cartLine?.description ?? '',
    id: cartLine?.id ?? '',
    price: getBasicPrice(cartLine?.price),
    quantity: cartLine?.quantity ?? null,
    product: getProductBasic(cartLine?.product),
    couponId: cartLine?.couponId ?? null,
    giftCard: cartLine?.giftCard,
    selectedSalesUnit: cartLine?.selectedSalesUnit ?? null,
    departmentLabel: cartLine?.departmentLabel ?? '',
    substituted: cartLine?.substituted ?? false,
    substitutedProduct: cartLine?.substitutedProduct ?? null,
  };
};

const getCartLinesInfo = (cartLines?: Array<OrderCartLineInfo>): CartLineInfo[] => {
  const preparedCartLines = cartLines?.length ? cartLines.filter((cartLine) => cartLine) : [];
  return cartLines?.length ? preparedCartLines.map((cartLine) => getCartLineInfo(cartLine)) : [];
};

const getOptionalAdditionsQuantity = (
  list?: DeepPartial<ProductBasicData[]>,
  cartLineList?: CartLineApi[],
) => {
  return (
    list?.map((item) => {
      const matchedProduct = cartLineList?.find(
        (cartLine) =>
          cartLine?.productExtendedData?.productBasicData?.productId === item?.productId,
      );
      return {
        ...item,
        quantity: { ...item?.quantity, quantity: matchedProduct?.quantitySelector?.quantity ?? 1 },
      };
    }) ?? []
  );
};

const getProductOptionalAdditions = (
  productId: string,
  cart?: CartApi,
): DeepPartial<Maybe<OptionalProductsData>> | undefined => {
  const cartLines = cart?.cartSections?.map((section) => section?.cartLines)?.flat() ?? [];
  const matchedCartline = cartLines.find(
    (cartLine) => cartLine?.productExtendedData?.productBasicData?.productId === productId,
  );

  const optionalProductItemList =
    matchedCartline?.productExtendedData?.optionalProductsData?.items ?? [];
  const items = getOptionalAdditionsQuantity(optionalProductItemList, cartLines as CartLineApi[]);

  return { ...matchedCartline?.productExtendedData?.optionalProductsData, items };
};

export const cartAdapter = () => {
  return {
    getCart,
    getLightCart,
    getCartTips,
    getCartLines,
    getCartLinesInfo,
    getCartSubtotal,
    getCartTotalSection,
    getMinimumCartLinesList,
    getProductOptionalAdditions,
  };
};
