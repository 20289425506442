import isNil from 'lodash/isNil';
import { MutationFunctionOptions } from '@apollo/client/react/types/types';
import {
  LightweightCart,
  SetProductQuantityInCartLightMutation,
  SetProductQuantityInCartLightMutationVariables,
} from '@api';
import { useSetProductQuantityInCartLight } from '@hooks/cart/useSetProductQuantityInCartLight';
import { cartAdapter } from '@adapters/cartAdapter';
import { useAddToCartLightEvent } from '@modules/ga/hooks/useAddToCartEvent';
import { useLightCart } from '@hooks/cart/useLightCart';
const { getLightCart } = cartAdapter();

export function useHandleSetProductQuantityInCart() {
  const [setProductQuantityInCart] = useSetProductQuantityInCartLight();
  const { trackAddToLightCart } = useAddToCartLightEvent();
  const { data: cartData } = useLightCart();

  return async (
    options: MutationFunctionOptions<
      SetProductQuantityInCartLightMutation,
      SetProductQuantityInCartLightMutationVariables
    >,
  ) => {
    if (options.variables?.productDataInput) {
      const newCartApi = await setProductQuantityInCart(options);
      const {
        itemPosition,
        itemListName: listName,
        productId,
      } = options.variables.productDataInput;

      if (
        !isNil(itemPosition) &&
        !isNil(listName) &&
        typeof itemPosition === 'number' &&
        listName
      ) {
        const newCart = getLightCart(
          newCartApi.data?.changeCartItemQuantityLight as LightweightCart,
        );

        trackAddToLightCart(newCart, cartData, {
          itemPosition: itemPosition,
          listName: listName,
          productIds: [productId],
        });
      }
    }
  };
}
