/* eslint-disable @typescript-eslint/naming-convention */
import { ApolloError, useReactiveVar } from '@apollo/client';
import { Typography } from '@mui/material';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { warning as WarningIcon } from '@assets/icons/system';
import { AUTH_MODAL_TYPES } from '@commons/auth';
import { AgeVerification } from '@components/Modals/AgeVerification/AgeVerification';
import { Button } from '@components/UI/Button/Button';
import { routing } from '@constants/routing';
import { ErrorTypes, usePageValidationError } from '@context/pageValidationErrorContext';
import { sectionsToBeDeletedVar } from '@graphql/variables/sectionsToBeDeletedVar';
import { useAuthModal } from '@hooks/authHooks/useAuthModal';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { useDeleteAlcohol, useVerifyAge } from '@hooks/cart';
import { useDeleteUnavailable } from '@hooks/cart/useDeleteUnavailable';
import { useCustomLazyCheckout } from '@hooks/checkout/useLazyCheckout';
import { useDisablePage } from '@hooks/common/useDisablePage';
import { useLocalization } from '@hooks/useLocalization';
import { useAddShippingInfoEvent } from '@modules/ga/hooks/useAddShippingInfoEvent';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { emitCheckoutClickAction } from '@modules/actions/checkout-click-action';
import { usePartialDisableCartPage } from '@features/CartPage/hooks/usePartialDisableCartPage';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';
import { useMasqueradeBarData } from '@hooks/masquerade/useMasqueradeBarData';
import { calculateRemainingOrderPrice } from '@utils/calculateRemainingOrderPrice';
import { getPrice } from '@utils/getPrice';
import { isCheckoutErrorType } from '@utils/isCheckoutErrorType';
import { useCartForCheckoutContext } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { Cart } from '@commons/cart';
import { fireBeginCheckoutGAEvent } from '@modules/ga/events/ecommerce/begin-checkout/beginCheckoutCreator';
import { AUTH_SIGNUP_NOT_COMPLETE } from '@constants/errorCodes';
import { fireCheckoutWithoutUnavailableItemsEvent } from '@modules/ga/events/custom/checkout-without-unavailable-items/checkoutWithoutUnavailableItemsCreator';
import { useTimeslots } from '@hooks/timeslots';
import { getOrderMinimumDetails } from '../Bag/components/FilledBag/components/OrderMinimum/OrderMinimum.utils';
import styles from './CheckoutBlock.module.scss';

interface CheckoutProps {
  isModal?: boolean;
  onAgeVerification?: () => void;
  onClose?: () => void;
  onModalsClose?: () => void;
  className?: string;
  leftSideScrollToTop?: () => void;
  isCartPage?: boolean;
}

const isTotalPriceBiggerMinOrder = (cart: Cart) => {
  return cart.totalSection.subTotal.value.value > cart.minOrder.value;
};

export const CheckoutBlock = ({
  onClose,
  className,
  isModal,
  onAgeVerification,
  onModalsClose,
  leftSideScrollToTop,
  isCartPage = false,
}: CheckoutProps) => {
  const { selectedTimeslot } = useTimeslots();
  const cartLineIds = useReactiveVar(sectionsToBeDeletedVar);
  const { isMobile, isTablet } = useHeaderContext();
  const untilLargeTablet = !!isMobile || !!isTablet;
  const { t } = useLocalization();
  const { enabled: isExpressEnabled } = useExpressContext();
  const { cartInfo } = useCartForCheckoutContext();
  const { data: cart } = cartInfo;
  const [deleteUnavailable] = useDeleteUnavailable();
  const { disabled: cartDisabledPartially } = usePartialDisableCartPage();
  const hasUnavailableItems = !!cartLineIds?.length;
  const hasUnavailableExpressItems = !!cart.nonExpressSection?.cartLines.length;
  const hasExpressOnlyItems = !!cart.expressOnlySection?.cartLines.length;
  const checkoutButtonText = () => {
    if (hasUnavailableItems || hasUnavailableExpressItems) {
      return t('delivery.checkOutWithoutUnavailableItems');
    } else if (hasExpressOnlyItems) {
      return t('delivery.checkOutExpressOnlyItems');
    }
    return t('delivery.checkOut');
  };
  const mobileButtonText =
    (hasUnavailableItems || hasUnavailableExpressItems || hasExpressOnlyItems) && isModal
      ? t('delivery.reviewItems')
      : checkoutButtonText();
  const checkout = useCustomLazyCheckout();
  const {
    data: { isAddOnOrderMode },
  } = useMasqueradeBarData();
  const [checkoutInProgress, setCheckoutInProgress] = useState(false);
  const { error, clear, set: setError } = usePageValidationError();
  const [verifyAge, { loading: verifyAgeInProgress }] = useVerifyAge();
  const [deleteAlcohol, { loading: deleteAlcoholInProgress }] = useDeleteAlcohol();
  const [ageVerificationOpened, setAgeVerificationOpened] = useState(
    error?.type === 'checkout.alcohol.is.restricted' ||
      error?.type === 'CHECKOUT_ALCOHOL_IS_RESTRICTED',
  );
  const { toggleAuthModal, on } = useAuthModal();
  const { disable: disableCart, enable: enableCart } = useDisablePage();
  const signInListener = useRef<VoidFunction>();
  const router = useRouter();
  const hasError =
    !checkoutInProgress &&
    error?.type &&
    ((isCartPage && error.type !== 'paymentMethodList') || !isCartPage) &&
    isCheckoutErrorType(error.type);
  const { trackAddShippingInfo } = useAddShippingInfoEvent();

  const handleCheckoutComplete = async () => {
    await router.push(routing.checkout);
    setCheckoutInProgress(false);
    trackAddShippingInfo();
    onClose?.();
  };

  const handleCheckoutError = (checkoutError: ApolloError) => {
    setCheckoutInProgress(false);
    const errorKey = checkoutError.graphQLErrors?.[0]?.message as ErrorTypes;
    if (errorKey === 'authorization.invalid') {
      toggleAuthModal(AUTH_MODAL_TYPES.SOCIAL_SIGN_IN);

      signInListener.current = on?.(async (value) => {
        if (value.type === 'signIn') {
          signInListener.current?.();

          doCheckout();
        }
      });
      return;
    }
    if (errorKey === 'checkout.alcohol.is.restricted') {
      return setAgeVerificationOpened(true);
    }
    if (errorKey === 'checkout.under.order.minimum') {
      handleMinOrderError(errorKey);
    }
    if (errorKey === AUTH_SIGNUP_NOT_COMPLETE) {
      toggleAuthModal(AUTH_MODAL_TYPES.SOCIAL_CREATE_ACCOUNT);
    }

    setError({
      type: errorKey,
      message: t(`cart:${errorKey}`),
    });
  };

  const doCheckout = async () => {
    setCheckoutInProgress(true);
    disableCart();
    clear();

    try {
      await checkout();
      enableCart();
      handleCheckoutComplete();
      if (cart.totalSection.deliveryFee.deliveryPassApplied) {
        sendGtmEvent(
          GtmCustomEvent({
            event: 'deliverypass-click',
            event_name: 'deliverypass_applied_in_order',
            ua_category: 'deliverypass',
            ua_action: 'deliverypass applied in order',
          }),
        );
      }
    } catch (checkoutError) {
      enableCart();
      handleCheckoutError(checkoutError as ApolloError);
    }
  };

  const handleMinOrderError = async (errorKey: ErrorTypes) => {
    const currency = cart?.minOrder?.currency || '';
    const minOrder = cart?.minOrder?.value || 0;

    setError({
      type: errorKey,
      message: await t(`cart:${errorKey}`, {
        left: calculateRemainingOrderPrice({
          current: cart?.totalSection?.subTotal?.value?.value || 0,
          min: minOrder,
          currency,
        }),
        min: getPrice(minOrder, currency),
      }),
    });
    onClose?.();
  };

  const order = getOrderMinimumDetails(cart.totalSection.subTotal.value.value, cart.minOrder.value);
  const handleDeleteUnavailable = () => {
    const hasOrderRemaining = !!order?.remaining && !isAddOnOrderMode;
    const shouldPreventClickForExpressAddress =
      (cart.cartLinesCount === 0 || hasOrderRemaining) && isExpressEnabled;

    if (shouldPreventClickForExpressAddress) {
      return;
    }
    deleteUnavailable({
      variables: { cartLineIds },
      onCompleted: () => {
        doCheckout();
      },
    });
  };
  useEffect(() => {
    if (error?.message === 'checkout.under.order.minimum') {
      handleMinOrderError(error.type);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, error?.type]);

  useEffect(() => {
    if (hasUnavailableItems || hasUnavailableExpressItems || hasExpressOnlyItems) {
      leftSideScrollToTop?.();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUnavailableItems, hasUnavailableExpressItems, hasExpressOnlyItems]);

  const confirmAgeVerification = () => {
    verifyAge({
      onCompleted() {
        setAgeVerificationOpened(false);
        doCheckout();
        onAgeVerification && onAgeVerification();
      },
    });
  };

  const declineAgeVerification = () => {
    disableCart();
    deleteAlcohol({
      onCompleted({ deleteAlcohol: deleteAlcoholCart }) {
        enableCart();
        setAgeVerificationOpened(false);
        if (deleteAlcoholCart.cartLinesCount && isTotalPriceBiggerMinOrder(deleteAlcoholCart)) {
          doCheckout();
        }
      },
    });
  };

  const handleCheckoutClick = () => {
    if (hasUnavailableItems || hasUnavailableExpressItems || hasExpressOnlyItems) {
      fireCheckoutWithoutUnavailableItemsEvent({
        cart,
        selectedTimeslot: selectedTimeslot?.timePeriod,
        unavailableItems: cartLineIds,
      });
    }

    sendGtmEvent({
      /* eslint-disable @typescript-eslint/naming-convention */
      event: 'has_unavailable_items',
      event_name: 'has-unavailable-items',
      ua_action: 'has unavailable items in cart',
      has_unavailable_items:
        hasUnavailableItems || hasUnavailableExpressItems || hasExpressOnlyItems,
    });
    fireBeginCheckoutGAEvent({ cartLines: cart.cartLinesList });
    if (
      untilLargeTablet &&
      isModal &&
      (hasUnavailableItems || hasUnavailableExpressItems || hasExpressOnlyItems)
    ) {
      return onModalsClose?.() || onClose?.();
    }
    return hasUnavailableItems ? handleDeleteUnavailable() : doCheckout();
  };

  useEffect(() => {
    const signOffListener = on?.((action) => {
      if (['close', 'welcomeClose', 'back'].includes(action.type)) {
        signInListener.current?.();
      }
    });
    return () => {
      signOffListener?.();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    if (!hasUnavailableItems && !hasUnavailableExpressItems && !hasExpressOnlyItems) {
      clear();
    }
  }, [hasUnavailableItems, hasUnavailableExpressItems, hasExpressOnlyItems]);

  const onClickCaptureHandler: MouseEventHandler = (event) => {
    emitCheckoutClickAction({ event });
  };

  return (
    <>
      <div className={cx(styles.wrapper, className)}>
        <div className={styles.error_wrapper} aria-live="polite">
          {hasError && error?.message && (
            <Typography variant="body" className={styles.error_msg}>
              <WarningIcon width={12} height={12} className={styles.error_icon} />
              <span dangerouslySetInnerHTML={{ __html: error?.message }} />
            </Typography>
          )}
        </div>
        <Button
          size="large"
          isFullWidth
          onClick={handleCheckoutClick}
          onClickCapture={onClickCaptureHandler}
          className={styles.check_out}
          disabled={cartDisabledPartially}
          loading={checkoutInProgress}
          data-testid={'checkout-button'}
        >
          {untilLargeTablet ? mobileButtonText : checkoutButtonText()}
        </Button>
      </div>
      <AgeVerification
        onConfirm={confirmAgeVerification}
        confirmationInProgress={verifyAgeInProgress}
        onDecline={declineAgeVerification}
        declineInProgress={deleteAlcoholInProgress}
        onClose={() => setAgeVerificationOpened(false)}
        open={ageVerificationOpened}
      />
    </>
  );
};
