import { Params, ParamsContext } from '@context/AddToCartContext/AddToCartContext.utils';
import { useModifiedAddToCartLightForContext } from '@hooks/cart/useAddToCartLight';
import { useAddToCartLightEvent } from '@modules/ga/hooks/useAddToCartEvent';
import { useLightCart } from '@hooks/cart/useLightCart';
import { cartAdapter } from '@adapters/cartAdapter';
import { AUTH_ERROR_MESSAGE } from '@constants/errorCodes';
import { useGetPendingOrdersForPage } from '@context/AddToCartContext/hooks/useGetPendingOrdersForPage';
import { useHandleAddToCartAndTrack } from '@context/AddToCartContext/hooks/useAddToCartAndTrack';

const { getLightCart } = cartAdapter();

export function useAddToCartWithPendingOrdersCheck() {
  const getPendingOrdersForPage = useGetPendingOrdersForPage();
  const [modifiedAddToCart] = useModifiedAddToCartLightForContext();
  const addToCartAndTrack = useHandleAddToCartAndTrack();

  const { trackAddToLightCart } = useAddToCartLightEvent();
  const { data: cartData } = useLightCart();

  return async (params: Params & { paramsContext: ParamsContext }) => {
    const { paramsContext } = params;

    try {
      const shouldShowPendingOrdersModal = await getPendingOrdersForPage();

      if (!shouldShowPendingOrdersModal) {
        const newCartApi = await modifiedAddToCart();
        trackAddToLightCart(getLightCart(newCartApi.data?.addToCartLight), cartData, {
          ...paramsContext,
          sponsored: params.product.marketingTags.sponsored,
        });
      }
    } catch (error) {
      if (error instanceof Error && error.message === AUTH_ERROR_MESSAGE) {
        await addToCartAndTrack(params);
      }
    }
  };
}
