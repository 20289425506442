import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import isEqual from 'lodash/isEqual';
import { useReactiveVar } from '@apollo/client';
import {
  Product,
  ProductTile,
  ProductTileVariations,
  SelectedConfiguration,
} from '@commons/product';
import { SimpleCounter, useCounter } from '@components/SimpleCounter';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { useLocalization } from '@hooks/useLocalization';
import { calculateEstimatedWeight } from '@utils/calculateEstimatedWeight';
import { REQUEST_DEBOUNCE_TIME } from '@constants/debounceTime';
import { calculateTotalPrice, getSelectedSalesUnit } from '@utils/calculateTotalPrice';
import { Button } from '@components/UI';
import { ProductConfigurationModal } from '@components/ProductConfigurationModal/ProductConfigurationModal';
import { MODAL_TYPE } from '@components/ProductConfigurationModal/types';
import {
  ConfigurationDropdowns,
  ValidateHandle,
} from '@components/ProductConfigurationModal/components/ConfigurationDropdowns/ConfigurationDropdowns';
import { AddToCartControls } from '@features/ProductPage/components/AddToCart/components/AddToCartControls/AddToCartControls';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { InfoContent } from '@components/ProductConfigurationModal/components/InfoContent/InfoContent';
import { isMealBundle } from '@utils/productHelper';
import { useAddToCartWithOrdersCheck } from '@hooks/cart/useAddToCartWithOrdersCheck';
import { getConfigurationOptions } from '@adapters/product/getProductTile';
import { isEligibleForAddingToShoppingList } from '@utils/isEligibleForAddingToShoppingList';
import { useCurrentDeliveryAddress } from '@hooks/deliveryAddress/useCurrentDeliveryAddress';
import { useProductInfoFromCartLight } from '@hooks/cart/useProductInfoFromCartLight';
import { useProductQuantityInLineItemLight } from '@hooks/cart/useProductQuantityInLineItemLight';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';
import { getProductConfigurationVariables } from '@utils/getProductConfigurationVariables';
import { useLazyVariationItem } from '@components/ProductConfigurationModal/hooks/useLazyVariationItem';
import { AddToShoppingListButton } from '@components/AddToShoppingListButton/AddToShoppingListButton';
import { AddToCartContext } from '@context/AddToCartContext/AddToCartContext';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { useProductListContext } from '@context/ProductListContext';
import { openProductVariationModal } from '@modules/modals/events/productVariationModal';
import { useChooseProductForShoppingList } from '@hooks/shoppingLists/useChooseProductForShoppingList';
import { ProductPageStandingOrderButton } from '@features/ProductPage/components/ProductPageStandingOrderButton/ProductPageStandingOrderButton';
import { ExpressOverlay } from '@components/ExpressOverlay/ExpressOverlay';
import { isFirstExpressOverlayVar } from '@graphql/variables/isFirstExpressOverlayVar';
import { useAuthContext } from '@modules/auth/context';
import { AddToCartProps } from './../AddToCart';
import styles from './../AddToCart.module.scss';

export const TConfigurableProduct = 'ConfigurableProduct';

export const isConfigurableProduct = (product: Product | ProductTile) =>
  !!product.variations?.length ||
  product.soldBySalesUnit ||
  product.salesUnits?.length > 1 ||
  isMealBundle(product) ||
  !!product.bundle.variationGroups.length;

interface ConfigurationChangeHandlerProps {
  salesUnit?: string;
  configs?: SelectedConfiguration[];
}

const DEFAULT_QUANTITY = 1;
const ITEMS_FOR_SHOW_MODAL = 4;
const ITEMS_FOR_SHOW_MODAL_MOBILE = 1;

const getConfigFromVariations = (variations: ProductTileVariations[]) =>
  variations
    .filter(({ values }) => values?.[0]?.name)
    .map(({ name, values }) => ({
      name,
      value: values[0].name,
    }));

export const AddToCartConfigurableProduct: FC<AddToCartProps> = ({
  product,
  className,
  currentPrice,
  prevPrice,
  discount,
  isShowPercentage,
}) => {
  const { data: cachedQuantity } = useProductQuantityInLineItemLight(product?.productId || '');
  const { data: productCartInfo } = useProductInfoFromCartLight(
    product.productId,
    product.soldBySalesUnit,
  );
  const { t } = useLocalization('product');
  const { userStatus } = useAuthContext();
  const isFirstExpressOverlay = useReactiveVar(isFirstExpressOverlayVar);
  const isBundle = isMealBundle(product);
  const { chooseProduct } = useChooseProductForShoppingList();
  const { setProductQuantityInCart } = useContext(AddToCartContext);
  const { getProductPosition } = useProductListContext();
  const { getListName } = usePageListName();
  const { minQuantity: minQty, maxQuantity: maxQty } = product.quantity;
  const { salesUnits, price } = product;
  const [salesUnit, setSalesUnit] = useState<string>(salesUnits[0]?.alternateSalesUnit || '');

  const INITIAL_COUNT = cachedQuantity || minQty || DEFAULT_QUANTITY;

  const {
    handleCounterInput,
    handleDecrease,
    handleIncrease,
    updateCounter,
    quantity,
    increment,
    minValue,
    maxValue,
  } = useCounter({
    initialQuantity: DEFAULT_QUANTITY,
    increment: product.quantity.quantityIncrement,
    minValue: minQty,
    maxValue: maxQty,
  });
  const [totalPrice, setTotalPrice] = useState<number>(price.value || 0);
  const [configs, setConfigs] = useState<SelectedConfiguration[]>([]);
  const [estimatedWeight, setEstimatedWeight] = useState<string | null>(
    calculateEstimatedWeight(product, quantity),
  );
  const fetchVartiationProduct = useLazyVariationItem();

  const [isQuantity, setIsQuantity] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [htmlUrl, setHtmlUrl] = useState<string | null>(null);
  const [addToCart, { loading: addToCartInProgress }] = useAddToCartWithOrdersCheck(product);

  const [hasCounter, setHasCounter] = useState(false);

  const { isMobile, isSmallScreen } = useHeaderContext();
  const untilLargeTablet = !isSmallScreen;

  const [isCustomizeModalOpen, setIsCustomizeModalOpen] = useState(false);
  const isOrdered = !!cachedQuantity;
  const dropdownsRef = useRef<ValidateHandle>(null);
  const [isCounterManageQuantity, setIsCounterManageQuantity] = useState<boolean>(true);
  const shouldShowTotalPrice = !isOrdered || hasCounter;
  const { address: currentAddress } = useCurrentDeliveryAddress();
  const { enabled: expressEnabled } = useExpressContext();
  const resetConfigs = () => {
    setHasCounter(!product.soldBySalesUnit);
    setIsCounterManageQuantity(false);
  };

  useEffect(() => {
    setSalesUnit(salesUnits[0]?.alternateSalesUnit ?? '');
    if (expressEnabled) {
      setConfigs(getConfigFromVariations(product.variations));
    } else {
      setConfigs([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAddress.id, expressEnabled]);

  const configurationChangeHandler = useCallback(
    ({ salesUnit: changedSalesUnit, configs: changedConfigs }: ConfigurationChangeHandlerProps) => {
      if (changedSalesUnit && !isEqual(changedSalesUnit, salesUnit)) {
        setSalesUnit(changedSalesUnit);
      }
      if (changedConfigs?.length && !isEqual(changedConfigs, configs)) {
        setConfigs(changedConfigs.filter(({ name, value }) => !!name && !!value));
      }
      if (isOrdered && isCounterManageQuantity && hasCounter) {
        resetConfigs();
      }
      if (!isCounterManageQuantity) {
        updateCounter(minQty || DEFAULT_QUANTITY);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOrdered, salesUnit, configs, isCounterManageQuantity, minQty],
  );

  useEffect(() => {
    setTotalPrice(
      calculateTotalPrice(product, {
        ratio: getSelectedSalesUnit(salesUnits, salesUnit)?.ratio || 1,
        quantity,
        selectedConfigurations: configs,
      }),
    );
  }, [configs, quantity, product, isCounterManageQuantity, salesUnit, salesUnits]);

  useEffect(() => {
    if (!isCounterManageQuantity) {
      updateCounter(minQty || DEFAULT_QUANTITY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCounterManageQuantity, minQty]);

  useEffect(() => {
    setEstimatedWeight(
      calculateEstimatedWeight(product, quantity, getSelectedSalesUnit(salesUnits, salesUnit)),
    );
  }, [quantity, product, salesUnit, salesUnits]);

  const handleInfoIconClick = () => {
    setHtmlUrl(product?.estimatedWeightDisclaimer || null);
    setModalTitle(t('configurationModal.moreInfo.weightTitle'));
    setIsModalOpen((prev) => !prev);
  };

  const handleShowInfo = async (id: string) => {
    const productVariation = await fetchVartiationProduct(id);
    if (productVariation) {
      openProductVariationModal({ productVariation });
    }
  };

  const updateCartLineQuantity = useCallback(
    (prevQuantity: number, newQuantity: number) => {
      const getConfiguration = () => {
        if (product.configuration.length) return product.configuration;
        return getProductConfigurationVariables(
          configs.length ? configs : getConfigFromVariations(product.variations),
        );
      };

      setProductQuantityInCart({
        variables: {
          productDataInput: {
            categoryId: product.categoryId,
            productId: product.productId,
            skuCode: product.skuCode,
            quantity: newQuantity,
            itemListName: getListName(product),
            itemPosition: getProductPosition(product.productId),
            configuration: getConfiguration(),
            salesUnit,
          },
        },
        onError: () => {
          updateCounter(prevQuantity);
        },
      });
    },
    [
      setProductQuantityInCart,
      product,
      getListName,
      getProductPosition,
      salesUnit,
      configs,
      updateCounter,
    ],
  );

  const updateProductQuantityInCart = () => {
    const isValid = dropdownsRef?.current?.validate ? dropdownsRef.current.validate() : true;
    if (isValid) {
      updateCartLineQuantity(cachedQuantity, debouncedQuantity);
    }
  };

  const debouncedQuantity = useDebounce<number>(quantity, REQUEST_DEBOUNCE_TIME);

  useUpdateEffect(() => {
    // TODO remove this refactor useCounter useEffect instead
    if (isCounterManageQuantity) {
      updateCartLineQuantity(cachedQuantity, debouncedQuantity);
    }
  }, [debouncedQuantity, isCounterManageQuantity]);

  useEffect(() => {
    if (!isQuantity && !isCounterManageQuantity) {
      updateCounter(INITIAL_COUNT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQuantity, isCounterManageQuantity]);

  useEffect(() => {
    setIsQuantity(!!productCartInfo?.count);
  }, [productCartInfo, setIsQuantity]);

  const handleMoreInfoClick = (link: string) => {
    setHtmlUrl(link);
    setModalTitle('');
    setIsModalOpen(true);
  };

  const openCustomizeModal = () => {
    setIsCustomizeModalOpen(true);
  };

  const closeCustomizeModal = () => {
    setIsCustomizeModalOpen(false);
  };

  const handleCustomizeButtonClick = () => {
    openCustomizeModal();
  };

  const addProductToCart = () => {
    const isValid = dropdownsRef?.current?.validate ? dropdownsRef.current.validate() : true;
    if (isValid) {
      addToCart({
        quantity: quantity,
        configuration: getProductConfigurationVariables(configs),
        salesUnit,
      });
      setHasCounter(!product.soldBySalesUnit);
    }
  };

  const handleAddButtonClick = () => {
    if (!isCounterManageQuantity || !cachedQuantity) {
      addProductToCart();
    } else {
      updateProductQuantityInCart();
    }
  };

  const renderCTAButton = (isModalCustomize: boolean) => {
    if (isModalCustomize) {
      return (
        <Button onClick={handleCustomizeButtonClick} size="large" isFullWidth>
          {isOrdered ? t('buttons.selectMore') : t('buttons.customize')}
        </Button>
      );
    }
    if (isOrdered && isCounterManageQuantity) {
      return (
        <Button className={styles.item} size="large" onClick={resetConfigs}>
          {t('buttons.selectMore')}
        </Button>
      );
    }
    return (
      <Button onClick={handleAddButtonClick} size="large" isFullWidth loading={addToCartInProgress}>
        {t('buttons.addToBasket')}
      </Button>
    );
  };

  const handleAddToShoppingList = () => {
    const isAllowToSend = dropdownsRef?.current?.validate ? dropdownsRef.current.validate() : true;

    if (!isAllowToSend) {
      return;
    }

    const defaultConfigs = getConfigurationOptions(product.variations);

    chooseProduct({
      product,
      configurationOptions: configs.length > 0 ? configs : defaultConfigs,
      salesUnit,
    });
  };

  const renderOrderComponent = () => {
    return renderDesktopOrderComponent();
  };

  const renderAddToShoppingListButton = () => {
    return (
      <AddToShoppingListButton
        product={product}
        listLabel={t('common:shoppingList.addToList')}
        classNames={styles.add_to_shopping_list_button}
        shouldRenderAddToShoppingListButton={isEligibleForAddingToShoppingList(product)}
        handleAddToShoppingList={handleAddToShoppingList}
      />
    );
  };

  const renderDesktopOrderComponent = () => {
    const isModalConfigurations =
      (untilLargeTablet && product.variations?.length >= ITEMS_FOR_SHOW_MODAL) ||
      (!untilLargeTablet && product.variations?.length >= ITEMS_FOR_SHOW_MODAL_MOBILE);

    return (
      <div>
        {!isModalConfigurations && (
          <ConfigurationDropdowns
            isBundle={isBundle}
            bundleVariations={product.bundle.variationGroups}
            salesUnits={salesUnits}
            variations={product.variations}
            initialValues={{
              salesUnit,
              variables: configs,
            }}
            onChange={configurationChangeHandler}
            ref={dropdownsRef}
            allowTwoItemsInLine={true}
            onMoreInfoClick={handleMoreInfoClick}
            onShowVariationInfo={handleShowInfo}
          />
        )}
        {hasCounter && (
          <div className={styles.counter_wrapper}>
            <SimpleCounter
              className={styles.counter}
              value={quantity}
              step={increment}
              size="extraLarge"
              onMinusClick={handleDecrease}
              onPlusClick={handleIncrease}
              onChange={handleCounterInput}
              minValue={minValue}
              maxValue={maxValue}
              buttonArialLabel={product.productName}
            />
          </div>
        )}
        {(!hasCounter || !isCounterManageQuantity) && !userStatus && (
          <>{renderCTAButton(isModalConfigurations)}</>
        )}
        {(!hasCounter || !isCounterManageQuantity) && !!userStatus && (
          <ExpressOverlay
            isFirstLoad={isFirstExpressOverlay}
            placement={isMobile ? 'top' : 'bottom-start'}
            hasArrow={false}
            className={styles.regular_express_overlay}
          >
            {renderCTAButton(isModalConfigurations)}
          </ExpressOverlay>
        )}
        <ProductPageStandingOrderButton product={product} />
        {renderAddToShoppingListButton()}
      </div>
    );
  };

  return (
    <AddToCartControls
      className={className}
      shouldShowTotalPrice={shouldShowTotalPrice}
      product={product}
      totalPrice={totalPrice}
      estimatedWeight={estimatedWeight}
      shouldShowQuantity={!!cachedQuantity}
      quantity={cachedQuantity}
      currentPrice={currentPrice}
      prevPrice={prevPrice}
      discount={discount}
      isShowPercentage={isShowPercentage}
    >
      {renderOrderComponent()}

      {isModalOpen && htmlUrl && (
        <ModalWindow open={isModalOpen} onClose={handleInfoIconClick} className={styles.content}>
          <InfoContent url={htmlUrl} title={modalTitle} className={styles.window_disclaimer} />
        </ModalWindow>
      )}

      {isCustomizeModalOpen && (
        <ProductConfigurationModal
          product={product}
          open={isCustomizeModalOpen}
          onClose={closeCustomizeModal}
          initialValues={{}}
          variant={MODAL_TYPE.PRODUCT}
        />
      )}
    </AddToCartControls>
  );
};
