import { useApolloClient } from '@apollo/client';
import { useProductListLocation } from '@modules/ga/context/location';
import { defaultTitleName, useProductListTitle } from '@modules/ga/context/title';
import { LightweightCart } from '@api';
import { GET_LIGHT_CART } from '@graphql/cart/queries/getLightCart';
import { cartAdapter } from '@adapters/cartAdapter';
import { useProductListContext } from '@context/ProductListContext';
import { ProductMarketingTags } from '@commons/product';

const { getLightCart } = cartAdapter();

export const usePageListName = () => {
  const { getChannel } = useProductListContext();
  const { getLocation } = useProductListLocation();
  const { getTitle } = useProductListTitle();
  const client = useApolloClient();

  const getListNameFromLightCart = (id: string) => {
    const lightCart = client.cache.readQuery<{ lightweightCart: DeepPartial<LightweightCart> }>({
      query: GET_LIGHT_CART,
    });
    const cartLine = getLightCart(lightCart?.lightweightCart).cartLines.find(
      ({ productId }) => productId === id,
    );

    return cartLine?.itemListName;
  };

  const getListNameFromContext = (
    product?: {
      variantId?: string;
      marketingTags?: ProductMarketingTags;
    },
    channelName?: string,
  ) => {
    const title = getTitle();
    const titleText = title && title !== defaultTitleName ? `-title_${title}` : '';
    return `channel_${getChannel(product, channelName)}-loc_${getLocation()}${titleText}`;
  };

  return {
    getListName: (
      product?: {
        variantId?: string;
        productId?: string;
        marketingTags?: ProductMarketingTags;
      },
      channelName?: string,
    ) =>
      (product?.productId && getListNameFromLightCart(product.productId)) ||
      getListNameFromContext(product, channelName),
  };
};
