import { useState, useEffect } from 'react';

interface UseDelayedOpenParams {
  value: string;
  needsDelay: boolean;
}

export const useDelayedOpen = ({ value, needsDelay }: UseDelayedOpenParams) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    let timer = null;

    if (needsDelay && Number(value) > 0) {
      timer = setTimeout(() => setIsOpen(true), 0);
    } else {
      setIsOpen(Number(value) > 0);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [value, needsDelay]);

  return isOpen;
};
