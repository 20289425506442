import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { AccountPreferences } from '@commons/account';
import { AccountAlert } from '@features/AccountPage/components/AccountAlert/AccountAlert';
import { AccountPageLoader } from '@features/AccountPage/components/AccountPageLoader/AccountPageLoader';
import { useScrollToAnchor } from '@hooks/useScrollToAnchor';
import { useAuthContext } from '@modules/auth/context';
import { getOtpLoginTypes } from './helpers';
import styles from './AccountPagePreferences.module.scss';
import {
  DeleteAccount,
  EmailBlock,
  LinkedAccounts,
  PasswordBlock,
  PersonalInfo,
  PhoneBlock,
} from './components/index';

interface AccountPagePreferencesProps {
  accountPreferences: AccountPreferences;
  loading: boolean;
  fetchAccountPreferences: () => void;
}

export const AccountPagePreferences = ({
  accountPreferences,
  loading,
  fetchAccountPreferences,
}: AccountPagePreferencesProps) => {
  useScrollToAnchor(loading);
  const passwordBlockRef = useRef<HTMLDivElement>(null);
  const { userInfo } = useAuthContext();

  const [sendNewsLetter, setSendNewsLetter] = useState(
    accountPreferences.notifications.sendNewsLetter,
  );

  const { otpLoginType } = accountPreferences;
  const { isEmailOtpLogin, isPhoneOtpLogin, isPasswordLogin } = getOtpLoginTypes(otpLoginType);

  const connectedSocials = {
    socialName: accountPreferences?.socialProviders || [],
    email: accountPreferences?.email || '',
    isSocialOnly: !!accountPreferences?.isSocialOnly,
  };
  const hasConnectedSocials = !!connectedSocials.socialName?.length || false;

  useEffect(() => {
    if (!loading && window.location.hash === '#password') {
      setTimeout(() => {
        passwordBlockRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 1000);
    }
  }, [loading]);

  useEffect(() => {
    setSendNewsLetter(userInfo?.notifications?.sendNewsLetter as boolean);
  }, [userInfo?.notifications?.sendNewsLetter]);

  return (
    <div className={cx(styles.wrapper, { [styles.wrapper_loading]: loading })}>
      {loading && <AccountPageLoader />}
      <div className={cx(styles.container, { [styles.container_loading]: loading })}>
        <div className={styles.content}>
          <AccountAlert />
          <PersonalInfo />
          <PhoneBlock isPhoneOtpLogin={isPhoneOtpLogin} />
          <EmailBlock
            email={connectedSocials.email}
            sendNewsLetter={sendNewsLetter}
            isSocialOnly={connectedSocials.isSocialOnly}
            isEmailOtpLogin={isEmailOtpLogin}
          />
          <div ref={passwordBlockRef}>
            <PasswordBlock
              loginType={otpLoginType}
              isPasswordLogin={isPasswordLogin}
              fetchAccountPreferences={fetchAccountPreferences}
            />
          </div>
          {hasConnectedSocials && <LinkedAccounts connectedSocials={connectedSocials} />}
          <DeleteAccount />
        </div>
      </div>
    </div>
  );
};
