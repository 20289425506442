import React, { ReactEventHandler, useEffect, useState } from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { DropdownMenu, NavigationMenuItem } from '@api';
import { useNavigationData } from '@hooks/header/useNavigationData';
import { arrowDown as ArrowDown } from '@assets/icons/system';
import { LinkV2 } from '@components/UI';
import { routing } from '@constants/routing';
import { dealsAnonSubmenu, dealsSubmenu } from '@constants/dealsSubmenu';
import { useAuthContext } from '@modules/auth/context';
import { useLocalization } from '@hooks/useLocalization';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { LeftNavSkeleton } from '@components/Skeletons/LeftNavSkeleton/LeftNavSkeleton';
import { getCurrentPageType } from '@modules/ga/utils/getPageType';
import { fireNavigationClickEvent } from '@modules/ga/events/custom/navigation-click/navigationClickCreator';
import {
  AdditionalLeftNavProps,
  fullyIncludes,
  getAllParentElements,
  getLeftNavData,
  LeftNavProps,
  shouldLoadAdditionalLeftNav,
  shouldLoadDealsSalesLeftNav,
} from './leftNavHelper';
import styles from './LeftNav.module.scss';

const NAVIGATION_PLACEMENT = 'Left Navigation Bar';

const handleArrowDownClick: ReactEventHandler = (e) => {
  e.stopPropagation();
  const currentBtn = e.currentTarget;
  const currentList = currentBtn?.parentElement;
  const currentNav = document.getElementById('left_nav');

  const allLists = currentNav?.querySelectorAll('ul');
  const allBtns = currentNav?.querySelectorAll('button');

  const subLists = currentList?.parentElement?.querySelectorAll('ul');
  const parentLists = getAllParentElements('ul', currentList);
  const parentBtns = getAllParentElements('li', currentList)?.map((li) =>
    li.querySelector('button'),
  );

  currentBtn.classList.toggle(styles.rotate);

  currentBtn.getAttribute('aria-expanded') === 'true'
    ? currentBtn.setAttribute('aria-expanded', 'false')
    : currentBtn.setAttribute('aria-expanded', 'true');

  subLists?.[0]?.classList.toggle(styles.is_open);

  allLists?.forEach((list) => {
    if (list !== subLists?.[0] && !(parentLists as HTMLElement[]).includes(list)) {
      list.classList.remove(styles.is_open);
    }
  });

  allBtns?.forEach((btn) => {
    if (btn !== currentBtn && !parentBtns?.includes(btn)) {
      btn.classList.remove(styles.rotate);
      btn.setAttribute('aria-expanded', 'false');
    }
  });

  currentBtn.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

const triggerEvent = (titleOfLinkClicked: string) => {
  fireNavigationClickEvent(titleOfLinkClicked, NAVIGATION_PLACEMENT, getCurrentPageType());
};

const renderLeftNav = ({ children, isOpen, routerAsPath, isRoot, title }: LeftNavProps) => {
  if (!children?.length) {
    return null;
  }

  return (
    <ul className={cx({ [styles.is_root]: isRoot, [styles.is_open]: isOpen })} aria-label={title}>
      {children.map((child, index) => {
        const isActive = fullyIncludes(routerAsPath, child.url) || routerAsPath == child.url;
        const isInPath = fullyIncludes(routerAsPath, child.id);
        const shouldOpen = isActive || isInPath;

        return (
          <li
            key={`${child.id}_${index}`}
            className={cx(styles.list_item, { [styles.is_active]: isActive })}
          >
            {child.children?.length === 0 ? (
              <LinkV2
                href={child.url}
                className={cx(styles.leaf, styles.link)}
                onClick={() => {
                  triggerEvent(child?.name);
                }}
              >
                {child.name}
                {isActive && <span className="sr-only">selected</span>}
              </LinkV2>
            ) : (
              <>
                <div className={styles.link_wrapper}>
                  <LinkV2
                    href={child.url}
                    onClick={() => {
                      triggerEvent(child?.name);
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: child.name }} />
                    {isActive && <span className="sr-only">selected</span>}
                  </LinkV2>
                  {child.children?.length !== 0 && (
                    <button
                      className={cx(styles.arrow_down, { [styles.rotate]: shouldOpen })}
                      onClick={handleArrowDownClick}
                      aria-label={child.name}
                      aria-expanded={shouldOpen}
                    >
                      <ArrowDown height={16} width={16} />
                    </button>
                  )}
                </div>
                {renderLeftNav({
                  children: child.children || [],
                  isOpen: shouldOpen,
                  routerAsPath,
                  title: child.name,
                })}
              </>
            )}
          </li>
        );
      })}
    </ul>
  );
};

const renderAdditionalLeftNav = ({ additionalMenuItems, routerAsPath }: AdditionalLeftNavProps) => {
  return (
    <ul>
      {additionalMenuItems.map((item, index) => {
        const isActive = () => {
          return (
            routerAsPath === item?.link ||
            (item?.link && fullyIncludes(routerAsPath, item.link)) ||
            (item?.linkId && fullyIncludes(routerAsPath, item.linkId))
          );
        };

        return (
          <li
            key={`${item?.name}_${index}`}
            className={cx(styles.list_item, {
              [styles.is_active]: isActive(),
            })}
          >
            <LinkV2
              href={item?.link}
              className={cx(styles.link)}
              onClick={() => {
                triggerEvent(item?.name ?? '');
              }}
            >
              {item?.name}
              {isActive() && <span className="sr-only">selected</span>}
            </LinkV2>
          </li>
        );
      })}
    </ul>
  );
};

export const LeftNav = () => {
  const router = useRouter();
  const { t } = useLocalization();
  const { isKnownUser, isUserInfoLoading } = useAuthContext();
  const { leftNavigation, additionalMenus, loading: isNavLoading } = useNavigationData();
  const { breadcrumbInfo, setIsEmptyNav } = useHeaderContext();
  const [highlightPath, sethighlightPath] = useState(router.asPath);
  const [leftNavData, setLeftNavData] = useState(getLeftNavData(leftNavigation, router.asPath));
  const [additionalLeftNav, setAdditionalLeftNav] = useState<DropdownMenu | null>(null);
  const isLoading = isUserInfoLoading || isNavLoading;
  const shouldRenderLeftNav = !!leftNavData && leftNavData?.length > 0;
  const shouldRenderAdditionalLeftNav =
    !!additionalLeftNav?.menuItems?.length && additionalLeftNav?.menuItems?.length > 0;

  useEffect(() => {
    if (shouldLoadAdditionalLeftNav(additionalMenus, router.asPath)) {
      setAdditionalLeftNav(additionalMenus?.[0]);
      sethighlightPath(router.asPath);
      setLeftNavData([]);
    } else if (shouldLoadDealsSalesLeftNav(router.pathname)) {
      setAdditionalLeftNav(isKnownUser ? dealsSubmenu : dealsAnonSubmenu);
      sethighlightPath(router.asPath);
      setLeftNavData([]);
    } else {
      setAdditionalLeftNav(null);
    }
  }, [isKnownUser, router]);

  useEffect(() => {
    if (!!breadcrumbInfo && breadcrumbInfo.length > 0 && !additionalLeftNav) {
      const breadCrumbPath = breadcrumbInfo[breadcrumbInfo.length - 1].url;
      if (breadCrumbPath) {
        sethighlightPath(breadCrumbPath);
        setLeftNavData(getLeftNavData(leftNavigation, breadCrumbPath));
      }
    }
  }, [breadcrumbInfo, additionalLeftNav]);

  useEffect(() => {
    if (!isLoading) {
      setIsEmptyNav?.(!shouldRenderLeftNav && !shouldRenderAdditionalLeftNav);
    }
  }, [shouldRenderLeftNav, shouldRenderAdditionalLeftNav, isLoading]);

  if (shouldRenderLeftNav) {
    return (
      <nav className={styles.left_nav} id="left_nav" aria-label={leftNavData[0]?.name}>
        <h2>
          <LinkV2
            href={leftNavData[0]?.url}
            className={cx(styles.link, styles.header)}
            onClick={() => {
              triggerEvent(leftNavData[0]?.name ?? '');
            }}
          >
            {leftNavData[0]?.name}
          </LinkV2>
        </h2>

        {renderLeftNav({
          children: leftNavData[0].children,
          routerAsPath: highlightPath.split('?')[0],
          isOpen: true,
          isRoot: true,
          title: leftNavData[0]?.name,
        })}
      </nav>
    );
  }

  if (shouldRenderAdditionalLeftNav) {
    return (
      <nav className={styles.additional_left_nav}>
        {shouldLoadDealsSalesLeftNav(router.pathname) && (
          <h2>
            <LinkV2
              href={routing.dealsAndSale}
              className={cx(styles.link, styles.header)}
              onClick={() => {
                triggerEvent(t('pageHeader.dealsAndSales'));
              }}
            >
              {t('pageHeader.dealsAndSales')}
            </LinkV2>
          </h2>
        )}

        {renderAdditionalLeftNav({
          additionalMenuItems: additionalLeftNav.menuItems as NavigationMenuItem[],
          routerAsPath: highlightPath,
        })}
      </nav>
    );
  }

  if (isLoading) {
    return <LeftNavSkeleton />;
  }

  return null;
};
