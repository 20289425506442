import { Grid, Typography } from '@mui/material';
import { DepartmentItem, Maybe } from '@api';
import { CategoriesSectionDivider } from './CategoriesSectionDivider';
import { CategoriesSectionGrid } from './CategoriesSectionGrid';
import { CategoryLink } from './CategoryLink';
import { CategoriesSectionSeeAllLinks } from './CategoriesSectionSeeAllLinks';
import { getSectionColumns, getSectionRows } from './getters';

interface CategoriesSectionDualViewProps {
  department: DepartmentItem;
  showHeadlines?: boolean;
  onClick?: () => void;
  headlines: Array<Maybe<string> | undefined>;
  categoryClick?: (name?: string) => void;
}

export const CategoriesSectionDualView = ({
  department: { dropDownCategories, id: departmentId, url },
  showHeadlines = true,
  onClick,
  headlines,
  categoryClick,
}: CategoriesSectionDualViewProps) => {
  if (!dropDownCategories) return null;
  const sectionColumns = getSectionColumns(dropDownCategories);

  return (
    <>
      {dropDownCategories.map((dropDownCategory, index) =>
        dropDownCategory.categoryMap?.map(({ headline, categories }) => {
          const sectionRows = getSectionRows(categories, sectionColumns);
          return (
            <Grid key={JSON.stringify(categories)} item lg={sectionColumns} pl={5}>
              <CategoriesSectionDivider isShown={Boolean(headlines[index + 1])}>
                <Typography fontWeight="bold" variant="body" component="h2">
                  {showHeadlines && headline}&nbsp;
                </Typography>
                <CategoriesSectionGrid rows={sectionRows} columns={sectionColumns}>
                  {categories?.map(({ id: categoryId, name, url: categoryUrl }) => {
                    return (
                      <CategoryLink
                        key={categoryId}
                        name={name}
                        tabIndex={-1}
                        headline={headline}
                        href={categoryUrl}
                        onClick={onClick}
                        categoryClick={categoryClick}
                      />
                    );
                  })}
                </CategoriesSectionGrid>
              </CategoriesSectionDivider>
            </Grid>
          );
        }),
      )}
      <CategoriesSectionSeeAllLinks
        headlines={headlines}
        sectionColumns={sectionColumns}
        url={url}
      />
    </>
  );
};
