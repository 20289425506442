import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import Trans from 'next-translate/Trans';
import classNames from 'classnames';
import { Button, Link } from '@components/UI';
import { ProductsGrid } from '@components/ProductsGrid';
import { Product, ProductBasic, ProductTile as IProductTile } from '@commons/product';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { CartLine, SingleStoreCartLine as SingleStoreCartLineType } from '@commons/cart';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import { CAROUSEL_TYPES } from '@commons/carousel';
import { useLocalization } from '@hooks/useLocalization';
import { useDeleteCartLine } from '@components/Tiles/CartTile/components/Controllers/hooks/cartHooks/useDeleteCartLine';
import { KeepOrReplaceModalAlert } from '@components/OrderList/components/ReplaceModal/components/KeepOrReplaceModalAlert';
import { useResetCartLineUnavailability } from '@hooks/cart/useResetCartLineUnavailability';
import { useCartForCheckoutContext } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { ProductTileManager } from '@components/Tiles/ProductTile/ProductTileManager';
import { DYNAMIC_FEATURES } from '@commons/dynamicFeatures';
import { isFeatureEnabled } from '@utils/dynamicFeatures';
import { GaValuesProps } from '@modules/ga/context/channel';
import { getCartLineByLineId } from '@modules/ga/eventBodyGetters/utils/getCartLine';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { OrderListProductTile } from '../OrderListProductTile/OrderListProductTile';
import styles from './ReplaceModal.module.scss';

export interface ReplaceProductProps {
  isReplaced: boolean;
  lineToReplace?: string;
  setShouldUpdate: Dispatch<SetStateAction<boolean>>;
  shouldUpdate: boolean;
  loading: boolean;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  onReplace?: (promise: Promise<any>) => void;
  setShouldRefetchCart?: Dispatch<SetStateAction<boolean>>;
  onReplaceCompleted?: (
    selectedProductId: string,
    selectedCartLine?: SingleStoreCartLineType,
  ) => void;
}

interface ReplaceModalProps {
  open: boolean;
  onClose: () => void;
  productToReplace: (Product | ProductBasic)[];
  urlAlternative: string;
  cartLineId: string;
  onReplaceCompleted: (
    selectedProductId: string,
    selectedCartLine?: SingleStoreCartLineType,
  ) => void;
  isNonExpress: boolean;
  availableQuantity: number | null;
  gaValues?: GaValuesProps;
  selectedCartLine?: SingleStoreCartLineType;
}

export const ReplaceModal = ({
  open,
  onClose,
  productToReplace,
  urlAlternative,
  cartLineId,
  onReplaceCompleted,
  isNonExpress,
  availableQuantity,
  gaValues,
  selectedCartLine,
}: ReplaceModalProps) => {
  const { t } = useLocalization();
  const [loading, setLoading] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const [shouldRefetchCart, setShouldRefetchCart] = useState<boolean>(false);
  const replacedRef = useRef(false);
  const titleKey = isNonExpress ? 'cart:expressAlternatives' : 'cart:tryAlternatives';
  const [isKeepQuantityChecked, setIsKeepQuantityChecked] = useState(true);
  const isKeepOrReplaceModal = !!availableQuantity;
  const [resetCartLineUnavailability, { loading: resetCartLineUnavailabilityLoading }] =
    useResetCartLineUnavailability();
  const { cartInfo } = useCartForCheckoutContext();
  const [replacedCartLine, setReplacedCartLine] = useState<
    CartLine | SingleStoreCartLineType | undefined
  >(undefined);
  const [shouldDeleteItem, setShouldDeleteItem] = useState<boolean>(false);
  const { getListName } = usePageListName();
  const { deleteItem, deleteInProgress: deleteCartLineLoading } = useDeleteCartLine({
    cartLineId,
    replacedCartLine,
    listName: getListName(),
  });

  const isV2 = isFeatureEnabled(DYNAMIC_FEATURES.NEW_PRODUCT_TILES);
  const confirmChangesButtonLoading =
    deleteCartLineLoading || cartInfo.loading || resetCartLineUnavailabilityLoading;

  const toggleKeepQuantityChecked = () => {
    setIsKeepQuantityChecked((prevState) => !prevState);
  };

  useEffect(() => {
    if (shouldRefetchCart) {
      cartInfo.refetch();
      setShouldRefetchCart(false);
    }
  }, [shouldRefetchCart]);

  useEffect(() => {
    if (shouldDeleteItem) {
      handleDeleteItem();
    }
  }, [shouldDeleteItem]);
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleReplaceItem = async (promise: any) => {
    setLoading(true);
    try {
      await promise;
      replacedRef.current = true;
    } catch (e) {}
    setLoading(false);
  };

  const handleClose = () => {
    replacedRef.current = false;
    if (!isKeepOrReplaceModal) {
      onClose();
    }
  };

  const handleKeepOrReplace = async () => {
    if (isKeepQuantityChecked) {
      await resetCartLineUnavailability({ variables: { cartLineId } });
      await cartInfo.refetch();
    } else {
      setReplacedCartLine(getCartLineByLineId(cartInfo.data, cartLineId) ?? selectedCartLine);
      setShouldDeleteItem(true);
    }
    onClose();
  };

  const handleDeleteItem = async () => {
    setShouldDeleteItem(false);
    await deleteItem();
  };

  const replaceProductInfo: ReplaceProductProps = {
    isReplaced: isKeepOrReplaceModal ? true : replacedRef.current,
    lineToReplace: cartLineId ? cartLineId : undefined,
    setShouldUpdate: setShouldUpdate,
    shouldUpdate: shouldUpdate,
    loading: loading,
    onReplace: isKeepOrReplaceModal ? undefined : handleReplaceItem,
    onReplaceCompleted: isKeepOrReplaceModal ? undefined : onReplaceCompleted,
    setShouldRefetchCart: setShouldRefetchCart,
  };

  return (
    <ModalWindow
      open={open}
      onClose={handleClose}
      isScrollable
      labelId={MODAL_TITLE_ID.REPLACE_PRODUCT}
      className={styles.replace_modal}
      showCloseButton={!isKeepOrReplaceModal}
    >
      <>
        <Typography
          variant="h3"
          component="h2"
          className={classNames(styles.alternative_modal_title, {
            [styles.with_margin_bottom]: isKeepOrReplaceModal,
          })}
          id={MODAL_TITLE_ID.REPLACE_PRODUCT}
        >
          <Trans
            i18nKey={titleKey}
            components={{
              link: (
                <Link
                  classNameLinkText={styles.alternative_modal_title_link}
                  href={`${urlAlternative}`}
                />
              ),
            }}
          />
        </Typography>
        {isKeepOrReplaceModal && (
          <KeepOrReplaceModalAlert
            checked={isKeepQuantityChecked}
            toggleCheckbox={toggleKeepQuantityChecked}
            availableQuantity={availableQuantity}
          />
        )}

        <div className={classNames({ [styles.content_wrapper]: isKeepOrReplaceModal })}>
          <ProductsGrid
            xs={6}
            sm={4}
            products={productToReplace as IProductTile[]}
            gaValues={gaValues}
            customTileComponent={(product) => (
              <>
                {isV2 ? (
                  <ProductTileManager
                    product={product}
                    productCarouselType={CAROUSEL_TYPES.MODULE_DEFAULT}
                    replaceProductInfo={replaceProductInfo}
                    hideShoppingListButton
                    isV2
                    gaValues={gaValues}
                  />
                ) : (
                  <OrderListProductTile
                    product={product}
                    productCarouselType={CAROUSEL_TYPES.MODULE_DEFAULT}
                    setShouldUpdate={setShouldUpdate}
                    shouldUpdate={shouldUpdate}
                    loading={loading}
                    {...(!!cartLineId && { lineToReplace: cartLineId })}
                    {...(!!isKeepOrReplaceModal && { isReplaced: true })}
                    {...(!isKeepOrReplaceModal && { isReplaced: replacedRef.current })}
                    {...(!isKeepOrReplaceModal && { onReplace: handleReplaceItem })}
                    {...(!isKeepOrReplaceModal && { onReplaceCompleted: onReplaceCompleted })}
                    gaValues={gaValues}
                  />
                )}
              </>
            )}
          />
        </div>
        {isKeepOrReplaceModal && (
          <div className={styles.modal_button_wrapper}>
            <Button
              loading={confirmChangesButtonLoading}
              size="large"
              onClick={handleKeepOrReplace}
              isFullWidth
            >
              {t('cart:confirmChanges')}
            </Button>
          </div>
        )}
      </>
    </ModalWindow>
  );
};
