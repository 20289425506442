import { fireEvent } from '@modules/ga/emitter';
import { GA_CUSTOM_EVENT_ACTION } from '@modules/ga/type';
import { GA_EVENT_TYPE } from '@modules/ga/constants';
import { Cart } from '@commons/cart';

export const fireCheckoutWithoutUnavailableItemsEvent = ({
  cart,
  selectedTimeslot,
  unavailableItems,
}: {
  cart: Cart;
  selectedTimeslot?: string | null;
  unavailableItems?: (string | null)[];
}) => {
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_CUSTOM_EVENT_ACTION.CHECKOUT_WITHOUT_UNAVAILABLE_ITEMS,
    data: {
      cart,
      selectedTimeslot,
      unavailableItems,
    },
  });
};
