import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { sendGtmEvent } from '@modules/ga/gtm-event';

export interface AppRedirectClickEventOptions {
  paymentType: string;
}

export const addPaymentEventHandler = ({ paymentType }: AppRedirectClickEventOptions) => {
  sendGtmEvent(
    GtmCustomEvent({
      /* eslint-disable @typescript-eslint/naming-convention */
      event: 'add_payment_method',
      event_name: 'add_payment_method_click',
      ua_action: 'Add Payment Method',
      paymentType: paymentType,
    }),
  );
};
