import {
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useProductsPageSize } from '@hooks/index';
import { ScreenSizeProps, WindowSize, useWindowSize } from '@hooks/useWindowDimensions';
import { useFeatureEligibility } from '@hooks/common/useFeatureEligibility';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';
import { DYNAMIC_FEATURES } from '@commons/dynamicFeatures';
import { isFeatureEnabled } from '@utils/dynamicFeatures';
import { BreadcrumbItem } from '@commons/breadcrumbs';
import { LAYOUTS } from '@commons/layouts';

interface HeaderContextInterface {
  headerContentRef: RefObject<HTMLDivElement>;
  windowSize?: WindowSize;
  screenSize?: ScreenSizeProps;
  isDesktop?: boolean;
  isLaptop?: boolean;
  isTablet?: boolean;
  isMobile?: boolean;
  isLargeTablet?: boolean;
  isSmallScreen?: boolean;
  skeletonPageSize: number;
  productsPageSize: number;
  getFeature?: (feature: FEATURE) => boolean;
  isLeftLayoutEnabled?: boolean;
  breadcrumbInfo?: BreadcrumbItem[] | null;
  setBreadcrumbInfo?: Dispatch<SetStateAction<BreadcrumbItem[] | null>>;
  isStandingOrderEnabled?: boolean;
  isTopNavDropdownEnabled?: boolean;
  isEmptyNav?: boolean;
  setIsEmptyNav?: Dispatch<SetStateAction<boolean>>;
}

interface HeaderContextProviderProps {
  children: ReactNode;
  layout: string;
}

const HeaderContext = createContext<HeaderContextInterface>({
  headerContentRef: { current: null },
  skeletonPageSize: 0,
  productsPageSize: 0,
  breadcrumbInfo: null,
  setBreadcrumbInfo: () => undefined,
});

export const useHeaderContext = () => useContext(HeaderContext);

export const HeaderContextProvider = ({ children, layout }: HeaderContextProviderProps) => {
  const headerContentRef = useRef<HTMLDivElement>(null);
  const { windowSize, screenSize } = useWindowSize();
  const { skeletonPageSize, productsPageSize } = useProductsPageSize(false, screenSize, windowSize);
  const { getFeature } = useFeatureEligibility();
  const [breadcrumbInfo, setBreadcrumbInfo] = useState<BreadcrumbItem[] | null>(null);

  const [isEmptyNav, setIsEmptyNav] = useState(layout !== LAYOUTS.LEFT_NAV);
  const isLeftLayoutEnabled = isFeatureEnabled(DYNAMIC_FEATURES.LEFT_NAV);
  const isTopNavDropdownEnabled = isFeatureEnabled(DYNAMIC_FEATURES.TOP_NAV_DROPDOWN);
  const isStandingOrderEnabled =
    !!getFeature?.(FEATURE.STANDING_ORDERS) && !!getFeature?.(FEATURE.COS);

  const value = useMemo(
    () => ({
      headerContentRef,
      windowSize,
      screenSize,
      skeletonPageSize,
      productsPageSize,
      getFeature,
      isLeftLayoutEnabled,
      breadcrumbInfo,
      setBreadcrumbInfo,
      isStandingOrderEnabled,
      isTopNavDropdownEnabled,
      isEmptyNav,
      setIsEmptyNav,
      ...screenSize,
    }),
    [
      headerContentRef,
      windowSize,
      screenSize,
      skeletonPageSize,
      productsPageSize,
      isStandingOrderEnabled,
      isTopNavDropdownEnabled,
      getFeature,
      isLeftLayoutEnabled,
      breadcrumbInfo,
      setBreadcrumbInfo,
      isEmptyNav,
      setIsEmptyNav,
    ],
  );

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};
