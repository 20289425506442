import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import dynamic from 'next/dynamic';
import type { ProductConfigurationModalProps } from '@components/ProductConfigurationModal/ProductConfigurationModal';
import { searchAutocompleteVar } from '@graphql/variables/searchAutocompleteVar';
import { ShoppingListProductInfo } from '@modules/modals/types/addToShoppingListModal/addToShoppingListModal';
import { useProductListChannel } from '@modules/ga/context/channel';
import { ProductListTitleProvider } from '@modules/ga/context/title';
import { ProductListLocationProvider } from '@modules/ga/context/location';
import { ConfOptionInput } from '@commons/order';
import { SetProductsPositionsProps, useProductListPosition } from '@modules/ga/context/position';
import { ProductBasicData } from '@api';

const DynamicProductConfigurationModal = dynamic(
  () => import('@components/ProductConfigurationModal/ProductConfigurationModal'),
  {
    ssr: false,
  },
);

type ProductConfigurationModalContextProps = Omit<ProductConfigurationModalProps, 'onClose'> & {
  hasSuggestion?: boolean;
  position?: number;
  channel?: string;
  onAddToShoppingList?: (params: ShoppingListProductInfo) => void;
  location?: string;
  title?: string;
  onClose?: (conf?: ConfOptionInput[], quantity?: number) => void;
  skipAddToCart?: boolean;
  isReorderItem?: boolean;
};

const ProductListContext = createContext<
  | {
      productConfigurationProps: ProductConfigurationModalContextProps | null;
      openConfigurationModal: (props: ProductConfigurationModalContextProps) => void;
      getProductPosition(productId: string): number;
      setProductPositions: SetProductsPositionsProps;
      getChannel: (
        product?: { variantId?: string } | ProductBasicData,
        channelName?: string,
      ) => string;
      setChannel: Dispatch<SetStateAction<string>>;
      fireRWProductAddToCartEvent: (
        product: { variantId: string; productId: string },
        channelName?: string,
      ) => void;
      fireRWProductClickEvent: (
        product: { variantId: string; productId: string },
        channelName?: string,
      ) => void;
    }
  | undefined
>(undefined);

const ProductListProvider = ({ children }: { children: ReactNode }) => {
  const { getProductPosition, setProductPositions } = useProductListPosition();
  const { getChannel, setChannel, fireRWProductAddToCartEvent, fireRWProductClickEvent } =
    useProductListChannel();

  const [productConfigurationProps, setProductConfigurationProps] =
    useState<ProductConfigurationModalContextProps | null>(null);

  const openConfigurationModal = (props: ProductConfigurationModalContextProps) => {
    setProductConfigurationProps(props);
  };

  const value = useMemo(
    () => ({
      productConfigurationProps,
      openConfigurationModal,
      getProductPosition,
      setProductPositions,
      getChannel,
      setChannel,
      fireRWProductAddToCartEvent,
      fireRWProductClickEvent,
    }),
    [
      productConfigurationProps,
      openConfigurationModal,
      getProductPosition,
      setProductPositions,
      getChannel,
      setChannel,
      fireRWProductAddToCartEvent,
      fireRWProductClickEvent,
    ],
  );

  return (
    <ProductListContext.Provider value={value}>
      {productConfigurationProps?.product && (
        <ProductListLocationProvider location={productConfigurationProps?.location}>
          <ProductListTitleProvider title={productConfigurationProps?.title}>
            <DynamicProductConfigurationModal
              open={productConfigurationProps.open}
              product={productConfigurationProps.product}
              variant={productConfigurationProps.variant}
              initialValues={productConfigurationProps.initialValues}
              withImageTile={productConfigurationProps.withImageTile}
              chosenCartLineId={productConfigurationProps.chosenCartLineId}
              onAddToShoppingList={productConfigurationProps.onAddToShoppingList}
              onReplace={productConfigurationProps.onReplace}
              skipAddToCart={!!productConfigurationProps?.skipAddToCart}
              isReorderItem={productConfigurationProps.isReorderItem}
              onClose={(conf?: ConfOptionInput[], quantity?: number) => {
                setProductConfigurationProps(null);
                searchAutocompleteVar(productConfigurationProps.hasSuggestion);
                productConfigurationProps.onClose?.(conf, quantity);
              }}
              gaValues={{ channel: productConfigurationProps?.channel }}
            />
          </ProductListTitleProvider>
        </ProductListLocationProvider>
      )}
      {children}
    </ProductListContext.Provider>
  );
};

function useProductListContext() {
  const context = useContext(ProductListContext);
  if (context === undefined) {
    throw new Error('useProductListContext must be used within a ProductListProvider');
  }
  return context;
}

export { ProductListProvider, useProductListContext };
