/* eslint-disable @typescript-eslint/naming-convention */
import { Banner } from '@api';
import { IGtmEvent } from '../gtm-event';

export interface BannerEvent extends IGtmEvent {
  event: string;
  ad_slot: string;
  campaign: string;
  banner_id: number;
  banner_name: string;
  link?: string;
  program?: string;
}

export type BannerEventType = 'banner_click' | 'banner_view' | 'banner_dismiss';

export function getBannerEvent(type: BannerEventType, adSlot: string, banner: Banner): BannerEvent {
  return {
    event: type,
    ad_slot: adSlot,
    campaign: '' + banner.campaignName,
    banner_id: banner.id!,
    banner_name: '' + banner.name,
    ...(banner.link && { link: banner.link }),
    ...(banner.program && { program: banner.program }),
  };
}
