import { useState } from 'react';
import { useDeleteFromCart } from '@hooks/cart/useDeleteFromCart';
import { addTask } from '@modules/queue/queue';
import { CartLine, SingleStoreCartLine as SingleStoreCartLineType } from '@commons/cart';
import { useRemoveUnavailableCartLine } from '../useRemoveUnavailableCartLine';

interface UseDeleteCartLine {
  cartLineId: string;
  inModifyMode?: boolean;
  replacedCartLine?: CartLine | SingleStoreCartLineType;
  listName?: string;
}

export const useDeleteCartLine = ({
  cartLineId,
  inModifyMode,
  replacedCartLine,
  listName,
}: UseDeleteCartLine) => {
  const [deleteItem, { loading: deleteLoading }] = useDeleteFromCart(
    cartLineId,
    inModifyMode,
    replacedCartLine,
    listName,
  );
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const removeUnavailableItem = useRemoveUnavailableCartLine();

  const deleteItemHandler = async () => {
    setDeleteInProgress(true);
    try {
      await addTask(() => deleteItem());
      removeUnavailableItem(cartLineId);
    } finally {
      setDeleteInProgress(false);
    }
  };

  return {
    deleteItem: deleteItemHandler,
    deleteInProgress: deleteLoading || deleteInProgress,
  };
};
