import { radioGroupOptions } from './constants';

export interface Values {
  giftCardId: string;
  giftCardAmount: string;
  giftCardDefaultAmount?: string;
  giftCardCustomAmount?: string;
  senderName: string;
  senderEmail: string;
  recipientName: string;
  recipientEmail: string;
  recipientMessage?: string;
  giftCardType: (typeof radioGroupOptions)[number]['value'];
  giftCardTemplateId: string;
  quantity: number | string;
  isBulk: boolean;
}

export enum GIFTCARD_FIELDS {
  GIFTCARD_ID = 'giftCardId',
  GIFTCARD_AMOUNT = 'giftCardAmount',
  GIFTCARD_DEFAULT_AMOUNT = 'giftCardDefaultAmount',
  GIFTCARD_CUSTOM_AMOUNT = 'giftCardCustomAmount',
  SENDER_NAME = 'senderName',
  SENDER_EMAIL = 'senderEmail',
  RECIPIENT_NAME = 'recipientName',
  RECIPIENT_EMAIL = 'recipientEmail',
  RECIPIENT_MESSAGE = 'recipientMessage',
  GIFTCARD_TYPE = 'giftCardType',
  GIFTCARD_TEMPLATE_ID = 'giftCardTemplateId',
  GIFTCARD_QUANTITY = 'quantity',
}
