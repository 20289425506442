import { useReactiveVar } from '@apollo/client';
import { ConfOptionInput } from '@commons/order';
import { AutosuggestProduct, Product, ProductTile } from '@commons/product';
import { getAddToOrderVariables } from '@hooks/order/useAddToOrderLightV2';
import {
  AddToOrderProduct,
  useSetProductToAddToOrder,
} from '@hooks/order/useSetProductToAddToOrder';
import { AUTH_ERROR_MESSAGE } from '@constants/errorCodes';
import { useAddToCartLight, useModifiedAddToCartLight } from '@hooks/cart/useAddToCartLight';
import { setShowPendingOrdersModalVar } from '@graphql/variables/showPendingOrdersModalVar';
import { areItemsAddedToCartInCurrentSession } from '@graphql/variables/areItemsAddedToCartInCurrentSession';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { useProductListContext } from '@context/ProductListContext';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { GaValuesProps } from '@modules/ga/context/channel';
import { getAddToCartVariables, useSetProductToAddToCartVar } from './useAddToCart';
import { useLazyGetPendingOrders } from './useLazyGetPendingOrders';

type AddProduct = ProductTile | AutosuggestProduct | Product;

interface Params {
  quantity: number;
  configuration?: ConfOptionInput[];
  salesUnit?: string;
}

export interface GetAddToOrderParams {
  product: AddProduct;
  quantity: number;
  configuration: ConfOptionInput[];
  salesUnit?: string;
}

export interface GetAddToCartParams {
  product: AddProduct;
  quantity: number;
  configuration: ConfOptionInput[];
  itemListName: string;
  itemPosition: number;
  salesUnit?: string;
}

export const getAddToOrderParams = ({
  product,
  quantity,
  configuration,
  salesUnit,
}: GetAddToOrderParams): AddToOrderProduct =>
  getAddToOrderVariables({
    productId: product.productId,
    categoryId: product.categoryId,
    skuCode: product.skuCode,
    salesUnit: salesUnit || product.salesUnits[0].alternateSalesUnit || '',
    quantity,
    configuration,
    eventSource: '',
  });

const getAddToCartParams = ({
  product,
  quantity,
  configuration,
  itemListName,
  itemPosition,
  salesUnit,
}: GetAddToCartParams) =>
  getAddToCartVariables({
    productId: product.productId,
    categoryId: product.categoryId,
    skuCode: product.skuCode,
    salesUnit: salesUnit || product.salesUnits[0].alternateSalesUnit || '',
    quantity,
    configuration,
    itemListName,
    itemPosition,
  });

export const useAddToCartWithOrdersCheck = (product: AddProduct, gaValues?: GaValuesProps) => {
  const [addToCart, { loading: addToCartLoading }] = useAddToCartLight();
  const [modifiedAddToCart] = useModifiedAddToCartLight();
  const [getPendingOrders, { loading: pendingOrderLoading }] = useLazyGetPendingOrders();
  const { isKnownUser: isLoggedIn, isRecognizedUser } = useAuthContext();
  const { setProductToAddToCart } = useSetProductToAddToCartVar();
  const { setProductToAddToOrder } = useSetProductToAddToOrder();
  const { getListName } = usePageListName();
  const { getProductPosition } = useProductListContext();
  const loading = pendingOrderLoading || addToCartLoading;
  const itemsAddedToCart = useReactiveVar(areItemsAddedToCartInCurrentSession);

  const handleAddToCart = async (params: Params, callback?: () => void) => {
    const { quantity, configuration, salesUnit } = params;
    const itemListName = getListName(product, gaValues?.channel);
    const itemPosition = getProductPosition(product.productId);

    if (isRecognizedUser || !isLoggedIn || itemsAddedToCart) {
      addToCart({
        variables: {
          ...getAddToCartParams({
            product,
            quantity,
            configuration: configuration ?? [],
            itemPosition,
            itemListName,
            salesUnit,
          }),
        },
      });
    } else {
      setProductToAddToOrder({
        ...getAddToOrderParams({
          product,
          quantity,
          configuration: configuration ?? [],
          salesUnit,
        }),
      });
      setProductToAddToCart({
        ...getAddToCartParams({
          product,
          quantity,
          configuration: configuration ?? [],
          itemPosition,
          itemListName,
          salesUnit,
        }),
      });
      await getPendingOrders({
        onCompleted: (response) => {
          const shouldShowModal =
            response?.pendingOrdersInfo?.showPendingOrders &&
            !!response?.pendingOrdersInfo?.pendingOrders?.length;
          shouldShowModal ? setShowPendingOrdersModalVar(shouldShowModal) : modifiedAddToCart();
        },
        onError: (error) => {
          if (error.message === AUTH_ERROR_MESSAGE) {
            addToCart({
              variables: {
                ...getAddToCartParams({
                  product,
                  quantity,
                  configuration: configuration ?? [],
                  itemPosition,
                  itemListName,
                  salesUnit,
                }),
              },
            });
          }
        },
      });
    }

    if (callback) callback();
  };

  return [handleAddToCart, { loading }] as const;
};
