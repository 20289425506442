import { useRef, useState, useEffect } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { Product, ProductTile, SelectedConfiguration } from '@commons/product';
import { MODAL_TYPE } from '@components/ProductConfigurationModal/types';
import { useDeleteFromCart } from '@hooks/cart/useDeleteFromCart';
import { useSetProductQuantityInLineItem } from '@hooks/cart/useSetProductQuantityInLineItem';
import { InitialValues, useProductInfoFromCart } from '@hooks/cart/useProductInfoFromCart';
import { getAddToCartVariables, useAddToCart } from '@hooks/cart/useAddToCart';
import { AddToCartProps } from '@commons/addToCartProps';
import { useAddToCartWithOrdersCheck } from '@hooks/cart/useAddToCartWithOrdersCheck';
import { ConfOptionInput } from '@commons/order';
import { ShoppingListProductInfo } from '@modules/modals/types/addToShoppingListModal/addToShoppingListModal';
import { isMealBundle } from '@utils/productHelper';
import { useLazyOptionalAdditions } from '@hooks/cart/useOptionalAdditions';
import { sendProductOptionalAdditions } from '@features/ProductPage/components/OptionalAdditionsHandler/optionalProductsHelper';
import { useLazyGetLightCart } from '@hooks/cart/useLazyGetLightCart';
import { useProductListTitle } from '@modules/ga/context/title';
import { useProductListLocation } from '@modules/ga/context/location';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { productAdapter } from '@adapters/productAdapter';
import { useProductListContext } from '@context/ProductListContext';
import { DATA_QA } from '@constants/dataQA';
import { addCustomOrderBtn } from '@graphql/variables/addCustomOrderBtn';
import { GaValuesProps } from '@modules/ga/context/channel';
import {
  INFO_TYPE,
  ProductConfigurationModalContent,
} from './components/ProductConfigurationModalContent/ProductConfigurationModalContent';
import { useConfigurableProductById } from './hooks/useConfigurableProductById';
import { MiniProductPageModal } from './components/MiniProductPageModal/MiniProductPageModal';
import { hasCartLineChanged } from './utils/hasCartLineChanged';
import styles from './ProductConfigurationModal.module.scss';

const { getProduct } = productAdapter();

export interface ProductConfigurationModalProps {
  children?: never;
  open: boolean;
  product: Product | ProductTile;
  initialValues?: InitialValues;
  onClose: (conf?: ConfOptionInput[], quantity?: number) => void;
  onAddToShoppingList?: (params: ShoppingListProductInfo) => void;
  variant: MODAL_TYPE;
  withImageTile?: boolean;
  chosenCartLineId?: string;
  onReplace?: (configs: SelectedConfiguration[], replacedQnty?: number) => void;
  isReplaced?: boolean;
  inModifyMode?: boolean;
  isDisabledControls?: boolean;
  skipAddToCart?: boolean;
  isReorderItem?: boolean;
  isUnavailableCartline?: boolean;
  gaValues?: GaValuesProps;
}

// TODO: After rework that component should be removed, currently without handle that error - broken flow in replace unavailable items
const CART_LINE_NOT_FOUND_ERROR = 'cart.line.not.found';

export const ProductConfigurationModal = ({
  open,
  onClose,
  onAddToShoppingList,
  product,
  initialValues,
  variant,
  withImageTile,
  chosenCartLineId,
  onReplace,
  inModifyMode,
  isDisabledControls,
  skipAddToCart,
  isReorderItem,
  isUnavailableCartline,
  gaValues,
}: ProductConfigurationModalProps) => {
  const [loading, setLoading] = useState(true);
  const { getProductPosition, fireRWProductAddToCartEvent, getChannel } = useProductListContext();
  const [fetchLightCart, { loading: loadingLightCart }] = useLazyGetLightCart();
  const [shownInfoType, setShownInfoType] = useState<INFO_TYPE | undefined>();
  const { data: productCartInfo } = useProductInfoFromCart(
    product.productId,
    product.soldBySalesUnit,
  );
  const configurableProductByIdQuery = useConfigurableProductById(variant, product);

  useEffect(() => {
    if (!configurableProductByIdQuery.error && configurableProductByIdQuery.data?.product) {
      setLoading(false);
    }
  }, [configurableProductByIdQuery]);

  const getModalContent = () => {
    if (variant === MODAL_TYPE.CART_MINI_PDP) {
      const productInfo = {
        ...getProduct({ ...configurableProductByIdQuery.data?.product }),
      };
      return (
        <MiniProductPageModal
          cartLineId={productCartInfo.cartLineId}
          count={productCartInfo.count}
          productInfo={productInfo}
          variant={variant}
          shouldShowInfo={!!shownInfoType}
          initialValues={initialValues}
          withImageTile={withImageTile}
          loading={fetchingData || loading}
          onOrder={finishConfigurationHandler}
          onShowInfo={handleShowInfo}
          onAddToShoppingList={onAddToShoppingList}
          onReplace={onReplace}
          onClose={onClose}
          isDisabledControls={isDisabledControls}
          labelId={MODAL_TITLE_ID.PRODUCT_CONFIGURATION}
          ref={configurationContainerRef}
          isUnavailableCartline={isUnavailableCartline}
        />
      );
    } else
      return (
        <ProductConfigurationModalContent
          product={product}
          variant={variant}
          shouldShowInfo={!!shownInfoType}
          initialValues={initialValues}
          withImageTile={withImageTile}
          loading={fetchingData}
          onOrder={finishConfigurationHandler}
          onShowInfo={handleShowInfo}
          onAddToShoppingList={onAddToShoppingList}
          onReplace={onReplace}
          onClose={onClose}
          isDisabledControls={isDisabledControls}
          labelId={MODAL_TITLE_ID.PRODUCT_CONFIGURATION}
          ref={configurationContainerRef}
          isReorderItem={isReorderItem}
          gaValues={gaValues}
        />
      );
  };

  const shouldProductBeReAddedToCard = (cartLineId: string) =>
    cartLineId &&
    (variant === MODAL_TYPE.CART_EDIT ||
      (variant === MODAL_TYPE.CART_MINI_PDP && product.soldBySalesUnit));
  const [addToCart, { loading: loadingAddToCart }] = useAddToCart({
    previousQuantity: initialValues?.count,
  });
  const { fetchOptionalAdditions } = useLazyOptionalAdditions();
  const [addToCartWithOrdersCheck, { loading: loadindAddToCartWithOrdersCheck }] =
    useAddToCartWithOrdersCheck(product as ProductTile, gaValues);
  const [deleteFromCart, { loading: loadingDeleteFromCart }] = useDeleteFromCart();
  const [setProductQuantityInLineItem] = useSetProductQuantityInLineItem();
  const { getLocation } = useProductListLocation();
  const { getTitle } = useProductListTitle();
  const { getListName } = usePageListName();
  const fetchingData =
    loadingLightCart ||
    loadingAddToCart ||
    loadingDeleteFromCart ||
    loadindAddToCartWithOrdersCheck;
  const configurationContainerRef = useRef<HTMLDivElement>(null);

  useUpdateEffect(() => {
    if (!loadindAddToCartWithOrdersCheck) {
      addCustomOrderBtn(product.productName);
      onClose?.();
    }
  }, [loadindAddToCartWithOrdersCheck]);

  const getOptionalAdditions = async () => {
    const optionalAdditions = await fetchOptionalAdditions(product.productId);
    if (optionalAdditions && optionalAdditions.items?.length) {
      sendProductOptionalAdditions({
        product: optionalAdditions,
        channel: getChannel(product, gaValues?.channel),
        location: getLocation(product),
        title: getTitle(product),
      });
    }
    onClose();
  };

  const shouldProductQuantityBeChangedInCard = (cartLineId: string) =>
    cartLineId && !product.soldBySalesUnit && variant !== MODAL_TYPE.PRODUCT;

  const finishConfigurationHandler = async ({
    product: { productId, categoryId, skuCode, salesUnits },
    quantity,
    salesUnit,
    configuration = [],
  }: AddToCartProps) => {
    const itemListName = getListName(product, gaValues?.channel);
    const itemPosition = getProductPosition(product.productId);
    const cartLineId = chosenCartLineId || productCartInfo.cartLineId;
    const addToCartParams = getAddToCartVariables({
      quantity,
      salesUnit: salesUnit || salesUnits[0].alternateSalesUnit,
      configuration,
      productId,
      categoryId,
      skuCode,
      itemPosition,
      itemListName,
    });

    if (!hasCartLineChanged({ quantity, salesUnit, configuration, initialValues })) {
      onClose();
      return;
    }

    if (shouldProductBeReAddedToCard(cartLineId)) {
      await deleteFromCart({
        variables: {
          cartLineId,
          inModifyMode: !!inModifyMode,
        },
        onError: (error) => {
          if (error.message === CART_LINE_NOT_FOUND_ERROR) {
            return;
          }
        },
      });
      await addToCart({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        variables: {
          ...addToCartParams,
        },
      });
      await fetchLightCart({ fetchPolicy: 'network-only' });
      onClose();
    } else if (shouldProductQuantityBeChangedInCard(cartLineId)) {
      setProductQuantityInLineItem({
        variables: {
          lineItemId: cartLineId,
          quantity: quantity,
          couponId: product.coupon.couponId,
          inModifyMode: !!inModifyMode,
        },
        onCompleted: (data) => onClose(data),
      });
    } else {
      if (skipAddToCart) {
        onClose?.(configuration, quantity);
        return;
      }

      const callback = () => {
        if (isMealBundle(product)) {
          return getOptionalAdditions();
        }
      };
      addToCartWithOrdersCheck(
        {
          quantity,
          configuration: configuration as ConfOptionInput[],
          salesUnit,
        },
        callback,
      );
      fireRWProductAddToCartEvent(product, gaValues?.channel);
    }
  };

  const handleClose = () => {
    setShownInfoType(undefined);
    onClose();
  };

  const handleBack = () => {
    const infoType = shownInfoType;
    setShownInfoType(undefined);
    setTimeout(() => {
      if (infoType === INFO_TYPE.MORE) {
        (
          configurationContainerRef?.current?.querySelector(
            '[data-info-button]',
          ) as HTMLButtonElement
        )?.focus();
      } else {
        configurationContainerRef?.current?.focus();
      }
    }, 100);
  };

  const handleShowInfo = (infoType: INFO_TYPE) => {
    setShownInfoType(infoType);
  };

  return (
    <ModalWindow
      onClose={handleClose}
      onBack={shownInfoType ? handleBack : undefined}
      className={
        variant === MODAL_TYPE.CART_MINI_PDP ? styles.mini_product_page : styles.configuration_modal
      }
      data-testid="product-configuration-modal"
      dataQA={DATA_QA.PRODUCT_CONFIGURATION_MODAL}
      labelId={MODAL_TITLE_ID.PRODUCT_CONFIGURATION}
      showCloseButton={shownInfoType !== INFO_TYPE.VARIATION_ITEM}
      open={open}
      isScrollable
    >
      {getModalContent()}
    </ModalWindow>
  );
};

// eslint-disable-next-line import/no-default-export
export default ProductConfigurationModal;
