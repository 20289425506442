import { AutosuggestProduct, ProductTile as IProductTile } from '@commons/product';
import { ReplaceProductProps } from '@components/OrderList/components/ReplaceModal/ReplaceModal';
import { ProductTile } from '@components/Tiles/ProductTile/ProductTile';
import { ProductTileOutOfStock } from '@components/Tiles/ProductTileOutOfStock/ProductTileOutOfStock';
import { ProductTileWithRecommended } from '@components/Tiles/ProductTileWithRecommended/ProductTileWithRecommended';
import { CriteoFormatBeacon } from '@modules/criteo';
import { GaValuesProps } from '@modules/ga/context/channel';

interface ProductTileManagerProps {
  children?: never;
  className?: string;
  product: IProductTile;
  customTileComponent?: (product: IProductTile) => JSX.Element;
  hideGroupScale?: boolean;
  isShowImage?: boolean;
  isReorder?: boolean;
  onClick?: (productId?: string) => void;
  onClickCriteo?: () => void;
  nonFocusable?: boolean;
  hideShoppingListButton?: boolean;
  freeItem?: boolean;
  productCarouselType?: string;
  criteoFormatBeaconView?: CriteoFormatBeacon;
  clippedCouponId?: string;
  onClipCouponEvent?: (couponId: string) => void;
  onChange?: (product?: IProductTile | AutosuggestProduct) => void;
  skipAddToCart?: boolean;
  isReorderItem?: boolean;
  isV2?: boolean;
  replaceProductInfo?: ReplaceProductProps;
  gaValues?: GaValuesProps;
}

export const ProductTileManager = ({
  className,
  product,
  customTileComponent,
  isShowImage = true,
  hideGroupScale = false,
  isReorder,
  onClick,
  onClickCriteo,
  nonFocusable,
  hideShoppingListButton = false,
  freeItem = false,
  productCarouselType,
  criteoFormatBeaconView,
  clippedCouponId,
  onClipCouponEvent,
  onChange,
  skipAddToCart,
  isReorderItem,
  isV2 = false,
  replaceProductInfo,
  gaValues,
}: ProductTileManagerProps) => {
  if (customTileComponent) return customTileComponent(product);

  // Takes care of sold-out products with or without alternatives
  if (product.marketingTags.soldOut) {
    return <ProductTileOutOfStock product={product} isV2={isV2} gaValues={gaValues} />;
  }

  if (product.alternativeProductId) {
    return (
      <ProductTileWithRecommended
        product={product}
        className={className}
        hideGroupScale={hideGroupScale}
        isShowImage={isShowImage}
        isReorder={isReorder}
        onClick={onClick}
        onClickCriteo={onClickCriteo}
        nonFocusable={nonFocusable}
        criteoFormatBeaconView={criteoFormatBeaconView}
        onChange={onChange}
        skipAddToCart={skipAddToCart}
        isV2={isV2}
      />
    );
  }

  return (
    <ProductTile
      product={product}
      productCarouselType={productCarouselType}
      className={className}
      hideGroupScale={hideGroupScale}
      isShowImage={isShowImage}
      isReorder={isReorder}
      onClick={onClick}
      onClickCriteo={onClickCriteo}
      nonFocusable={nonFocusable}
      hideShoppingListButton={hideShoppingListButton}
      freeItem={freeItem}
      criteoFormatBeaconView={criteoFormatBeaconView}
      clippedCouponId={clippedCouponId}
      onClipCouponEvent={onClipCouponEvent}
      onChange={onChange}
      skipAddToCart={skipAddToCart}
      isReorderItem={isReorderItem}
      isV2={isV2}
      replaceProductInfo={replaceProductInfo}
      gaValues={gaValues}
    />
  );
};

ProductTileManager.displayName = 'ProductTileManager';
