import { ProductTile } from '@commons/product';
import {
  formatListName,
  getTotalPage,
  needWaitForBreadcrumbs,
  needWaitForTotalPage,
} from '@modules/ga/events/ecommerce/view-item-list/utils/viewItemListCreator.helpers';
import { MAX_RETRY_COUNT } from '@modules/ga/events/ecommerce/view-item-list/utils/viewItemListCreator.constants';
import { ViewItemListData } from '@modules/ga/events/ecommerce/view-item-list/utils/viewItemListCreator.types';
import { SetProductsPositionsProps } from '@modules/ga/context/position';
import { getBreadcrumbs } from '@modules/ga/utils/getBreadcrumbs';

let retryCount = 0;

const productList = new Map<string, (ProductTile & { list: string })[]>();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let timer: any;

/* eslint-disable @typescript-eslint/naming-convention */

const addProduct = (data: ViewItemListData) => {
  const { list_name, product, item_list_name } = data;
  const listName = formatListName(list_name);
  if (productList.has(listName)) {
    productList.get(listName)?.push({ ...product, list: item_list_name });
    return;
  }

  productList.set(listName, [{ ...product, list: item_list_name }]);
};
/* eslint-enable @typescript-eslint/naming-convention */

const couldRetry = () => retryCount < MAX_RETRY_COUNT;

const needWait = (totalPage: string, breadcrumbs: string) => {
  const isWaitForTotalPage = needWaitForTotalPage(totalPage);
  const isWaitForBreadcrumbs = needWaitForBreadcrumbs(breadcrumbs);

  return couldRetry() && (isWaitForTotalPage || isWaitForBreadcrumbs);
};

const sendEvent = (setProductPositions: SetProductsPositionsProps) => {
  const currentPage = new URLSearchParams(window.location.search).get('page');
  const totalPage = getTotalPage();
  const breadcrumbs = getBreadcrumbs();

  if (needWait(totalPage, breadcrumbs)) {
    sendViewItemList(setProductPositions);
    return;
  }
  setProductPositions(productList, {
    currentPage: Number(currentPage || '1'),
    totalPage: Number(totalPage || '1'),
    breadcrumbs,
  });
  productList.clear();
};

const sendViewItemList = (setProductPositions: SetProductsPositionsProps) => {
  retryCount++;
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => sendEvent(setProductPositions), 200);
};

export const fireViewItemListGAEvent = (
  data: ViewItemListData,
  setProductPositions: SetProductsPositionsProps,
) => {
  retryCount = 0;
  addProduct(data);
  sendViewItemList(setProductPositions);
};
