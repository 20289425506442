import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { sendGtmEvent } from '@modules/ga/gtm-event';

export const addDeliveryAddressHandler = () => {
  sendGtmEvent(
    GtmCustomEvent({
      /* eslint-disable @typescript-eslint/naming-convention */
      event: 'add_address',
      event_name: 'address_add_click',
      ua_action: 'Delivery Address Add',
    }),
  );
};
