import { Product, ProductTile } from '@commons/product';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { useProductListContext } from '@context/ProductListContext';
import { fireSelectItemEvent } from '@modules/ga/events/ecommerce/select-item/selectItemCreator';
import { GaValuesProps } from '../context/channel';

export const useSelectItemEvent = () => {
  const { getProductPosition } = useProductListContext();
  const { getListName } = usePageListName();

  const trackSelectItem = (
    product: ProductTile | Product,
    listName?: string,
    gaValues?: GaValuesProps,
  ): void => {
    const itemPosition = getProductPosition(product.productId);
    const itemListName = listName ?? getListName(product, gaValues?.channel);
    fireSelectItemEvent({ product, itemPosition, itemListName });
  };

  return {
    trackSelectItem,
  };
};
