import { ProductTile as IProductTile } from '@commons/product';
import { ProductTileManager } from '@components/Tiles/ProductTile/ProductTileManager';
import { ProductTileSelectWrapper } from '@components/ProductTileSelectWrapper/ProductTileSelectWrapper';
import { CriteoFormatBeacon } from '@modules/criteo';
import { GaValuesProps } from '@modules/ga/context/channel';
import styles from './MainProductsTile.module.scss';

interface MainProductTileProps {
  customTileComponent?: (p: IProductTile) => JSX.Element;
  onClickCriteo?(): void;
  product: IProductTile;
  nonFocusable?: boolean;
  freeItem?: boolean;
  hideShoppingListButton?: boolean;
  criteoFormatBeaconView?: CriteoFormatBeacon;
  clippedCouponId?: string;
  onClipCouponEvent?: (couponId: string) => void;
  isReorderItem?: boolean;
  isV2?: boolean;
  gaValues?: GaValuesProps;
}

export const MainProductTile = ({
  customTileComponent,
  onClickCriteo,
  product,
  nonFocusable,
  freeItem = false,
  hideShoppingListButton = false,
  criteoFormatBeaconView,
  clippedCouponId,
  onClipCouponEvent,
  isReorderItem,
  isV2 = false,
  gaValues,
}: MainProductTileProps) => {
  return (
    <ProductTileSelectWrapper product={product}>
      <div className={styles.product}>
        <ProductTileManager
          product={product}
          customTileComponent={customTileComponent}
          onClickCriteo={onClickCriteo}
          nonFocusable={nonFocusable}
          freeItem={freeItem}
          hideShoppingListButton={hideShoppingListButton}
          criteoFormatBeaconView={criteoFormatBeaconView}
          clippedCouponId={clippedCouponId}
          onClipCouponEvent={onClipCouponEvent}
          isReorderItem={isReorderItem}
          isV2={isV2}
          gaValues={gaValues}
        />
      </div>
    </ProductTileSelectWrapper>
  );
};
