import { ReactNode } from 'react';
import {
  Product,
  ProductBasic,
  ProductBasicPrice,
  ProductQuantity,
  ProductTile,
} from '@commons/product';
import { GiftCardInfo, Maybe, ProductBasicData, PromotionType, TimeslotType } from '@api';
import { ICsrData } from '@modules/masquerade/types/csr-data';
import { IComplaintReason } from '@modules/masquerade/types/complaint-reason';
import { Price as PriceType, Price, PriceWithProperties } from './price';
import { BreadcrumbItem } from './breadcrumbs';

export const COUPON_CLIPPABLE = 'COUPON_CLIPPABLE';

export interface Subtotal {
  id: string;
  text: string;
  value: ProductBasicPrice;
  mark: string;
  deliveryPassApplied: boolean;
  deliveryPassFreeTrialEligible: boolean;
}

export enum PICKUP_DROPDOWN_OPTION_TYPE {
  CUSTOM_COMPONENT = 'CUSTOM_COMPONENT',
  DEFAULT = 'DEFAULT',
}

export type PickupDropdownOptionDefault = {
  label: ReactNode;
  value: string;
  disabled: boolean;
  type: PICKUP_DROPDOWN_OPTION_TYPE;
};

export type PickupDropdownOptionCustom = {
  customComponent: ReactNode;
  type: PICKUP_DROPDOWN_OPTION_TYPE;
};

export type PickupDropdownOption = PickupDropdownOptionDefault | PickupDropdownOptionCustom;

export interface ProductCounter {
  count: number;
  productId: string;
}

export interface AtpLine {
  availableQuantity: number | null;
  cartLineId: string;
  description: string | null;
  recommendedProducts: Product[];
}

export interface AtpError {
  deliveryDate: string | null;
  deliveryTimeSlot: string | null;
  nonReplaceableLines: AtpLine[];
  notMetMinAmount: number | null;
  replaceableLines: AtpLine[];
}

interface ErrorField {
  name: string;
  value: string;
}

export interface ValidationError {
  error: string | null;
  name: string | null;
  field: ErrorField;
}

export interface ValidationResult {
  atpErrors: AtpError;
  errors: ValidationError[];
  success: boolean;
}

export interface Promotion {
  promotionCode: string;
  promotionType: PromotionType | null;
  offerType: OfferType;
  description: string;
  terms: string;
  name: string;
}

export interface PromotionCode {
  promotionApplied: boolean;
  promotionCode: string;
  promotionInfo: Promotion;
}

export interface StateBottleDeposit {
  text: string | null;
  value: string | null;
}

export interface Tips {
  tipAmounts: string[];
  tipApplied: string;
}

export interface Variant {
  characteristicName: string;
  characteristicValue: string;
}

export interface CouponContext {
  description: string | null;
  name: string | null;
}

export interface CouponProductInfo {
  catId: string | null;
  productId: string | null;
  skuCode: string | null;
  upc: string | null;
}

export interface CouponDisplayStatus {
  description: string | null;
  name: string | null;
}

export interface OfferType {
  description: string | null;
  name: string | null;
}

export interface CouponStatus {
  description: string | null;
  displayMessage: boolean;
  name: string | null;
}

export interface Coupon {
  context: CouponContext;
  couponId: string;
  couponProductInfo: CouponProductInfo;
  detailedDescription: string | null;
  displayDescription: string | null;
  displayStatus: CouponDisplayStatus;
  displayStatusMessage: boolean;
  expirationDate: string;
  offerType: OfferType;
  quantity: string | null;
  status: CouponStatus;
  value: string | null;
  isActive: boolean;
  hasError: boolean;
  version: number | null;
}

export interface LightCoupon {
  context: CouponContext;
  couponId: string;
  couponProductInfo: CouponProductInfo;
  detailedDescription: string | null;
  displayDescription: string | null;
  displayStatus: CouponDisplayStatus;
  displayStatusMessage: boolean;
  expirationDate: string;
  offerType: OfferType;
  quantity: string | null;
  status: CouponStatus;
  value: string | null;
  version: number | null;
}

export interface MinimumCartLine {
  id: string;
  productId: string;
  quantitySelector: {
    quantity: number;
  };
  confOptions: Variant[];
  selectedSalesUnit: string;
}

export interface CartLine {
  categoryId: string | null;
  confOptions: Variant[];
  configurationDescription: string | null;
  coupon: Coupon;
  discount: Discount;
  priceIndicators: PriceIndicators;
  promotionCode: string;
  promotionApplied: boolean;
  promotionDescription: string;
  displayPrice: Price;
  unscaledPrice: Price;
  freeSample: boolean;
  id: string;
  product: Product;
  productCouponClipped: boolean;
  quantitySelector: ProductQuantity;
  selectedSalesUnit: string | null;
  unitPrice: Price;
  atpLine?: AtpLine;
  advanceOrderAvailable: boolean;
  modified: boolean;
  freeItem: boolean;
  complaintReason: IComplaintReason | null;
  itemPosition: number;
  itemListName: string;
  discountPerUnit: number;
  departmentLabel: string;
}

export interface SingleStoreCartLine {
  advanceOrderAvailable: boolean;
  categoryId: string;
  confOptions: Variant[];
  coupon: Coupon;
  description: string;
  descriptionExtended: string;
  discount: Discount;
  displayPrice: PriceType;
  expressEligible: boolean;
  hasRecommendedReplacement: boolean;
  id: string;
  priceIndicators: PriceIndicators;
  product: Product | null;
  productCouponClipped?: boolean;
  productId: string;
  productImage: string;
  productPageUrl: string;
  promotionApplied: boolean;
  promotionDescription: string;
  promotionCode: string;
  quantitySelector: ProductQuantity;
  recommendedProducts: ProductBasic[];
  unitPrice: PriceType;
  unscaledPrice: PriceType;
  breadcrumbs: BreadcrumbItem[];
  itemListName: string;
  itemPosition: number;
  discountPerUnit: number;
}

export interface CartLineLight {
  id: string;
  expressEligible: boolean;
  isRecent: boolean;
  isNewItem: boolean;
  skuCode: string;
  productId: string;
  categoryId: string;
  quantitySelector: ProductQuantity;
  coupon: LightCoupon;
  selectedSalesUnit: string;
  discount: Discount;
  itemPosition: number;
  itemListName: string;
  displayPrice: Price;
  discountPerUnit: number;
}

export interface CartGaLineLight {
  brandName?: string;
  productName?: string;
  quantity?: ProductQuantity;
  variantId?: string;
  available?: boolean;
  price: Price;
  discountPerUnit: number;
  typename: 'CartGaLineLight';
}

export interface CartLineLightWithGA extends CartLineLight, CartGaLineLight {}

export interface CartLineInfo {
  description: string;
  id: string;
  quantity: number | null;
  price: Price;
  product: ProductBasic;
  couponId: string | null;
  giftCard?: Maybe<GiftCardInfo>;
  selectedSalesUnit: string | null;
  departmentLabel: string;
  substituted: boolean;
  substitutedProduct: ProductBasicData | null;
}

export interface CartSection {
  name: string;
  cartLines: CartLine[];
  sectionImage: string;
  sectionInfo: {
    sectionTitle: string;
    wine: boolean;
    subTotal: Price;
    modified: boolean;
  };
}

export interface Discount {
  description: string;
  discountPrice: Price;
  skuLimit: number;
}

export interface PriceIndicators {
  hasDepositValue: boolean;
  hasEstimatedPrice: boolean;
  hasScaledPricing: boolean;
  hasTax: boolean;
}

export interface TotalSection {
  credits: Subtotal;
  deliveryFee: Subtotal;
  fuelSurcharge: Subtotal;
  giftCardBalance: Subtotal;
  stateBottleDeposit: Subtotal;
  subTotal: Subtotal;
  promotion: Subtotal;
  automaticHeaderDiscount: Subtotal;
  tip: Subtotal;
  totalAvalaraTax: Subtotal;
  totalTax: Subtotal;
  premiumFee: Subtotal;
}

export interface NonExpressSection {
  subTotal: ProductBasicPrice;
  cartLines: SingleStoreCartLine[];
}
export interface ExpressOnlySection {
  subTotal: ProductBasicPrice;
  cartLines: SingleStoreCartLine[];
}

export interface BillingReferenceInfo {
  billingReference: string;
  isCorporateUser: boolean;
}

export interface Cart {
  cartLinesCount: number;
  cartSections: CartSection[];
  cartLinesList: CartLine[];
  estimatedTotal: Subtotal;
  productCounter: ProductCounter[];
  promotionCode: PromotionCode;
  cartTotalPriceIndicators: PriceIndicators[];
  cartLinePromotionApplied: boolean;
  tips: Tips;
  totalSection: TotalSection;
  validationResult: ValidationResult;
  totalItems: number;
  hasCouponError: boolean;
  containsWineSection: boolean;
  minOrder: Price;
  totalWithoutDiscount: Price;
  alcoholRestricted: boolean;
  deliveryPassCartOnly: boolean;
  nonExpressSection?: NonExpressSection;
  nonExpressCartTotalPriceIndicators: PriceIndicators;
  timeslotTypeSuggestion: TimeslotType;
  csrData: ICsrData;
  changeQuantityEventId: string;
  atcEventIds: string[];
  billingReferenceInfo: BillingReferenceInfo;
  sampleProducts: ProductTile[];
  expressOnlySection?: ExpressOnlySection;
  nonExpressUniqueCartLinesCount?: number;
  nonExpressCartLinesThreshold?: number;
}

export interface CartLight {
  cartLinesCount: number;
  nonExpressProductCount: number;
  modifyOrder: boolean;
  cartLines: CartLineLightWithGA[];
  subTotal: PriceWithProperties;
  productCounter: ProductCounter[];
  changeQuantityEventId: string;
  atcEventIds: string[];
}
