import cx from 'classnames';
import { useRouter } from 'next/router';
import { MainLayoutProps } from '@layouts/types';
import { routing } from '@constants/routing';
import { useAPC } from '@hooks/promo/useAPC';
import { ExpressNotificationAlert } from '@components/ExpressNotificationAlert/ExpressNotificationAlert';
import { HelloBar } from '@components/HelloBar/HelloBar';
import { MainLayoutProvider } from '@context/MainLayoutContext';
import { GlobalAlertContainer } from '@components/GlobalAlertContainer/GlobalAlertContainer';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { CartForCheckoutContextProvider } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { LeftNav } from '@components/LeftNav/LeftNav';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import styles from './LeftNavLayout.module.scss';

export const LeftNavLayout = ({
  children,
  isStickyHeader,
  wrapperClass,
  isContainer = true,
  hideFooter,
}: MainLayoutProps) => {
  const router = useRouter();
  const { isKnownUser: isLoggedIn } = useAuthContext();
  const { isSmallScreen } = useHeaderContext();
  const showFooter = !hideFooter && router.pathname !== routing.serverError;
  const showHeaderAndAlerts = router.pathname !== routing.serverError;
  const classes = cx(styles.wrapper, wrapperClass, {
    [styles.container]: isContainer,
    [styles.is_small_screen]: isSmallScreen,
  });

  useAPC();

  return (
    <CartForCheckoutContextProvider>
      <MainLayoutProvider>
        {showHeaderAndAlerts && (
          <>
            <HelloBar />
            {isLoggedIn && <GlobalAlertContainer showAlerts />}
            <Header isStickyHeader={isStickyHeader} />
          </>
        )}
        <div className={classes} id="side-nav-layout" tabIndex={-1} role="main">
          {!isSmallScreen && <LeftNav />}
          <div className={styles.content}>{children}</div>
        </div>
        <ExpressNotificationAlert />
        {showFooter && <Footer />}
      </MainLayoutProvider>
    </CartForCheckoutContextProvider>
  );
};
