import {
  getAddToCartParams,
  getAddToOrderParams,
  Params,
  ParamsContext,
} from '@context/AddToCartContext/AddToCartContext.utils';
import { useSetProductToAddToOrder } from '@hooks/order/useSetProductToAddToOrder';
import { useSetProductToAddToCartVar } from '@hooks/cart/useAddToCart';
import { useAddToCartWithPendingOrdersCheck } from '@context/AddToCartContext/hooks/useAddToCartWithPendingOrdersCheck';

export function usePrepareAndAddToCartWithPendingOrdersCheck() {
  const { setProductToAddToOrder } = useSetProductToAddToOrder();
  const { setProductToAddToCart } = useSetProductToAddToCartVar();
  const addToCartWithPendingOrdersCheck = useAddToCartWithPendingOrdersCheck();

  return async (params: Params & { paramsContext: ParamsContext }) => {
    const { quantity, configuration, salesUnit, product, paramsContext } = params;

    setProductToAddToOrder({
      ...getAddToOrderParams({
        product,
        quantity,
        configuration,
        salesUnit,
      }),
    });
    setProductToAddToCart({
      ...getAddToCartParams({
        product,
        quantity,
        configuration,
        itemListName: paramsContext.listName,
        itemPosition: paramsContext.itemPosition,
        salesUnit,
      }),
    });
    await addToCartWithPendingOrdersCheck(params);
  };
}
