import { PAGE_TYPE_ROUTES } from '@constants/pageType';
import { routing } from '@constants/routing';

const FEED = '/feed';
const SECTION = '/section';

export const GROUPSCALE = 'channel_rec_groupscale';

export const shouldPassTitle = (value: string) =>
  value === routing.home ||
  value.startsWith(FEED) ||
  value.startsWith(SECTION) ||
  PAGE_TYPE_ROUTES.SUPERDEPARTMENT_LIST.some((path) => value.startsWith(path)) ||
  PAGE_TYPE_ROUTES.DEPARTMENT_LIST.some((path) => value.startsWith(path)) ||
  PAGE_TYPE_ROUTES.CATEGORY_LIST.some((path) => value.startsWith(path));
export const getCurrentTitle = (pathname: string, title: string) =>
  shouldPassTitle(pathname) ? title : '';
