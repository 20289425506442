import cx from 'classnames';
import { BaseLink as Link } from '@components/UI';
import { TileTagList } from '@components/Tiles/components/TiletagList/TileTagList';
import { fdWineAndSpiritsTwoColumn as WineAndSpiritsLogo } from '@assets/icons/logos';
import { useLocalization } from '@hooks/useLocalization';
import { useSelectProductContext } from '@context/SelectProductContext/SelectProductContext';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { getProductTileSrcSet } from '@utils/imageUtils';
import { GaValuesProps } from '@modules/ga/context/channel';
import styles from './TileImage.module.scss';

export interface TileImageProps {
  children?: never;
  img: {
    ref: string;
    alt: string;
  };
  productUrl: string;
  isDisabled?: boolean;
  hasWineData?: boolean;
  onProductClick?: (listName: string) => void;
  expressEligible: boolean;
  peakQuality: boolean;
  isV2?: boolean;
  gaValues?: GaValuesProps;
}

export const TileImage = ({
  img,
  productUrl,
  isDisabled,
  hasWineData,
  onProductClick,
  expressEligible,
  peakQuality,
  isV2 = false,
  gaValues,
}: TileImageProps) => {
  const { t } = useLocalization('productTile');
  const { isSelectMode } = useSelectProductContext();
  const { getListName } = usePageListName();

  return (
    <>
      {!isV2 && hasWineData && (
        <div
          className={cx(styles.wine_logo, {
            [styles.wine_logo_select_mode]: isSelectMode,
          })}
          aria-label={t('ariaLabels.wineLogo')}
        >
          <WineAndSpiritsLogo width={58} height={21} />
        </div>
      )}
      <Link legacyBehavior href={productUrl}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          data-testid="tile-image"
          className={cx(
            styles.tile_image_container,
            { [styles.disabled]: isDisabled },
            { [styles.v2]: isV2 },
          )}
          onClick={() => onProductClick?.(getListName(undefined, gaValues?.channel))}
        >
          <img
            className={cx(styles.tile_image, { [styles.v2]: isV2 })}
            srcSet={getProductTileSrcSet(img.ref)}
            src={img.ref}
            alt=""
            fetchPriority="high"
          />
          {isV2 && (
            <TileTagList
              expressEligible={expressEligible}
              peakQuality={peakQuality}
              hasWineData={hasWineData}
              isSelectMode={isSelectMode}
              isV2={isV2}
            />
          )}
        </div>
      </Link>
    </>
  );
};
