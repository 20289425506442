import { sendGtmEvent } from '@modules/ga/gtm-event';
import { AnalyticsData } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { getCustomerData } from '@modules/ga/eventBodyGetters/getCustomerData';
import { getSetEmailEvent } from './utils/getSetEmailEvent';

interface PurchaseEventProps {
  commonAnalyticsData: AnalyticsData;
  newEmail?: string;
}

export const updateEmailHandler = (data: PurchaseEventProps) => {
  const { commonAnalyticsData, newEmail } = data;

  sendGtmEvent(
    getSetEmailEvent({
      customer: getCustomerData({
        ...commonAnalyticsData,
        minimumAccountPreferences: {
          ...commonAnalyticsData.minimumAccountPreferences,
          email: newEmail || commonAnalyticsData.minimumAccountPreferences.email,
        },
      }),
    }),
  );
};
