import { sendGtmEvent } from '@modules/ga/gtm-event';
import { CheckoutWithoutUnavailableItemsEvent } from '@modules/ga/eventBodyGetters/utils/checkout-without-unavailable-items';
import { getCustomerData } from '@modules/ga/eventBodyGetters/getCustomerData';
import { getShoppingMode } from '@modules/ga/utils/getShoppingMode';
import { getExpressVariant } from '@modules/ga/utils/getExpressVariant';
import { CartLine } from '@commons/cart';
import { OUT_OF_STOCK, UNAVAILABLE } from '@constants/cart';

export const checkoutWithoutUnavailableItemsEventHandler = ({
  cart,
  commonAnalyticsData,
  selectedTimeslot,
  unavailableItems,
}: CheckoutWithoutUnavailableItemsEvent) => {
  const getQuantityObject = (arr: CartLine[]) =>
    arr.reduce(
      (
        acc: {
          quantity?: number;
          productId: string;
          name: string;
          price: number;
          unitPrice: string;
        }[],
        cartLine,
      ) => [
        ...acc,
        {
          quantity: cart.productCounter.find(
            ({ productId }) => productId === cartLine.product.productId,
          )?.count,
          productId: cartLine.product.productId,
          name: cartLine.product.productName,
          price: cartLine.product.price.value,
          unitPrice: cartLine.product.unitPrice,
        },
      ],
      [],
    );

  const unavailableItemLists = [
    ...cart?.cartSections
      ?.filter((section) => section?.name === UNAVAILABLE || section?.name === OUT_OF_STOCK)
      .flatMap((section) => section?.cartLines),
    ...(cart?.expressOnlySection?.cartLines || []),
    ...(cart?.nonExpressSection?.cartLines || []),
  ];

  sendGtmEvent({
    /* eslint-disable @typescript-eslint/naming-convention */
    event: 'checkout_without_unavailable_items',
    event_name: 'checkout_without_unavailable_items',
    customer: getCustomerData({
      userDetails: commonAnalyticsData.userDetails,
      minimumAccountPreferences: commonAnalyticsData.minimumAccountPreferences,
      userStatus: commonAnalyticsData.userStatus,
      address: commonAnalyticsData.addressData,
      geoData: commonAnalyticsData.geoData,
    }),
    'shopping-mode': getShoppingMode(),
    'express-variant': getExpressVariant(),
    timeslot_value: selectedTimeslot,
    unavailable_items: unavailableItemLists,
    order_subtotal_after_replacement: {
      value: cart.totalSection.subTotal.value.value,
      value_without_discount: cart.totalWithoutDiscount.value,
    },
    order_subtotal_before_replacement: {
      value: cart.cartLinesList.reduce(
        (acc, { id, displayPrice }) =>
          unavailableItems.includes(id) ? acc + displayPrice.value : acc,
        0,
      ),
      value_without_discount: cart.cartLinesList.reduce(
        (acc, { id, unscaledPrice }) =>
          unavailableItems.includes(id) ? acc + unscaledPrice.value : acc,
        0,
      ),
    },
    remaining_quantity: getQuantityObject(
      cart.cartLinesList.filter(({ id }) => !unavailableItems.includes(id)),
    ),
    lost_quantity: getQuantityObject(
      cart.cartLinesList.filter(({ id }) => unavailableItems.includes(id)),
    ),
  });
};
