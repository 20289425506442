
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '../styles/globals.scss';
import 'swiper/scss';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { useRef, useState } from 'react';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { CookieValueTypes } from 'cookies-next';
import { MasqueradeQueryParams } from '@modules/masquerade-query-params';
import { getApolloClient } from '@graphql/client';
import { AuthModalProvider } from '@context/AuthModalContext/AuthModalProvider';
import { LAYOUTS } from '@commons/layouts';
import { WelcomeHandler } from '@layouts/Welcome/WelcomeHandler';
import { RequiredScripts } from '@components/RequiredScripts/RequiredScripts';
import { ContentPaintPriorityIncreaser } from '@components/ContentPaintPriorityIncreaser';
import { DevTools } from '@components/DevTools/DevTools';
import { ProductListProvider } from '@context/ProductListContext';
import { PageValidationErrorProvider } from '@context/pageValidationErrorContext';
import { CommonSeoMarkup } from '@components/CommonSeoMarkup/CommonSeoMarkup';
import { AdServerProvider } from '@context/AdServerContext/AdServerContext';
import { DynamicAuthModal } from '@layouts/AuthModal/DynamicAuthModal';
import { createEmotionCache } from '@utils/createEmotionCache';
import { errorsMap } from '@components/ApiErrorBoundary/ApiErrorBoundary';
import { OptionalAdditionsHandler } from '@features/ProductPage/components/OptionalAdditionsHandler/OptionalAdditionsHandler';
import { routing } from '@constants/routing';
import { ModifyOrderProvider } from '@context/ModifyOrderContext/ModifyOrderProvider';
import { ExpressProvider } from '@context/ExpressContext/ExpressProvider';
import { getDeviceType } from '@utils/getDeviceType';
import {
  DynamicFeature,
  DYNAMIC_FEATURES,
  isFeatureEnabled,
  replaceFeatures,
} from '@utils/dynamicFeatures';
import { getRegionId } from '@modules/region-id';
import { getServiceType } from '@modules/service-type';
import { getPreviewHeaders, IPreviewModeHeaders } from '@modules/preview-mode';
import { getExpressHeader } from '@modules/express-header';
import { GAHandler } from '@modules/ga/components/GAHandler';
import { ModalsManager } from '@modules/modals/components/ModalsManager';
import { HeaderContextProvider } from '@context/HeaderContext/HeaderContext';
import { AuthProvider } from '@modules/auth/context/AuthContextProvider';
import { AnalyticsDataProvider } from '@modules/ga/context/AnalyticsData/AnalyticsDataProvider';
import { SeoTagsContainer } from '@components/SeoTags/SeoTagsContainer';
import { getWebTrackingValuePerHeaderKey } from '@modules/web-tracking';
import { CLIENT_DEVICE_TYPE } from '@modules/pageSize/pageSize.constants';
import { AccountLayout } from '@layouts/AccoutLayout/AccountLayout';
import { PreviewBox } from '@components/PreviewBox/PreviewBox';
import { DynamicAppRedirectionModal } from '@layouts/AppRedirectionModal/DynamicAppRedirectionModal';
import { LeftNavLayout } from '@layouts/LeftNavLayout/LeftNavLayout';
import { GenesysChatService } from '@utils/genesysChatService';
import { StringBannerListPair } from '@api';
import { ScrollingContextProvider } from '@context/ScrollingContext/ScrollingContext';
import { useLocalization } from '@hooks/useLocalization';
import {
  useRouteEffects,
  useClipboardEffects,
  useMasqueradeEffects,
  useGenesysChatEffects,
  useAPIErrorEffects,
  useDeviceEffects,
} from '@hooks/app';
import { GRAPHQL_ERRORS } from '@commons/graphqlErrors';
import { NoResultsTemplate } from '@components/NoResultsTemplate/NoResultsTemplate';
import { updateBanners, setBannerSequenceNumber } from '@utils/bannerCache';
import { defaultTheme } from '../theme/defaultTheme';
import { CartLayout, MainLayout } from '../layouts';

const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  pageProps: {
    layout: string;
    referer?: string;
    meta?: {
      title: string;
      description: string;
    };
    masqueradeModeData: MasqueradeQueryParams;
    apolloState?: NormalizedCacheObject;
    bannerCache?: Array<StringBannerListPair>;
    bannerSqNr: number;
    dynamicFeatures?: Array<DynamicFeature>;
  };
  webTrackingValuePerHeaderKey: Record<string, string>;
  isExpress: CookieValueTypes;
  regionId?: string;
  serviceType?: string;
  previewModeHeaders?: IPreviewModeHeaders;
  clientDeviceType: CLIENT_DEVICE_TYPE;
}

function MyApp({
  Component,
  pageProps,
  router,
  emotionCache = clientSideEmotionCache,
  webTrackingValuePerHeaderKey,
  previewModeHeaders,
  clientDeviceType,
  ...rest
}: Readonly<MyAppProps>) {
  const { t } = useLocalization('errors');
  const { layout, meta, masqueradeModeData } = pageProps;
  const [failed, setFailed] = useState(false);
  const [errorType, setErrorType] = useState(GRAPHQL_ERRORS.NULL);
  const masqueradeInitCalled = useRef(false);
  const isInitRoute = useRef(false);
  const isPopStateHappend = useRef(false);
  const genesysService = GenesysChatService();
  const [client] = useState<ApolloClient<NormalizedCacheObject>>(
    getApolloClient({
      forceNew: false,
      previewModeHeaders,
      valuePerHeaderKey: webTrackingValuePerHeaderKey,
      cacheData: pageProps.apolloState,
      ...rest,
    }),
  );

  useClipboardEffects();
  useDeviceEffects(clientDeviceType);
  useGenesysChatEffects(genesysService);
  useRouteEffects(router, isInitRoute, isPopStateHappend);
  useMasqueradeEffects(client, router, masqueradeInitCalled, masqueradeModeData);
  useAPIErrorEffects(errorType, setErrorType, setFailed);

  const renderLayout = () => {
    if (router.query.connectionToken) {
      return null;
    }

    const displayedComponent = <Component {...pageProps} />;

    switch (layout) {
      case LAYOUTS.CART:
        return <CartLayout {...pageProps}>{displayedComponent}</CartLayout>;
      case LAYOUTS.EMPTY:
        return displayedComponent;
      case LAYOUTS.ACCOUNT:
        return <AccountLayout {...pageProps}>{displayedComponent}</AccountLayout>;
      case LAYOUTS.LEFT_NAV:
        if (isFeatureEnabled(DYNAMIC_FEATURES.LEFT_NAV)) {
          return <LeftNavLayout {...pageProps}>{displayedComponent}</LeftNavLayout>;
        } else {
          return <MainLayout {...pageProps}>{displayedComponent}</MainLayout>;
        }
      case LAYOUTS.FULL_SCREEN:
        return (
          <MainLayout isContainer={false} {...pageProps}>
            {displayedComponent}
          </MainLayout>
        );
      default:
        return <MainLayout {...pageProps}>{displayedComponent}</MainLayout>;
    }
  };

  if (pageProps.bannerCache) {
    updateBanners(pageProps.bannerCache);
  }

  if (pageProps.bannerSqNr) {
    setBannerSequenceNumber(pageProps.bannerSqNr);
  }

  if (pageProps.dynamicFeatures) {
    replaceFeatures(pageProps.dynamicFeatures);
  }

  if (router.pathname === routing.masqueradeModeInitialization) {
    return null;
  }

  if (failed) {
    return (
      <NoResultsTemplate
        title={t(errorsMap[errorType].title)}
        subtitle={t(errorsMap[errorType].subtitle)}
        buttonText={t(errorsMap[errorType].buttonText)}
        onClick={() => {
          setFailed(false);
          router.push(routing.home);
        }}
      />
    );
  }

  return (
    <>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            key="viewport"
          />
          <link rel="icon" type="image/x-icon" href="/static/next/favicon.ico" />
        </Head>
        <CommonSeoMarkup />
        <ApolloProvider client={client}>
          <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <SeoTagsContainer
              defaultSeoData={{
                pageTitle: meta?.title ?? '',
                metaDescription: meta?.description ?? '',
              }}
            />
            <ExpressProvider>
              <AuthModalProvider>
                <AuthProvider>
                  <ScrollingContextProvider>
                    <HeaderContextProvider layout={layout}>
                      <ProductListProvider>
                        <ModifyOrderProvider>
                          <PageValidationErrorProvider>
                            <AdServerProvider>
                              <WelcomeHandler />
                              <DynamicAuthModal />
                              <OptionalAdditionsHandler />
                              <ModalsManager />
                              <AnalyticsDataProvider>
                                <GAHandler />
                              </AnalyticsDataProvider>
                              {renderLayout()}
                              <ContentPaintPriorityIncreaser />
                            </AdServerProvider>
                          </PageValidationErrorProvider>
                        </ModifyOrderProvider>
                      </ProductListProvider>
                      <DynamicAppRedirectionModal />
                    </HeaderContextProvider>
                  </ScrollingContextProvider>
                  <RequiredScripts />
                  <DevTools />
                </AuthProvider>
              </AuthModalProvider>
            </ExpressProvider>
          </ThemeProvider>
        </ApolloProvider>
      </CacheProvider>
      {previewModeHeaders && <PreviewBox headers={previewModeHeaders} />}
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const oldInitialProps = await App.getInitialProps(appContext);
  const { ctx } = appContext;
  const { res, req } = ctx;
  const userAgent = req ? req.headers['user-agent'] : navigator?.userAgent;
  const clientDeviceType = getDeviceType(userAgent);

  return {
    ...oldInitialProps,
    webTrackingValuePerHeaderKey: getWebTrackingValuePerHeaderKey(res),
    regionId: getRegionId(res),
    serviceType: getServiceType(ctx),
    previewModeHeaders: getPreviewHeaders(res),
    isExpress: getExpressHeader(ctx),
    clientDeviceType,
  };
};

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  