import cx from 'classnames';
import { Skeleton, Typography } from '@mui/material';
import { getCurrencySymbol } from '@utils/productHelper';
import { useLocalization } from '@hooks/useLocalization';
import styles from './TileQuantity.module.scss';

export interface TileQuantityProps {
  children?: never;
  className?: string;
  unitSize?: string | null;
  unitPrice?: string | null;
  currency?: string | null;
  roughPricePerUnit?: string;
  selectedScaleUnit?: string;
  defaultScaleUnit?: string;
  pricePerScaleUnit?: string;
  selectedProductsNames?: string;
  formattedCurrentPrice?: string;
  loading?: boolean;
  isV2?: boolean;
}

const MAX_UNIT_LENGTH = 9;

export const TileQuantity = ({
  className,
  unitPrice,
  unitSize,
  currency,
  roughPricePerUnit,
  selectedScaleUnit,
  pricePerScaleUnit,
  selectedProductsNames = '',
  defaultScaleUnit,
  loading,
  formattedCurrentPrice,
  isV2 = false,
}: TileQuantityProps) => {
  const { t } = useLocalization('productTile');
  const displayScaleUnit = selectedScaleUnit || defaultScaleUnit;
  const displayPricePerScaleUnit = pricePerScaleUnit || (displayScaleUnit && formattedCurrentPrice);
  const noData = !(
    unitSize ||
    roughPricePerUnit ||
    displayScaleUnit ||
    unitPrice ||
    displayPricePerScaleUnit ||
    selectedProductsNames
  );
  const hasSecondUnit = Boolean(
    roughPricePerUnit ||
      defaultScaleUnit ||
      unitPrice ||
      displayPricePerScaleUnit ||
      selectedProductsNames,
  );
  const hasLongUnitSize = unitSize ? unitSize.length > MAX_UNIT_LENGTH : false;

  if (loading) {
    return <Skeleton variant="text" />;
  }

  if (noData) {
    return null;
  }

  const renderUnitSize = () => {
    if (hasLongUnitSize && hasSecondUnit) {
      return (
        <Typography
          data-testid={t('tileQuantity.ariaLabels.unitSize')}
          data-qa="tile_unit_size"
          component="span"
          variant="smallBody"
          className={cx(styles.tile_quantity_item, styles.long_unit_size)}
        >
          {unitSize}{' '}
        </Typography>
      );
    }
    if (unitSize) {
      return (
        <Typography
          data-testid={t('tileQuantity.ariaLabels.unitSize')}
          data-qa="tile_unit_size"
          component="span"
          variant="smallBody"
          className={styles.tile_quantity_item}
        >
          {unitSize}{' '}
        </Typography>
      );
    }
  };

  return (
    <div
      className={cx(styles.tile_quantity, className, {
        [styles.v2]: isV2,
      })}
      data-testid={t('tileQuantity.ariaLabels.quantity')}
    >
      {unitPrice && isV2 && (
        <Typography
          data-testid={t('tileQuantity.ariaLabels.unitPrice')}
          data-qa="tile_unit_price"
          component="span"
          variant="smallBody"
          className={styles.tile_quantity_item}
        >
          {getCurrencySymbol(currency)}
          {unitPrice}
        </Typography>
      )}
      {renderUnitSize()}
      {roughPricePerUnit && (
        <Typography
          aria-label={t('tileQuantity.ariaLabels.pricePerUnit')}
          data-qa="tile_price_per_unit"
          component="span"
          variant="smallBody"
          className={styles.tile_quantity_item}
        >
          {roughPricePerUnit}
        </Typography>
      )}
      {displayScaleUnit && displayScaleUnit !== unitSize && (
        <Typography
          aria-label={t('tileQuantity.ariaLabels.scaleUnit')}
          data-qa="tile_scale_unit"
          component="span"
          variant="smallBody"
          className={styles.tile_quantity_item}
        >
          {displayScaleUnit}
        </Typography>
      )}
      {unitPrice && !isV2 && (
        <Typography
          data-testid={t('tileQuantity.ariaLabels.unitPrice')}
          data-qa="tile_unit_price"
          component="span"
          variant="smallBody"
          className={styles.tile_quantity_item}
        >
          {getCurrencySymbol(currency)}
          {unitPrice}
        </Typography>
      )}
      {displayPricePerScaleUnit && (
        <Typography
          aria-label={t('tileQuantity.ariaLabels.pricePerScaleUnit')}
          data-qa="tile_price_per_scale_unit"
          component="span"
          variant="smallBody"
          className={styles.tile_quantity_item}
        >
          {displayPricePerScaleUnit}
        </Typography>
      )}
      {selectedProductsNames && (
        <Typography
          aria-label={t('tileQuantity.ariaLabels.selectedProductsNames')}
          data-qa="tile_selected_products_names"
          component="span"
          variant="smallBody"
          className={styles.tile_quantity_item}
        >
          {selectedProductsNames}
        </Typography>
      )}
    </div>
  );
};
