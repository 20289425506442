import { useQuery } from '@apollo/client';
import { useRouter, NextRouter } from 'next/router';
import { Pair, Banner } from '@api';
import { routing } from '@constants/routing';
import { PAGE_TYPE_ROUTES } from '@constants/pageType';
import { sessionStorageService, STORAGE_KEYS } from '@utils/storageService';
import { GET_BANNERS, GET_BANNER_BY_ID } from '@graphql/common/queries/getBanners';
import { BANNERCACHE, updateBanners, checkBannerCache } from '@utils/bannerCache';

export { getBannerSequenceNumber } from '@utils/bannerCache';

interface UseBannersProps {
  bannerIds: Array<string>;
  targeting?: Array<Pair>;
}

interface UseBannerPreviewProps {
  id: string;
}

function getBanners(id: string): Array<Banner> {
  const dismissedBanners = sessionStorageService?.read(STORAGE_KEYS.DISMISSED_ADSLOTS) ?? [];
  if (dismissedBanners.includes(id)) {
    return [];
  }
  return BANNERCACHE.banners.get(id) ?? [];
}

// add id and pageType to targeting if they are not already present
function extendTargeting(targetingQuery: URLSearchParams, router: NextRouter) {
  if (!targetingQuery.has('id') && router.query?.id) {
    targetingQuery.append('id', router.query.id as string);
  }
  if (!targetingQuery.has('pt')) {
    let pt;
    if (router.pathname === routing.home) {
      pt = 'homepage';
    } else {
      Object.entries(PAGE_TYPE_ROUTES).forEach((pageTypeToRoutesMap) => {
        const [pageType, pageTypeRoutes] = pageTypeToRoutesMap;

        pageTypeRoutes.forEach((route) => {
          if (route !== '/' && router.pathname?.includes(route)) {
            pt = pageType.toLowerCase();
          }
        });
      });
    }
    if (pt) {
      targetingQuery.append('pt', pt);
    }
  }
}

export function dismissBanner(id: string) {
  const dismissedBanners = new Set(
    sessionStorageService?.read(STORAGE_KEYS.DISMISSED_ADSLOTS) ?? [],
  );
  dismissedBanners.add(id);
  sessionStorageService?.put(STORAGE_KEYS.DISMISSED_ADSLOTS, [...dismissedBanners]);
}

export function resetDismissedBanners() {
  sessionStorageService?.remove(STORAGE_KEYS.DISMISSED_ADSLOTS);
}

export function useBanner({ bannerIds, targeting = [] }: UseBannersProps) {
  const router = useRouter();
  const targetingQuery = new URLSearchParams();
  targeting.forEach(({ key, value }) => {
    targetingQuery.append(key, value);
  });

  extendTargeting(targetingQuery, router);

  const targetingString = targetingQuery.toString();
  const { data, refetch } = useQuery(GET_BANNERS, {
    variables: { bannerIds, targeting: targetingString },
  });

  if (checkBannerCache(router.asPath)) {
    refetch();
  }

  if (data?.bannerSlots?.banners) {
    updateBanners(data.bannerSlots.banners);
  }

  return {
    banners: BANNERCACHE.banners,
    getBanners,
    dismissBanner,
    resetDismissedBanners,
  };
}

export function useBannerPreview({ id }: UseBannerPreviewProps): Banner {
  const { data } = useQuery(GET_BANNER_BY_ID, {
    variables: { id: id },
  });

  return data?.previewBanner;
}
