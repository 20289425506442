import { Typography } from '@mui/material';
import { ProductTile as IProductTile, Product } from '@commons/product';
import { shoppingList as ShoppingListIcon } from '@assets/icons/informational/with-spacing';
import { useLocalization } from '@hooks/useLocalization';
import { KEYBOARD_CODE } from '@constants/keyboard';
import { getConfigurationOptions } from '@adapters/product/getProductTile';
import { sendBeacon } from '@hooks/criteo/useCriteoBeacon.utils';
import { useChooseProductForShoppingList } from '@hooks/shoppingLists/useChooseProductForShoppingList';
import { GaValuesProps } from '@modules/ga/context/channel';

interface AddToShoppingListButtonProps {
  product: IProductTile | Product;
  classNames: string;
  handleAddToShoppingList?: () => void;
  shouldRenderAddToShoppingListButton: boolean | undefined;
  listLabel?: string;
  nonFocusable?: boolean;
  gaValues?: GaValuesProps;
}

export const AddToShoppingListButton = ({
  product,
  classNames,
  listLabel,
  handleAddToShoppingList,
  shouldRenderAddToShoppingListButton,
  nonFocusable,
  gaValues,
}: AddToShoppingListButtonProps) => {
  const { t } = useLocalization('productTile');
  const { chooseProduct } = useChooseProductForShoppingList();

  if (!shouldRenderAddToShoppingListButton) {
    return null;
  }

  const productName = product?.brandName
    ? `${product.brandName} ${product.productName}`
    : product.productName;

  const addToShoppingList = () => {
    if (handleAddToShoppingList) {
      handleAddToShoppingList();
    } else {
      chooseProduct({
        product,
        configurationOptions: getConfigurationOptions(product.variations),
        salesUnit: product?.salesUnits.find((e) => e.selected)?.alternateSalesUnit ?? undefined,
        gaValues,
      });
      if ((product as IProductTile)?.clickBeacon) {
        sendBeacon(product.clickBeacon);
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === KEYBOARD_CODE.ENTER) {
      addToShoppingList();
    }
  };

  return (
    <div
      className={classNames}
      onClick={addToShoppingList}
      onKeyDown={handleKeyDown}
      aria-label={t('common:shoppingList.ariaLabelAddToShoppingList', { product: productName })}
      title={t('common:shoppingList.ariaLabelAddToShoppingList', { product: productName })}
      role="button"
      tabIndex={nonFocusable ? -1 : 0}
    >
      <ShoppingListIcon />
      {listLabel && <Typography component="span">{listLabel}</Typography>}
    </div>
  );
};
