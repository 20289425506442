import pinoLoggerFactory, { stdTimeFunctions } from 'pino';
import { isClient } from '@utils/isClient';
import { isDeveloper } from '@utils/isDeveloper';

export const logger = pinoLoggerFactory({
  level: process.env.LOG_LEVEL || 'info',
  timestamp: stdTimeFunctions.isoTime,
  formatters: {
    level(label: string, _: number) {
      return { level: label };
    },
  },
  transport:
    process.env.NODE_ENV === 'development' || process.env.CI == 'true'
      ? { target: 'pino-pretty' }
      : undefined,
  browser: {
    write(obj) {
      if (isClient() && !isDeveloper()) {
        return;
      }

      try {
        if (isClient()) {
          // @ts-expect-error obj
          console.log(`%c[UXE ${obj.time}]`, 'background: #298321; color: #fff', obj.msg);
        } else {
          console.log(JSON.stringify(obj));
        }
      } catch (err) {
        if (err instanceof Error) {
          // Without a `replacer` argument, stringify on Error results in `{}`
          console.log(JSON.stringify(err, ['name', 'message', 'stack']));
        }

        console.log(JSON.stringify({ message: 'Unknown error type' }));
      }
    },
  },
});
