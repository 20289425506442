import { fireEvent } from '@modules/ga/emitter';
import { GA_CUSTOM_EVENT_ACTION } from '@modules/ga/type';
import { GA_EVENT_TYPE } from '@modules/ga/constants';

export const fireNavigationClickEvent = (
  name?: string | null | undefined,
  placement?: string,
  hierarchy?: string,
) => {
  const navigationData = { name, placement, hierarchy };

  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_CUSTOM_EVENT_ACTION.NAVIGATION_CLICK,
    data: navigationData,
  });
};
