import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Product, ProductBasic } from '@commons/product';
import { useModal } from '@hooks/common';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { useRemoveFromCartEvent } from '@modules/ga/hooks/useRemoveFromCart';
import { SingleStoreCartLine as SingleStoreCartLineType } from '@commons/cart';
import { ProductListLocationProvider } from '@modules/ga/context/location';
import { ReplaceModal } from '@components/OrderList/components/ReplaceModal';
import { channels } from '@modules/ga/context/constants';

export interface HandleReplaceParams {
  id: string;
  url: string;
  products?: (Product | ProductBasic)[];
  selectedCartLine?: SingleStoreCartLineType;
  isNonExpressCartLine?: boolean;
  availableQuantity?: number | null;
}

interface ReplaceModalData {
  handleReplace: (params: HandleReplaceParams) => void;
}

interface LocalState {
  productToReplace: (Product | ProductBasic)[];
  cartLineId: string;
  urlAlternative: string;
  isNonExpress: boolean;
  availableQuantity: number | null;
  selectedCartLine?: SingleStoreCartLineType;
}

export const ReplaceModalContext = createContext<ReplaceModalData | Record<string, never>>({});

export const ReplaceModalProvider = ({ children }: { children: ReactNode }) => {
  const {
    isOpen: isReplaceModalOpen,
    closeModal: closeReplaceModal,
    openModal: openReplaceModal,
  } = useModal();
  const { trackRemoveFromCartExpress } = useRemoveFromCartEvent();
  const shouldSendReplaceCompletedEvent = useRef(false);
  const [localState, setLocalState] = useState<LocalState>({
    productToReplace: [],
    cartLineId: '',
    urlAlternative: '',
    isNonExpress: false,
    availableQuantity: null,
    selectedCartLine: undefined,
  });

  const handleReplace = ({
    id,
    url,
    products,
    selectedCartLine,
    isNonExpressCartLine,
    availableQuantity,
  }: HandleReplaceParams) => {
    setLocalState({
      productToReplace: products ?? [],
      cartLineId: id,
      urlAlternative: url,
      isNonExpress: isNonExpressCartLine ?? false,
      availableQuantity: availableQuantity ?? null,
      selectedCartLine: selectedCartLine,
    });
    openReplaceModal();

    if (!selectedCartLine) {
      shouldSendReplaceCompletedEvent.current = false;
      return;
    }

    /* eslint-disable @typescript-eslint/naming-convention */
    sendGtmEvent({
      event: 'express-action',
      event_name: 'express_replace_item_click',
      ua_category: 'express shop',
      ua_action: 'express replace item click',
      ua_label: selectedCartLine?.product?.productName ?? selectedCartLine?.description ?? '',
    });
    shouldSendReplaceCompletedEvent.current = true;
  };

  const handleReplaceCompleted = (
    selectedProductId: string,
    selectedCartLine?: SingleStoreCartLineType,
  ) => {
    if (!shouldSendReplaceCompletedEvent.current) {
      return;
    }

    const { productName } =
      localState.productToReplace.find(({ productId }) => productId === selectedProductId) || {};
    /* eslint-disable @typescript-eslint/naming-convention */
    sendGtmEvent({
      event: 'express-action',
      event_name: 'express_add_to_cart_replacement',
      ua_category: 'express shop',
      ua_action: 'express add to cart replacement',
      ua_label: productName ?? '',
    });
    if (selectedCartLine) {
      trackRemoveFromCartExpress(selectedCartLine);
    }
  };

  useEffect(() => {
    return () => {
      shouldSendReplaceCompletedEvent.current = false;
    };
  }, []);

  return (
    <ProductListLocationProvider location="checkout">
      <ReplaceModalContext.Provider value={{ handleReplace }}>
        {children}
        <ReplaceModal
          open={isReplaceModalOpen}
          onClose={closeReplaceModal}
          cartLineId={localState.cartLineId}
          selectedCartLine={localState.selectedCartLine}
          urlAlternative={localState.urlAlternative}
          productToReplace={localState.productToReplace}
          isNonExpress={localState.isNonExpress}
          onReplaceCompleted={handleReplaceCompleted}
          availableQuantity={localState.availableQuantity}
          gaValues={{
            channel: localState.isNonExpress ? channels.expressAtp : channels.regularAtp,
          }}
        />
      </ReplaceModalContext.Provider>
    </ProductListLocationProvider>
  );
};

export const useReplaceModal = () => {
  const context = useContext(ReplaceModalContext);

  if (context === undefined) {
    throw new Error('useReplaceModal must be used within a ReplaceModalProvider');
  }

  return context;
};
