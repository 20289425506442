import { createContext, Dispatch, FC, useState, SetStateAction } from 'react';
import { MutationFunctionOptions } from '@apollo/client/react/types/types';
import { cartAdapter } from '@adapters/cartAdapter';
import { Cart, CartLight } from '@commons/cart';
import {
  getProductInfoFromCartLinesLight,
  IGetProductInfoFromCartLight,
  Params,
  ParamsContext,
} from '@context/AddToCartContext/AddToCartContext.utils';
import { useLightCart } from '@hooks/cart/useLightCart';
import { useSetProductQuantityInCartLight } from '@hooks/cart/useSetProductQuantityInCartLight';
import {
  SetProductQuantityInCartLightMutation,
  SetProductQuantityInCartLightMutationVariables,
} from '@api';
import { useHandleSetProductQuantityInCart } from '@context/AddToCartContext/hooks/useHandleSetProductQuantityInCart';
import { useHandleAddToCart } from '@context/AddToCartContext/hooks/useHandleAddToCart';

const { getLightCart } = cartAdapter();

interface IAddToCartContext {
  handleAddToCart: (
    params: Params & { paramsContext: ParamsContext },
    callback?: () => void,
  ) => Promise<void>;
  getProductInfoFromCart: (
    productId: string,
    soldBySalesUnit?: boolean,
  ) => IGetProductInfoFromCartLight;
  cart: CartLight | Cart;
  setProductQuantityInCart: (
    options: MutationFunctionOptions<
      SetProductQuantityInCartLightMutation,
      SetProductQuantityInCartLightMutationVariables
    >,
  ) => Promise<void>;
  addedProductId?: string;
  setAddedProductId: Dispatch<SetStateAction<string>>;
  lastAction?: string;
  setLastAction: Dispatch<SetStateAction<string>>;
  isSettingProductQuantity: boolean;
}

export const AddToCartContext = createContext<IAddToCartContext>({
  setAddedProductId: () => undefined,
  setLastAction: () => undefined,
  handleAddToCart: async () => undefined,
  getProductInfoFromCart: (): IGetProductInfoFromCartLight => ({
    loading: false,
    data: {
      cartLineId: '',
      count: 0,
      salesUnit: undefined,
    },
  }),
  cart: getLightCart(),
  setProductQuantityInCart: async () => Promise.resolve(),
  isSettingProductQuantity: false,
});

export const AddToCartContextProvider: FC = ({ children }) => {
  // useQuery<GetLightCartQuery, GetLightCartQueryVariables>(GET_LIGHT_CART, { ssr: false });
  const [addedProductId, setAddedProductId] = useState('');
  const [lastAction, setLastAction] = useState('');
  const { data: cartData, loading: cartLoading } = useLightCart();
  const [, { loading: isSettingProductQuantity }] = useSetProductQuantityInCartLight();

  const getProductInfoFromCart = (productId: string, soldBySalesUnit = false) => {
    return {
      data: getProductInfoFromCartLinesLight(cartData.cartLines, productId, soldBySalesUnit),
      loading: cartLoading,
    };
  };

  const handleSetProductQuantityInCart = useHandleSetProductQuantityInCart();

  const handleAddToCart = useHandleAddToCart();

  return (
    <AddToCartContext.Provider
      value={{
        handleAddToCart,
        cart: cartData,
        getProductInfoFromCart,
        setProductQuantityInCart: handleSetProductQuantityInCart,
        isSettingProductQuantity,
        addedProductId,
        setAddedProductId,
        lastAction,
        setLastAction,
      }}
    >
      {children}
    </AddToCartContext.Provider>
  );
};
