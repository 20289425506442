export const channels = {
  //pages
  browse: 'browse',
  feedPage: 'rec_cms',
  freshDealsPage: 'rec_pres_picks',
  homePage: 'homepage',
  productPage: 'product_detail',
  reorderPage: 'reorder',
  searchPage: 'search',
  salePage: 'sale',

  //components
  criteo: 'rec_criteo',
  expressAtp: 'rec_express_atp',
  groupScale: 'rec_groupscale',
  regularAtp: 'rec_atp',
  reorderCarousel: 'rec_top_items_qs',
  sample: 'sample',
};
