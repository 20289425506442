import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { NavigationClickEvent } from '@modules/ga/eventBodyGetters/utils/ga-navigation-click';
import { sendGtmEvent } from '@modules/ga/gtm-event';

export const navigationClickEventHandler = ({
  name,
  placement,
  hierarchy,
}: NavigationClickEvent) => {
  sendGtmEvent(
    GtmCustomEvent({
      /* eslint-disable @typescript-eslint/naming-convention */
      event: 'navigation_click',
      event_name: 'navigation_click_selection',
      navigationData: { name, placement, hierarchy },
    }),
  );
};
