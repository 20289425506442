import cx from 'classnames';
import { PRODUCT_LABEL, ProductMarketingTags } from '@commons/product';
import { time as TimeIcon } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import styles from './TileLabels.module.scss';

interface TileLabelsProps {
  marketingTags?: ProductMarketingTags;
  preparationTime?: number;
  isV2?: boolean;
}

const getMarketingLabel = (marketingTag: ProductMarketingTags) => {
  if (marketingTag.sponsored) return PRODUCT_LABEL.SPONSORED;
  if (marketingTag.yourFave) return PRODUCT_LABEL.YOURFAVE;
  if (marketingTag.new) return PRODUCT_LABEL.NEW;
  if (marketingTag.backOnline) return PRODUCT_LABEL.BACKONLINE;
};

export const TileLabels = ({ marketingTags, preparationTime, isV2 = false }: TileLabelsProps) => {
  const { t } = useLocalization('product');
  const hasAnyTags =
    marketingTags?.sponsored ||
    marketingTags?.yourFave ||
    marketingTags?.new ||
    marketingTags?.backOnline;

  if (preparationTime) {
    return (
      <div
        data-testid="preparation time"
        className={cx(styles.tile_label, styles.tile_prep_time, styles.tile_label_container, {
          [styles.v2]: isV2,
        })}
        data-qa="tile_prep_time"
      >
        <TimeIcon className={styles.tile_label_icon} />
        <span>{t('preparationTimeTag', { count: preparationTime })}</span>
      </div>
    );
  }

  if (hasAnyTags) {
    return (
      <div
        data-testid="marketing tag"
        className={cx(styles.tile_label, styles.tile_label_container, {
          [styles.v2]: isV2,
        })}
        data-qa="tile_marketing_tag"
      >
        {getMarketingLabel(marketingTags)}
      </div>
    );
  }

  return null;
};
