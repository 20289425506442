import { Fragment, useEffect, useState } from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { Grid } from '@components/UI/Grid/Grid';
import { ProductBasic, ProductTile as IProductTile } from '@commons/product';
import { SkeletonWrapper } from '@components/Skeletons/SkeletonWrapper/SkeletonWrapper';
import { ProductTileSkeleton } from '@components/Skeletons/ProductTileSkeleton/ProductTileSkeleton';
import { getProductKey } from '@components/ProductsGrid/getProductKey';
import { ButterflyProductTile } from '@components/Tiles/ButterflyProductTile/ButterflyProductTile';
import { CriteoButterfly } from '@commons/criteoBanner';
import { getCurrentButterflyBannerIndex } from '@components/Tiles/ButterflyProductTile/getCurrentButterflyBanner';
import { MainProductTile } from '@components/Tiles/MainProductTile';
import { CriteoFormatBeacon } from '@modules/criteo';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { DYNAMIC_FEATURES, isFeatureEnabled } from '@utils/dynamicFeatures';
import { GaValuesProps } from '@modules/ga/context/channel';
import styles from './ProductsGrid.module.scss';

interface GridProps {
  customTileComponent?: (product: IProductTile) => JSX.Element;
  isReplace?: boolean;
  products: IProductTile[] | ProductBasic[];
  isLoading?: boolean;
  criteoButterflyData?: CriteoButterfly[];
  withSkeleton?: boolean;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  nonFocusable?: boolean;
  freeItem?: boolean;
  hideShoppingListButton?: boolean;
  criteoFormatBeaconView?: CriteoFormatBeacon;
  isReorderItems?: boolean;
  carouselClippedCouponId?: string;
  updateCarouselClippedCouponId?: (value: string) => void;
  gaValues?: GaValuesProps;
}

export const ProductsGrid = ({
  products,
  isLoading,
  customTileComponent,
  isReplace,
  criteoButterflyData,
  withSkeleton = true,
  nonFocusable,
  freeItem = false,
  hideShoppingListButton = false,
  criteoFormatBeaconView,
  isReorderItems,
  carouselClippedCouponId,
  updateCarouselClippedCouponId,
  gaValues,
  ...gridItemProps
}: GridProps) => {
  const router = useRouter();
  const { productsPageSize } = useHeaderContext();

  const currentPage = (router.query?.page as string) || '1';
  const [clippedCouponId, setClippedCouponId] = useState('');
  const isV2 = isFeatureEnabled(DYNAMIC_FEATURES.NEW_PRODUCT_TILES);

  useEffect(() => {
    if (carouselClippedCouponId) {
      setClippedCouponId(carouselClippedCouponId);
    }
  }, [carouselClippedCouponId]);

  const handleClipCouponEvent = (couponId: string) => {
    setClippedCouponId(couponId);
    updateCarouselClippedCouponId?.(couponId);
  };

  if (isLoading && withSkeleton) {
    // NOTE: hardcoded count to avoid hydration error on SSR (mobile and desktop have different product counts)
    return <SkeletonWrapper count={12} skeleton={<ProductTileSkeleton />} />;
  }

  return (
    <Grid container className={styles.grid}>
      {(products as IProductTile[])?.map((product, index) => {
        const currentButterflyIndex = getCurrentButterflyBannerIndex({
          index,
          productsPageSize,
          currentPage,
        });
        const currentButterfly =
          typeof currentButterflyIndex === 'number' && criteoButterflyData?.[currentButterflyIndex];
        const currentButterflyWithAvailableProducts = currentButterfly && {
          ...currentButterfly,
          products: currentButterfly?.products.filter((prod) => prod.productData.available),
        };
        const isButterflyProductTile =
          criteoButterflyData?.length && currentButterflyWithAvailableProducts;
        const { xs, sm, md, lg, xl } = gridItemProps;

        return (
          <Fragment key={getProductKey(product)}>
            <Grid
              item
              data-testid={product.productId}
              className={cx(styles.grid_item, { [styles.v2]: isV2 })}
              {...gridItemProps}
            >
              <MainProductTile
                product={product}
                freeItem={freeItem}
                hideShoppingListButton={hideShoppingListButton}
                customTileComponent={customTileComponent}
                nonFocusable={nonFocusable}
                criteoFormatBeaconView={criteoFormatBeaconView}
                clippedCouponId={clippedCouponId}
                onClipCouponEvent={handleClipCouponEvent}
                isReorderItem={isReorderItems}
                isV2={isV2}
                gaValues={gaValues}
              />
            </Grid>
            {isButterflyProductTile ? (
              <Grid
                item
                className={styles.grid_item}
                xs={xs && xs * 2}
                sm={sm && sm * 2}
                md={md && md * 2}
                lg={lg && lg * 2}
                xl={xl && xl * 2}
              >
                <ButterflyProductTile
                  criteoButterfly={currentButterflyWithAvailableProducts}
                  size={{ xs, sm, md, lg, xl }}
                  customTileComponent={customTileComponent}
                  gridItemProps={gridItemProps}
                  criteoFormatBeaconView={criteoFormatBeaconView}
                  isV2={isV2}
                  gaValues={gaValues}
                />
              </Grid>
            ) : null}
          </Fragment>
        );
      })}
    </Grid>
  );
};
