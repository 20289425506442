// NOTE manually sync these to @types/dynamicFeatures.ts, merging enums is currently not possible
export enum FEATURE {
  COS = 'newUXCOS',
  STANDING_ORDERS = 'standingOrder',
  EXPRESS_TOGGLE = 'expressToggleSwitch',
  REFER_A_FRIEND = 'referAFriend2024',
  NEW_PRODUCT_TILES = 'newProductTiles2024',
  LEFT_NAV = 'leftNavigation2024',
  TOP_NAV_DROPDOWN = 'departmentNavDropdown',
  EXPRESS_TIMESLOT_ALERT_MESSAGING = 'expressTimeslotAlertMessaging',
  HIDE_EXPRESS_TIMESLOTS = 'hideExpressTimeslots2025',
}
