import { logger } from '@logger';

const copyOnEvent = (e: Event) => {
  const closestToCopy = (e.target as HTMLElement).closest('[data-copy-to-clipboard]');
  if (!closestToCopy) {
    return;
  }
  const text = closestToCopy.getAttribute('data-copy-to-clipboard') || '';
  navigator.clipboard.writeText(text);
  logger.info(`Copied to clipboard: ${text}`);
};

export const initCopyToClipboard = (el?: HTMLElement) => {
  if (!navigator.clipboard) {
    return;
  }

  if (!el) {
    el = document.body;
  }

  el.addEventListener('click', copyOnEvent);
};

export const destroyCopyToClipboard = (el?: HTMLElement) => {
  if (!el) {
    el = document.body;
  }

  el.removeEventListener('click', copyOnEvent);
};
