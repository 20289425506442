import { useRef, useState } from 'react';
import { Formik, Field, Form, FormikValues, FormikErrors } from 'formik';
import cx from 'classnames';
import * as yup from 'yup';
import Trans from 'next-translate/Trans';
import { Typography } from '@mui/material';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { useAccountPreferences } from '@hooks/useAccountPreferences';
import { ALERT_TYPES } from '@commons/account';
import { Alert, Button, FormikInput } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { EMAIL_REGEX } from '@utils/validation';
import { ContactUsModal } from '@components/Modals/ContactUsModal/ContactUsModal';
import { PageBlock } from '../PageBlock/PageBlock';
import { SubmitButton } from '../SubmitButton/SubmitButton';
import { CustomCheckbox } from '../CustomCheckbox/CustomCheckbox';
import styles from './EmailBlock.module.scss';

enum FIELDS {
  EMAIL = 'email',
  OFFERS = 'offers',
}

interface EmailBlockProps {
  email: string;
  sendNewsLetter: boolean;
  isSocialOnly: boolean;
  isEmailOtpLogin?: boolean;
}

export const EmailBlock = ({
  isSocialOnly,
  sendNewsLetter,
  email,
  isEmailOtpLogin,
}: EmailBlockProps) => {
  const { updateAccountEmail, updateAccountEmailPreferences } = useAccountPreferences();
  const { t } = useLocalization('account');
  const emailFieldName = t('preferences.forms.email.fields.email.label');
  const { dispatchAlert } = useAccountAlertContext();
  const titleRef = useRef<HTMLDivElement>(null);
  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);
  const schema = yup.object().shape({
    email: isSocialOnly
      ? yup.string()
      : yup
          .string()
          .matches(
            EMAIL_REGEX,
            t('preferences.forms.errors.emailFormat', { field: emailFieldName }),
          )
          .required(t('preferences.forms.errors.required')),
  });

  const handleSubmit = (values: FormikValues) => {
    const newEmail = values.email;
    const newsletterSent = values.offers;
    const emailChanged = newEmail !== email;
    const newsletterChanged = newsletterSent !== sendNewsLetter;
    if (emailChanged) {
      updateAccountEmail(newEmail);
    }

    if (newsletterChanged) {
      updateAccountEmailPreferences(newsletterSent);
    }
  };

  const handleValidationError = (isValid: boolean) => {
    !isValid && dispatchAlert(ALERT_TYPES.ERROR, t('preferences.alerts.message.invalidEmail'));
    titleRef?.current?.focus();
  };

  const handleCloseContactUsModal = () => {
    setIsContactUsModalOpen(false);
  };

  const formLoginFields = (
    values: FormikValues,
    errors: FormikErrors<{ email: string; offers: boolean }>,
  ) => {
    const helperText = isSocialOnly
      ? t('preferences.forms.email.fields.email.disabledMessage')
      : errors.email;

    return isEmailOtpLogin ? (
      <>
        <Typography component="label" className={styles.email_text_label}>
          {t(`preferences.forms.email.fields.emailAddress`)}
        </Typography>
        <Typography component="h5" className={styles.email_text}>
          {values.email}
        </Typography>
        <Alert className={cx(styles.alert, styles.success)} size="medium">
          <Trans
            i18nKey="account:preferences.emailOtpEnabled"
            components={{
              contactUsComponent: (
                <Button
                  variant="underline"
                  className={styles.contact_us}
                  onClick={() => {
                    setIsContactUsModalOpen(true);
                  }}
                ></Button>
              ),
            }}
          />
        </Alert>
      </>
    ) : (
      <Field
        className={styles.email}
        data-testid="email"
        name={FIELDS.EMAIL}
        label={emailFieldName}
        disabled={isSocialOnly}
        error={errors.email}
        helperText={helperText}
        component={FormikInput}
        id={FIELDS.EMAIL}
        autoComplete="email"
      />
    );
  };

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          email,
          offers: sendNewsLetter,
        }}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, isValid }) => {
          return (
            <PageBlock title={t('preferences.forms.email.title')} ref={titleRef}>
              <Form>
                {formLoginFields(values, errors)}
                <Field
                  name={FIELDS.OFFERS}
                  id={FIELDS.OFFERS}
                  data-testid="offers"
                  type="checkbox"
                  label={t('preferences.forms.email.fields.offers.label')}
                  additionalText={
                    <Typography
                      variant="smallBody"
                      component="p"
                      className={styles.checkbox_additional_text}
                    >
                      {t('preferences.forms.email.fields.offers.additionalText')}
                    </Typography>
                  }
                  component={CustomCheckbox}
                />

                <SubmitButton
                  disabled={isSocialOnly}
                  aria-label={t('preferences.forms.email.button.text')}
                  onClick={() => handleValidationError(isValid)}
                >
                  {t('preferences.forms.email.button.text')}
                </SubmitButton>
              </Form>
            </PageBlock>
          );
        }}
      </Formik>
      <ContactUsModal
        isOpen={isContactUsModalOpen}
        onClose={handleCloseContactUsModal}
        onBack={handleCloseContactUsModal}
      />
    </>
  );
};
