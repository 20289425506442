import { cloneElement, ReactElement, SyntheticEvent } from 'react';
import styles from './SkipLink.module.scss';

interface SkipLinkProperties {
  className?: string;
  children: ReactElement;
  skipTo?: string;
}

export const SkipLink = ({
  className = styles.skiplink,
  children,
  skipTo = '#main-layout,#side-nav-layout',
}: SkipLinkProperties) => {
  const onClick = (event: SyntheticEvent) => {
    event.preventDefault();

    const container: HTMLElement | null = document.querySelector(skipTo);

    if (container) {
      container.tabIndex = -1;
      container.focus();
      setTimeout(() => container.removeAttribute('tabindex'), 1000);
    }
  };

  return cloneElement(children, { onClick, className: className });
};
