/* eslint-disable @typescript-eslint/naming-convention */
import { IGaCustomer } from '@modules/ga/eventBodyGetters/utils/ga-customer';

export interface PurchaseEventOptions {
  customer: IGaCustomer;
}

interface PurchaseEvent {
  event: string;
  event_name: string;
  ua_action: string;
  ua_label?: string;
  customer: IGaCustomer;
}

export const getSetEmailEvent = ({ customer }: PurchaseEventOptions): PurchaseEvent => {
  return {
    event: 'set-user-email',
    event_name: 'set-user-email-update',
    customer,
    ua_action: 'Email Update',
  };
};
