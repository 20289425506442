import { useState } from 'react';
import { ProductBasicData } from '@api';
import {
  fireRWProductAddToCartGAEvent,
  fireRWProductClickGAEvent,
} from '@modules/ga/events/custom/RWProduct/rwEventCreator';
import { getRWChannel } from '@modules/ga/context/utils';
import { ProductMarketingTags } from '@commons/product';

export const DEFAULT_CHANNEL_NAME = 'unknown';

export const CHANNEL_LIST = {
  recBrSearch: 'rec_br_search',
};

export interface GaValuesProps {
  channel?: string;
}

function useProductListChannel() {
  const [channel, setChannel] = useState(DEFAULT_CHANNEL_NAME);

  const getChannel = (
    product?: { variantId?: string; marketingTags?: ProductMarketingTags } | ProductBasicData,
    channelName?: string,
  ) => {
    if (channel === DEFAULT_CHANNEL_NAME && product?.variantId) {
      return product.variantId;
    }

    if (!!channelName) {
      return channelName;
    }

    return channel;
  };

  const isProductInRWChannel = (product: { variantId?: string }, channelName?: string) =>
    getChannel(product, channelName) === getRWChannel(product);

  const fireRWProductClickEvent = (
    product: { variantId: string; productId: string },
    channelName?: string,
  ) => {
    if (isProductInRWChannel(product, channelName)) {
      fireRWProductClickGAEvent(product?.productId, product.variantId);
    }
  };

  const fireRWProductAddToCartEvent = (
    product: { variantId: string; productId: string },
    channelName?: string,
  ) => {
    if (isProductInRWChannel(product, channelName)) {
      fireRWProductAddToCartGAEvent(product?.productId, product.variantId);
    }
  };
  return {
    getChannel,
    setChannel,
    fireRWProductAddToCartEvent,
    fireRWProductClickEvent,
  };
}

export { useProductListChannel };
