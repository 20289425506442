import {
  forwardRef,
  KeyboardEventHandler,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  memo,
  useMemo,
} from 'react';
import { LegalInterstitial } from '@features/LegalPage/LegalInterstitial';
import { useLocalization } from '@hooks/useLocalization';
import { CollapsedCounter } from '@components/QuantityCounter/components/CollapsedCounter';
import { AutosuggestProduct, ProductTile } from '@commons/product';
import { ProductTileCounter } from './ProductTileCounter';
import { isWrapperClass, getDisableState } from './ProductTileCollapsableCounter.helpers';

interface Props {
  initialValue?: number;
  step?: number;
  min?: number;
  max?: number;
  onChange: (quantity: string) => void;
  onAdd: (isCollapsed?: boolean) => void;
  onRemoveClick?: () => void;
  onMinusClick?: () => void;
  onPlusClick?: () => void;
  size?: 'small' | 'medium' | 'large' | 'extraLarge';
  onBlur?: (value: string) => void;
  isCollapseAnimate?: boolean;
  isFullWidth?: boolean;
  isCollapsedInitial?: boolean;
  isReorder?: boolean;
  value?: string;
  className?: string;
  isRestricted?: boolean;
  buttonArialLabel?: string;
  nonFocusable?: boolean;
  addBtnRef?: React.RefObject<HTMLButtonElement>;
  product?: ProductTile | AutosuggestProduct;
  disableDelete?: boolean;
  uniqueProductId?: string;
  addedProductId?: string;
  setAddedProductId?: Dispatch<SetStateAction<string>>;
  lastAction?: string;
  setLastAction?: Dispatch<SetStateAction<string>>;
  cartLoading?: boolean;
  setCartLoading?: Dispatch<SetStateAction<boolean>>;
  isV2?: boolean;
  disabledProductId?: string;
  isReplaceProduct?: boolean;
}

const ProductTileCollapsableCounter = memo(
  forwardRef<HTMLDivElement | null, Props>(
    (
      {
        isCollapseAnimate = false,
        isFullWidth,
        isReorder,
        className = '',
        initialValue = 0,
        value = '',
        min = 0,
        max = 99,
        step = 1,
        size = 'small',
        isCollapsedInitial = true,
        isRestricted = false,
        onRemoveClick,
        onMinusClick,
        onPlusClick,
        onChange,
        onBlur,
        onAdd,
        buttonArialLabel,
        nonFocusable,
        addBtnRef,
        product,
        disableDelete = false,
        uniqueProductId,
        addedProductId,
        setAddedProductId,
        lastAction,
        setLastAction,
        cartLoading = false,
        setCartLoading,
        isV2 = false,
        disabledProductId,
        isReplaceProduct,
        ...restProps
      },
      ref,
    ) => {
      const [isCollapsed, setIsCollapsed] = useState(isCollapsedInitial);

      const { t } = useLocalization('components');

      const wrapperClass = useMemo(
        () => isWrapperClass(className, isV2, isCollapseAnimate, isCollapsed),
        [className, isV2, isCollapseAnimate, isCollapsed],
      );

      useEffect(() => {
        setIsCollapsed(isCollapsedInitial);
      }, [isCollapsedInitial]);

      const showCounter = () => {
        setIsCollapsed(false);
      };

      const handleKeyDown: KeyboardEventHandler = (event) => {
        if (event.key === 'Enter') showCounter();
      };

      const handleKeyDownAdd: KeyboardEventHandler = (event) => {
        if (event.key === 'Enter') onAdd?.(true);
      };

      const handleAgree = () => {
        if (isCollapsed) {
          showCounter();
        }

        onAdd?.();
      };

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
      };

      const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        onBlur?.(event.target.value);
      };

      return (
        <LegalInterstitial isRestricted={isRestricted} onAgree={handleAgree}>
          <div
            {...restProps}
            ref={ref}
            role="presentation"
            data-testid={t('quantityCounter.ariaLabels.counter')}
            className={wrapperClass}
            onKeyDown={handleKeyDown}
          >
            {isCollapseAnimate && isCollapsed ? (
              <CollapsedCounter
                addBtnRef={addBtnRef}
                isReorder={isReorder}
                productCount={Number(value)}
                onClick={() => onAdd(true)}
                onKeyDown={handleKeyDownAdd}
                buttonArialLabel={buttonArialLabel}
                nonFocusable={nonFocusable}
                product={product}
                isV2={isV2}
              />
            ) : (
              <ProductTileCounter
                className={className}
                addBtnRef={addBtnRef}
                value={value}
                step={step}
                couldBeRemoved={Number(value) <= min}
                plusDisabled={getDisableState(
                  Number(value) >= max,
                  disabledProductId,
                  product?.productId,
                )}
                isCollapseAnimate={isCollapseAnimate}
                isCollapsed={isCollapsed}
                size={size}
                min={min}
                max={max}
                onRemoveClick={onRemoveClick}
                onMinusClick={onMinusClick}
                onPlusClick={onPlusClick}
                onChange={handleChange}
                onBlur={handleBlur}
                buttonArialLabel={buttonArialLabel}
                nonFocusable={nonFocusable}
                disableDelete={getDisableState(
                  disableDelete,
                  disabledProductId,
                  product?.productId,
                )}
                counterInputId={uniqueProductId}
                setLastAction={setLastAction}
                isV2={isV2}
                disableRemoveButton={cartLoading}
                isReplaceProduct={isReplaceProduct}
              />
            )}
          </div>
        </LegalInterstitial>
      );
    },
  ),
);

ProductTileCollapsableCounter.displayName = 'ProductTileCollapsableCounter';

export { ProductTileCollapsableCounter };
