import { useRouter } from 'next/router';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { ProductsGrid } from '@components/ProductsGrid';
import { ProductTileManager } from '@components/Tiles/ProductTile/ProductTileManager';
import { useApplyScalePrice, useLocalization } from '@hooks/index';
import { ProductListTitleProvider } from '@modules/ga/context/title';
import { ProductListLocationProvider, useProductListLocation } from '@modules/ga/context/location';
import { DYNAMIC_FEATURES, isFeatureEnabled } from '@utils/dynamicFeatures';
import { channels } from '@modules/ga/context/constants';
import styles from './GroupScaleModal.module.scss';

interface GroupScaleModalProps {
  open: boolean;
  onClose: () => void;
  grpDescription: string | null;
  scaleCount: number | null;
  grpVersion: string | null;
  grpId: string | null;
}

export const GroupScaleModal = ({
  open,
  onClose,
  grpDescription,
  scaleCount,
  grpVersion,
  grpId,
}: GroupScaleModalProps) => {
  const router = useRouter();
  const isPDPPage = router.asPath.includes('/p/');
  const isSectionPage = router.asPath.includes('/section/');
  const { getLocation } = useProductListLocation();
  const { products, isLoading, error, isDiscountApplied, savedMoney, itemsAmountForDiscount } =
    useApplyScalePrice({
      scaleCount,
      grpVersion,
      grpId,
    });
  const { t } = useLocalization('product');
  const isV2 = isFeatureEnabled(DYNAMIC_FEATURES.NEW_PRODUCT_TILES);
  const gaValues = { channel: channels.groupScale };

  const title = isDiscountApplied ? (
    <>
      {t('groupScaleModal.savedTitle')}
      <span className={styles.saved_money}>{savedMoney}</span>
    </>
  ) : (
    t('groupScaleModal.addMoreTitle', { itemsAmountForDiscount })
  );

  return (
    <ModalWindow
      open={open}
      onClose={onClose}
      title={title}
      subtitle={grpDescription}
      className={styles.modal}
    >
      <ProductListLocationProvider location={isSectionPage ? 'section' : getLocation()}>
        <ProductListTitleProvider title={!isPDPPage && grpDescription ? grpDescription : ''}>
          {error}
          {products && (
            <ProductsGrid
              isLoading={isLoading}
              products={products}
              xs={6}
              sm={4}
              gaValues={gaValues}
              customTileComponent={(product) => (
                <ProductTileManager
                  product={product}
                  hideGroupScale={true}
                  isV2={isV2}
                  gaValues={gaValues}
                />
              )}
            />
          )}
        </ProductListTitleProvider>
      </ProductListLocationProvider>
    </ModalWindow>
  );
};
