import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { ProductTileLine } from '@components/Tiles/ProductTileLine/ProductTileLine';
import { useLocalization } from '@hooks/useLocalization';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { getAtpReplaceEvent } from '@modules/ga/eventBodyGetters/atpEvents';
import { CartLineControls } from './components/Controllers/CartLineControls';
import { AdditionalInfo } from './components/AdditionalInfo/AdditionalInfo';
import { CartTileBaseProps } from './types';
import styles from './CartTile.module.scss';

export const CartTile = ({
  cartLineId,
  product,
  quantitySelector,
  currentPrice,
  priceIndicators,
  previousPrice,
  discount,
  variants,
  configurationDescription,
  className,
  cartTileClassName,
  coupon,
  promoApplied,
  promoDescription,
  handleProductModal,
  handleEditModal,
  isUnavailable,
  unavailableIcon,
  atpLine,
  onReplace,
  alcoholRestricted,
  modifiedItem,
  isOutOfStock,
  isBundle,
  children,
  selectedSalesUnitLabel,
  inModifyMode,
  sampleProduct = false,
  carTileIndex,
  deletedItemIndex,
  handleDeleteEvent,
  isFilledBag,
  ...props
}: CartTileBaseProps) => {
  const { t } = useLocalization('components');
  const cartTileRef = useRef(null);
  const [isCartTileUpdating, setIsCartTileUpdating] = useState(false);
  const currentPriceRef = useRef(currentPrice.value);

  const description = alcoholRestricted ? t('cartTile.alcoholUnavailable') : atpLine?.description;
  const isConfigurableProduct = !!variants?.length;
  const unavailableText = isUnavailable ? description : t('cartTile.OutOfStock');

  const handleReplace = () => {
    onReplace({
      id: cartLineId,
      url: product.categoryPageUrl,
      products: atpLine?.recommendedProducts,
      availableQuantity: atpLine?.availableQuantity,
    });

    const atpReplaceEventObj = {
      cartLineProduct: product ? [product] : [],
      buttonTitle: atpLine?.availableQuantity ? 'keep or replace' : 'replace',
      reason: unavailableText || '',
    };

    sendGtmEvent(getAtpReplaceEvent(atpReplaceEventObj));
  };

  const handleDelete = (index?: number) => {
    if (typeof index === 'number' && handleDeleteEvent) {
      handleDeleteEvent(index);
    }
  };

  useEffect(() => {
    if (deletedItemIndex === carTileIndex && cartTileRef.current) {
      const nextBtn = (cartTileRef.current as HTMLDivElement).querySelector('[role="button"]');

      if (nextBtn) {
        (nextBtn as HTMLDivElement).focus();
      }
    }
  }, [cartTileRef]);

  useEffect(() => {
    if (currentPrice.value !== currentPriceRef.current) {
      currentPriceRef.current = currentPrice.value;
      setIsCartTileUpdating(true);
    }
  }, [currentPrice]);

  return (
    <div {...props} className={cx(styles.wrapper, className)} ref={cartTileRef}>
      <ProductTileLine
        className={cartTileClassName}
        productPageUrl={product?.productPageUrl}
        productName={product?.productName}
        brandName={product?.brandName}
        img={product?.productImage}
        isPeakQuality={product?.featureTags?.topPick}
        isExpressEligible={product?.featureTags.expressEligible}
        isAvailable={!isUnavailable}
        defaultQuantity={quantitySelector.quantity}
        isModifiedItem={modifiedItem}
        isFilledBag={isFilledBag}
        additionalInfo={
          !isUnavailable && !isOutOfStock ? (
            <AdditionalInfo
              product={product}
              discount={discount}
              coupon={coupon}
              promoApplied={promoApplied}
              promoDescription={promoDescription}
              variants={variants}
              configurationDescription={configurationDescription}
              selectedSalesUnitLabel={selectedSalesUnitLabel}
              sampleProduct={sampleProduct}
              isCartTileUpdating={isCartTileUpdating}
            />
          ) : (
            <div className={styles.unavailable_info_section}>
              <span className={cx({ [styles.unavailable]: isUnavailable })}>{unavailableIcon}</span>
              <span className={cx(styles.description, { [styles.unavailable]: isUnavailable })}>
                {unavailableText}
              </span>
            </div>
          )
        }
        handleProductModal={handleProductModal}
        controllers={
          <CartLineControls
            cartLineIndex={carTileIndex}
            cartLineId={cartLineId}
            quantitySelector={quantitySelector}
            currentPrice={currentPrice}
            previousPrice={previousPrice}
            priceIndicators={priceIndicators}
            product={product}
            configurable={isConfigurableProduct}
            onEditModal={handleEditModal}
            onReplace={handleReplace}
            replaceable={!!atpLine?.recommendedProducts.length}
            modified={modifiedItem}
            unavailable={!!isUnavailable || isOutOfStock}
            isBundle={isBundle}
            inModifyMode={!!inModifyMode}
            onDelete={handleDelete}
            setIsCartTileUpdating={setIsCartTileUpdating}
            availableQuantity={atpLine?.availableQuantity}
            isFilledBag={isFilledBag}
          />
        }
      />
      {children}
    </div>
  );
};
