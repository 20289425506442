import { useRouter } from 'next/router';
import { Typography } from '@mui/material';
import { useLocalization } from '@hooks/useLocalization';
import { Button } from '@components/UI';
import { Grid } from '@components/UI/Grid/Grid';
import styles from './AccountDeleteConfirm.module.scss';
import { useDeleteAccount } from './hooks/useDeleteAccount';

export const AccountDeleteConfirm = () => {
  const { t } = useLocalization('account');
  const router = useRouter();
  const deletionToken = Array.isArray(router.query.deletionToken)
    ? router.query.deletionToken.join('')
    : router.query.deletionToken || '';

  const { deleteAccount } = useDeleteAccount({ deletionToken });

  const handleDeleteAccount = () => {
    deleteAccount();
  };

  return (
    <Grid container xs={12} justifyContent="center">
      <Grid item xs={10}>
        <Typography variant="h3" className={styles.title}>
          {t('deleteConfirm.title')}
        </Typography>
        <Typography variant="smallBody" component="div" className={styles.description}>
          {t('deleteConfirm.description')}
        </Typography>
        <Button size="large" onClick={handleDeleteAccount}>
          {t('deleteConfirm.button')}
        </Button>
      </Grid>
    </Grid>
  );
};
