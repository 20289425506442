import { Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react';
import { Router } from 'next/router';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { updateHistory } from '@hooks/common/useRouterHistory';
import { fireCommonCustomPageView } from '@modules/ga/events/custom/custom-page-view/customPageViewCreator';
import { clearDataLayer, sendGtmEvent } from '@modules/ga/gtm-event';
import { sessionStorageService, STORAGE_KEYS, localStorageService } from '@utils/storageService';
import { InitFunctionProps } from '@utils/genesysChatService';
import { incrementBannerSequenceNumber } from '@utils/bannerCache';
import { removeHashFromURL } from '@utils/removeHashFromURL';
import { destroyCopyToClipboard, initCopyToClipboard } from '@utils/copyToClipboard';
import { routing } from '@constants/routing';
import { initMasqueradeMode } from '@utils/initMasqueradeMode';
import { MasqueradeQueryParams } from '@modules/masquerade-query-params';
import { on } from '@modules/emitter';
import { IFail, TFail } from '@modules/fail';
import { GRAPHQL_ERRORS } from '@commons/graphqlErrors';
import { errorsMap } from '@components/ApiErrorBoundary/ApiErrorBoundary';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { clientDeviceTypeVar } from '@modules/pageSize/pageSize.variables';
import { CLIENT_DEVICE_TYPE } from '@modules/pageSize/pageSize.constants';

export const useRouteEffects = (
  router: Router,
  isInitRoute: MutableRefObject<boolean>,
  isPopStateHappend: MutableRefObject<boolean>,
) => {
  useEffect(() => {
    removeHashFromURL(router);

    router.beforePopState(() => {
      isPopStateHappend.current = true;
      return true;
    });

    if (sessionStorageService?.read(STORAGE_KEYS.LAST_URL)) {
      const count = localStorageService?.read(STORAGE_KEYS.PAGE_REFRESH_COUNT) ?? 0;
      localStorageService?.put(STORAGE_KEYS.PAGE_REFRESH_COUNT, count + 1);
    }

    if (!isInitRoute.current) {
      isInitRoute.current = true;
      updateHistory(router.asPath);
    }

    const handleRouteChange = (url: string) => {
      if (isPopStateHappend.current) {
        clearDataLayer();
        fireCommonCustomPageView({
          url,
        });
        isPopStateHappend.current = false;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (typeof window !== 'undefined' && !(window as any).navigation) {
        incrementBannerSequenceNumber();
      }
    };

    router.events.on('routeChangeStart', updateHistory);
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', updateHistory);
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);
};

export const useGenesysChatEffects = (genesysService: {
  init: ({ attributes, shouldOpenChat }: InitFunctionProps) => void;
  openGenesysChat: () => void;
}) => {
  useEffect(() => {
    const userInfo = sessionStorageService?.read(STORAGE_KEYS.CHAT_INFO);

    if (sessionStorageService?.isKeyExists(STORAGE_KEYS.IS_CHAT_INITIALIZED)) {
      genesysService.init({ attributes: { Name: userInfo?.fullName, Email: userInfo?.email } });
    }
  }, [genesysService]);
};

export const useClipboardEffects = () => {
  useEffect(() => {
    initCopyToClipboard();
    return () => {
      destroyCopyToClipboard();
    };
  }, []);
};

export const useMasqueradeEffects = (
  client: ApolloClient<NormalizedCacheObject>,
  router: Router,
  masqueradeInitCalled: MutableRefObject<boolean>,
  masqueradeModeData: MasqueradeQueryParams,
) => {
  useEffect(() => {
    if (router.pathname === routing.masqueradeModeInitialization && !masqueradeInitCalled.current) {
      masqueradeInitCalled.current = true;
      initMasqueradeMode({ client, router, masqueradeModeData });
    }
  }, [client, router, masqueradeInitCalled, masqueradeModeData]);
};

export const useAPIErrorEffects = (
  errorType: GRAPHQL_ERRORS,
  setErrorType: Dispatch<SetStateAction<GRAPHQL_ERRORS>>,
  setFailed: Dispatch<SetStateAction<boolean>>,
) => {
  useEffect(
    () =>
      on((action) => {
        if (action.type === TFail) {
          const errorMessage = (action as IFail).message as GRAPHQL_ERRORS;
          setErrorType(errorsMap[errorMessage] ? errorMessage : GRAPHQL_ERRORS.NULL);
          if (errorType !== GRAPHQL_ERRORS.NULL) {
            setFailed(true);
          }
          if (
            errorMessage === GRAPHQL_ERRORS.INTERNAL_SERVER_ERROR ||
            errorMessage === GRAPHQL_ERRORS.UNEXPECTED_BACKEND_EXCEPTION
          )
            sendGtmEvent(
              GtmCustomEvent({
                /* eslint-disable @typescript-eslint/naming-convention */
                event: 'error',
                event_name: 'error_500',
                ua_category: 'Error',
                ua_action: '500 Error',
                ua_label: '500 Error',
                /* eslint-enable @typescript-eslint/naming-convention */
              }),
            );
        }
      }),
    [],
  );
};

export const useDeviceEffects = (clientDevice: CLIENT_DEVICE_TYPE) => {
  useEffect(() => {
    if (clientDevice) {
      clientDeviceTypeVar(clientDevice);
    }
  }, [clientDevice]);
};
