import { GRAPHQL_ERRORS } from '@commons/graphqlErrors';

export const serverError = {
  title: 'serverError.title',
  subtitle: '',
  buttonText: 'serverError.goBackShopping',
};

export const errorsMap = {
  [GRAPHQL_ERRORS.INTERNAL_SERVER_ERROR]: serverError,
  [GRAPHQL_ERRORS.UNEXPECTED_BACKEND_EXCEPTION]: serverError,
  [GRAPHQL_ERRORS.GRAPHQL_PARSE_FAILED]: serverError,
  [GRAPHQL_ERRORS.GRAPHQL_VALIDATION_FAILED]: serverError,
  [GRAPHQL_ERRORS.PERSISTED_QUERY_NOT_FOUND]: serverError,
  [GRAPHQL_ERRORS.PERSISTED_QUERY_NOT_SUPPORTED]: serverError,
  [GRAPHQL_ERRORS.OPERATION_RESOLUTION_FAILURE]: serverError,
  [GRAPHQL_ERRORS.TOO_MANY_REQUESTS]: {
    title: 'tooManyRequests.title',
    subtitle: '',
    buttonText: '',
  },
  [GRAPHQL_ERRORS.NOT_FOUND]: {
    title: 'notFound.title',
    subtitle: 'notFound.subtitle',
    buttonText: 'notFound.goBackShopping',
  },
  [GRAPHQL_ERRORS.SERVER_UNAVAILABLE]: {
    title: 'serverUnavailable',
    subtitle: '',
    buttonText: 'serverError.goBackShopping',
  },
  [GRAPHQL_ERRORS.NULL]: serverError,
};
