import { useCallback, useRef } from 'react';
import { ProductTile } from '@commons/product';
import { CriteoFormatBeacon } from '@modules/criteo';
import { useCriteoBeacon } from '@hooks/criteo/useCriteoBeacon';
import { useSelectItemEvent } from '@modules/ga/hooks/useSelectItemEvent';
import { useProductListContext } from '@context/ProductListContext';
import { GaValuesProps } from '@modules/ga/context/channel';

export interface UseProductTileAnalyticsProps {
  product: ProductTile;
  onClick?: (productId?: string) => void;
  onClickCriteo?: () => void;
  criteoFormatBeaconView?: CriteoFormatBeacon;
  gaValues?: GaValuesProps;
}

export const useProductTileAnalytics = (props: UseProductTileAnalyticsProps) => {
  const { product, onClick, onClickCriteo, criteoFormatBeaconView, gaValues } = props;

  const productRef = useRef<HTMLDivElement>(null);

  const { trackSelectItem } = useSelectItemEvent();
  const { fireRWProductClickEvent } = useProductListContext();

  const { handleClick: handleBeaconClick } = useCriteoBeacon({
    onClickBeacon: product.clickBeacon,
    onLoadBeacon: product.imageBeacon,
    onViewBeacon: product.viewBeacon,
    componentRef: productRef,
    criteoFormatBeaconView,
  });

  const handleProductClick = useCallback(
    (trackListName?: string) => {
      if (product) {
        trackSelectItem(product, trackListName || '', gaValues);
        fireRWProductClickEvent(product, gaValues?.channel);
      }

      onClickCriteo?.();

      onClick?.(product.productId);

      handleBeaconClick?.();
    },
    [product, trackSelectItem, fireRWProductClickEvent, onClickCriteo, onClick, handleBeaconClick],
  );

  return {
    productRef,
    handleProductClick,
  };
};
