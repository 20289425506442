import classNames from 'classnames';
import { useRef } from 'react';
import { ProductTile } from '@commons/product';
import { TrackViewItemList } from '@modules/ga/events/ecommerce/view-item-list/TrackViewItemList';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { TileName } from '@components/Tiles/components/TileName/TileName';
import { TileImage } from '@components/Tiles/components/TileImage/TileImage';
import { TileTagList } from '@components/Tiles/components/TiletagList/TileTagList';
import productTileStyles from '@components/Tiles/ProductTile/ProductTile.module.scss';
import { SoldOutBlock } from '@components/Tiles/ProductTileOutOfStock/components/SoldOutBlock';
import { GaValuesProps } from '@modules/ga/context/channel';
import styles from './ProductTileOutOfStock.module.scss';

export interface ProductTileOutOfStockProps {
  children?: never;
  className?: string;
  product: ProductTile;
  isV2?: boolean;
  gaValues?: GaValuesProps;
}

export const ProductTileOutOfStock = ({
  className,
  product,
  isV2 = false,
  gaValues,
}: ProductTileOutOfStockProps) => {
  const productRef = useRef<HTMLDivElement>(null);
  const { getListName } = usePageListName();
  const itemListName = getListName(product, gaValues?.channel);

  return (
    <div
      className={classNames(styles.product_card, styles.disabled, className, {
        [productTileStyles.product_hover]: !isV2,
        [styles.v2]: isV2,
      })}
      ref={productRef}
      role="listitem"
    >
      <TrackViewItemList productRef={productRef} product={product} listName={itemListName} />
      <div
        className={styles.product_card_main_info}
        data-ga-tile-list={itemListName}
        data-ga-tile-list-type="product-tile"
        data-ga-tile-id={product.productId}
      >
        {product.productImage.ref && (
          <TileImage
            img={{
              ref: product.productImage.ref,
              alt: product.productImage.alt ?? 'product image',
            }}
            productUrl={product.productPageUrl}
            isDisabled={true}
            hasWineData={product.hasWineData}
            isV2={isV2}
            expressEligible={product.featureTags.expressEligible}
            peakQuality={product.featureTags.topPick}
            gaValues={gaValues}
          />
        )}
        <TileTagList expressEligible={product.featureTags.expressEligible} isV2={isV2} />
        <TileName
          name={product.productName}
          brand={product.brandName}
          url={product.productPageUrl}
          isV2={isV2}
          gaValues={gaValues}
        />
      </div>
      <SoldOutBlock product={product} productRef={productRef} isV2={isV2} />
    </div>
  );
};
