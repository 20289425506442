import { useLazyGetPendingOrders } from '@hooks/cart/useLazyGetPendingOrders';
import {
  useAddToCart,
  useModifiedAddToCart,
  useSetProductToAddToCartVar,
} from '@hooks/cart/useAddToCart';
import { useSetProductToAddToOrder } from '@hooks/order/useSetProductToAddToOrder';
import { useReplaceCartItem } from '@hooks/cart/useReplaceCartItem';
import {
  getAddToCartParams,
  getAddToOrderParams,
  getProductInfoFromCartLines,
  Params,
} from '@context/AddToCartContext/AddToCartContext.utils';
import { addTask } from '@modules/queue/queue';
import { setShowPendingOrdersModalVar } from '@graphql/variables/showPendingOrdersModalVar';
import { AUTH_ERROR_MESSAGE } from '@constants/errorCodes';
import { cartAdapter } from '@adapters/cartAdapter';
import { useSetProductQuantityInLineItem } from '@hooks/cart/useSetProductQuantityInLineItem';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { useProductListContext } from '@context/ProductListContext';
import { useCartForCheckoutContext } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { useAuthContext } from '@modules/auth/context/AuthContext';

const { getMinimumCartLinesList } = cartAdapter();

export const useAddToCartFlowMethods = () => {
  const [addToCart] = useAddToCart();
  const [modifiedAddToCart] = useModifiedAddToCart();
  const { cartInfo } = useCartForCheckoutContext();
  const { data: cartData, rawData, isEmpty: isEmpty, loading: cartLoading } = cartInfo;
  const cartLines = getMinimumCartLinesList(rawData);
  const [getPendingOrders] = useLazyGetPendingOrders();
  const { isKnownUser: isLoggedIn, isRecognizedUser } = useAuthContext();
  const { setProductToAddToCart } = useSetProductToAddToCartVar();
  const { setProductToAddToOrder } = useSetProductToAddToOrder();
  const [setProductQuantityInLineItem] = useSetProductQuantityInLineItem();
  const [replaceCartItem] = useReplaceCartItem();
  const { getListName } = usePageListName();
  const { getProductPosition } = useProductListContext();

  const getProductInfoFromCart = (productId: string, soldBySalesUnit = false) => {
    return {
      data: getProductInfoFromCartLines(cartLines, productId, soldBySalesUnit),
      loading: cartLoading,
    };
  };
  const handleAddToCart = async (params: Params, callback?: () => void) => {
    await addTask(async () => {
      const { quantity, configuration, salesUnit, product, eventSource } = params;
      const itemListName = getListName(product);
      const itemPosition = getProductPosition(product.productId);

      if (isRecognizedUser || !isLoggedIn || !isEmpty) {
        await addToCart({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          variables: {
            ...getAddToCartParams({
              product,
              quantity,
              configuration,
              itemPosition,
              salesUnit,
              itemListName,
              eventSource,
            }),
          },
        });
      } else {
        setProductToAddToOrder({
          ...getAddToOrderParams({
            product,
            quantity,
            configuration,
            salesUnit,
          }),
        });
        setProductToAddToCart({
          ...getAddToCartParams({
            product,
            quantity,
            configuration,
            itemPosition,
            salesUnit,
            itemListName,
          }),
        });
        await getPendingOrders({
          onCompleted: (response) => {
            const shouldShowModal =
              response?.pendingOrdersInfo?.showPendingOrders &&
              !!response?.pendingOrdersInfo?.pendingOrders?.length;
            shouldShowModal ? setShowPendingOrdersModalVar(shouldShowModal) : modifiedAddToCart();
          },
          onError: (error) => {
            if (error.message === AUTH_ERROR_MESSAGE) {
              addToCart({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                variables: {
                  ...getAddToCartParams({
                    product,
                    quantity,
                    configuration,
                    itemPosition,
                    itemListName,
                    salesUnit,
                  }),
                },
              });
            }
          },
        });
      }

      if (callback) callback();
    });
  };

  return {
    handleAddToCart,
    cart: cartData,
    getProductInfoFromCart,
    setProductQuantityInLineItem,
    replaceCartItem,
  };
};
