import {
  getAddToCartParams,
  Params,
  ParamsContext,
} from '@context/AddToCartContext/AddToCartContext.utils';
import { useAddToCartLightForContext } from '@hooks/cart/useAddToCartLight';
import { useLightCartOptimisticResponse } from '@context/AddToCartContext/hooks/useLightCartOptimisticResponse';
import { useAddToCartLightEvent } from '@modules/ga/hooks/useAddToCartEvent';
import { useLightCart } from '@hooks/cart/useLightCart';
import { cartAdapter } from '@adapters/cartAdapter';

const { getLightCart } = cartAdapter();

export function useHandleAddToCartAndTrack() {
  const [addToCartLight] = useAddToCartLightForContext();
  const { getAddToCartLightOptimisticResponse } = useLightCartOptimisticResponse();
  const { trackAddToLightCart } = useAddToCartLightEvent();
  const { data: cartData } = useLightCart();

  return async (params: Params & { paramsContext: ParamsContext }) => {
    const { quantity, configuration, salesUnit, product, paramsContext, eventSource } = params;
    const newCartApi = await addToCartLight({
      variables: {
        ...getAddToCartParams({
          product,
          quantity,
          configuration,
          itemListName: paramsContext.listName,
          itemPosition: paramsContext.itemPosition,
          salesUnit,
          eventSource,
        }),
      },
      optimisticResponse: getAddToCartLightOptimisticResponse({
        product,
        quantity,
        salesUnit,
        paramsContext,
      }),
    });
    trackAddToLightCart(getLightCart(newCartApi.data?.addToCartLight), cartData, {
      ...paramsContext,
      sponsored: params.product.marketingTags.sponsored,
    });
  };
}
