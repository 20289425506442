import React from 'react';
import cx from 'classnames';
import { NavigationData } from '@hooks/header/useNavigationData';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { GlobalNav } from './components/GlobalNav/GlobalNav';
import { GlobalNavV2 } from './components/GlobalNav/GlobalNavV2';
import styles from './BottomLine.module.scss';

interface BottomLineProps {
  navigationData: NavigationData;
  className?: string;
}

export const BottomLine = ({ className, navigationData }: BottomLineProps) => {
  const { isLeftLayoutEnabled, isTopNavDropdownEnabled } = useHeaderContext();
  const shouldRenderGlobalNavV2 = isLeftLayoutEnabled && !isTopNavDropdownEnabled;

  return (
    <div className={cx([styles.bottom_line, className])}>
      {shouldRenderGlobalNavV2 ? (
        <GlobalNavV2 navigationData={navigationData} />
      ) : (
        <GlobalNav navigationData={navigationData} />
      )}
    </div>
  );
};
