import { fireEvent } from '@modules/ga/emitter';
import { GA_CUSTOM_EVENT_ACTION } from '@modules/ga/type';
import { GA_EVENT_TYPE } from '@modules/ga/constants';

export interface SetEmailOptions {
  newEmail: string;
}

export const fireUpdateEmailEvent = (data: SetEmailOptions) => {
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_CUSTOM_EVENT_ACTION.UPDATE_EMAIL,
    data,
  });
};
