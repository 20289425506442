export const isWrapperClass = (
  className: string,
  isV2: boolean,
  isCollapseAnimate: boolean,
  isCollapsed: boolean,
) => {
  if (!isV2 || (isCollapseAnimate && isCollapsed)) {
    return className;
  } else {
    return '';
  }
};

export const getDisableState = (
  firstCondition: boolean,
  disabledProductId?: string,
  productId?: string,
) => {
  const isProductDisabled = !!disabledProductId ? disabledProductId === productId : false;
  return firstCondition || isProductDisabled;
};
