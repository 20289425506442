import { Dispatch, RefObject, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { Skeleton } from '@mui/material';
import { useDebounce, useUpdateEffect } from 'usehooks-ts';
import { useRouter } from 'next/router';
import { AutosuggestProduct, ProductTile, SelectedConfiguration } from '@commons/product';
import { TileCustomOrder } from '@components/Tiles/components/TileCustomOrder/TileCustomOrder';
import { getAddToCartVariables } from '@hooks/cart/useAddToCart';
import { isRestrictedProduct } from '@utils/isRestrictedProduct';
import { ProductQuantityTooltip } from '@components/ProductQuantityTooltip/ProductQuantityTooltip';
import { ProductTileCollapsableCounter } from '@components/Tiles/ProductTile/ProductTileCollapsableCounter';
import { useAddToCartFlowMethods } from '@hooks/cart/useAddToCartFlowMethods';
import { getProductConfigurationVariables } from '@utils/getProductConfigurationVariables';
import { SingleStoreCartLine as SingleStoreCartLineType } from '@commons/cart';
import { useDeleteFromCart } from '@hooks/cart/useDeleteFromCart';
import { routing } from '@constants/routing';
import { useProductListContext } from '@context/ProductListContext';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { SALES_UNIT_ID_FIELD } from '@constants/salesUnits';
import { GaValuesProps } from '@modules/ga/context/channel';
import styles from './OrderListQuantitySelector.module.scss';

interface Props {
  className?: string;
  product: ProductTile | AutosuggestProduct;
  productContainerRef?: RefObject<HTMLDivElement>;
  isReplaced?: boolean;
  lineToReplace?: string;
  setShouldUpdate?: Dispatch<SetStateAction<boolean>>;
  onAddToCart?: () => void;
  onChangeQuantity?: (options: { delta: number; changeQuantityEventId: string }) => void;
  shouldUpdate?: boolean;
  isReorder?: boolean;
  loading?: boolean;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  onReplace?: (promise: Promise<any>) => void;
  onReplaceCompleted?: (
    selectedProductId: string,
    selectedCartLine?: SingleStoreCartLineType,
  ) => void;
  productCarouselType?: string;
  isV2?: boolean;
  gaValues?: GaValuesProps;
}

const DEFAULT_QUANTITY = 0;

export const OrderListQuantitySelector = ({
  className,
  product,
  isReplaced,
  lineToReplace,
  setShouldUpdate,
  onAddToCart,
  onChangeQuantity,
  isReorder,
  onReplace,
  loading: externalLoading,
  onReplaceCompleted,
  productCarouselType,
  isV2 = false,
  gaValues,
}: Props) => {
  const router = useRouter();
  const inModifyMode = router.pathname === routing.modifyOrder;
  const setProductQuantityAbortController = useRef<AbortController | null>(null);
  const {
    handleAddToCart: addToCart,
    cart: cartData,
    getProductInfoFromCart,
    setProductQuantityInLineItem,
    replaceCartItem,
  } = useAddToCartFlowMethods();
  const [addedProductId, setAddedProductId] = useState('');
  const [lastAction, setLastAction] = useState('');
  const [cartLoading, setCartLoading] = useState(false);
  const [deleteFromCart, { loading: loadingDeleteFromCart }] = useDeleteFromCart();
  const { getProductPosition } = useProductListContext();
  const { getListName } = usePageListName();
  const addBtnRef = useRef<HTMLButtonElement | null>(null);

  const uniqueProductId = `${productCarouselType}_${product?.productId}`;
  const { salesUnits } = product;
  const { minQuantity: minQty, maxQuantity: maxQty } = product.quantity;
  const salesUnit = salesUnits?.[0]?.[SALES_UNIT_ID_FIELD] ?? '';

  const existProduct = useMemo(
    () => cartData.productCounter.find(({ productId }) => product.productId === productId),
    [cartData.productCounter, product.productId],
  );
  const productExistsInCart = existProduct !== undefined;
  const productQuantityInCart = (existProduct?.count || DEFAULT_QUANTITY).toString();

  const { data: productCartInfo, loading } = getProductInfoFromCart(
    product.productId,
    product.soldBySalesUnit,
  );

  const isCustomisableProduct = !!product.variations?.length || salesUnits?.length > 1;
  const [productQuantity, setProductQuantity] = useState(productQuantityInCart);

  useEffect(() => {
    if (productQuantityInCart.toString() === productQuantity) return;
    setProductQuantity(productQuantityInCart);
    // eslint-disable-next-line
  }, [productQuantityInCart]);

  const productQuantityDebounced = useDebounce<string>(productQuantity, 500);

  useUpdateEffect(() => {
    if (productQuantityDebounced === productQuantityInCart) return;
    if (!productCartInfo.cartLineId) return;
    if (productQuantityDebounced === '') return;
    if (productQuantity === '0') return;
    if (Number(productQuantityDebounced) < minQty) {
      return;
    }
    if (Number(productQuantityDebounced) > maxQty) {
      return;
    }

    setProductQuantityAbortController.current = new window.AbortController();

    setProductQuantityInLineItem({
      variables: {
        lineItemId: productCartInfo.cartLineId,
        quantity: productQuantityDebounced,
        couponId: product.coupon.couponId,
      },
      context: {
        fetchOptions: { signal: setProductQuantityAbortController.current?.signal },
      },
      onCompleted: ({ changeCartLineQuantity }) => {
        onChangeQuantity?.({
          delta: Number(productQuantityDebounced) - Number(productQuantityInCart),
          changeQuantityEventId: changeCartLineQuantity.changeQuantityEventId ?? '',
        });
      },
      onError: () => {
        setProductQuantity(productQuantity);
      },
    });
  }, [productQuantityDebounced]);

  useUpdateEffect(() => {
    if (cartLoading && productExistsInCart) {
      setCartLoading(false);
      setTimeout(() => {
        addBtnRef.current?.focus({
          preventScroll: true,
        });
      }, 100);
    }
  }, [cartLoading, productExistsInCart]);

  const handleQuantityChange = (quantity: string) => {
    setProductQuantityAbortController.current?.abort();
    setProductQuantity(quantity);
  };

  const handleClickRemove = () => {
    setProductQuantityAbortController.current?.abort();
    setAddedProductId?.(uniqueProductId);
    setCartLoading(true);
    deleteFromCart({
      variables: {
        cartLineId: productCartInfo.cartLineId,
        inModifyMode,
      },
    }).then(() => {
      setCartLoading(false);
    });
    setProductQuantity('0');
  };

  const handleClickMinus = () => {
    setProductQuantityAbortController.current?.abort();
    setProductQuantity((Number(productQuantity) - product.quantity.quantityIncrement).toString());
  };

  const handleClickPlus = () => {
    setProductQuantityAbortController.current?.abort();
    if (isReplaced !== undefined && !isReplaced) {
      return handleUpdateCart();
    }
    setProductQuantity((Number(productQuantity) + product.quantity.quantityIncrement).toString());
  };

  const handleBlur = (quantity: string) => {
    const min = minQty;
    const max = maxQty;
    let newQuantity = quantity;

    if (newQuantity === '') {
      newQuantity = min.toString();
    } else if (Number(quantity) < min) {
      newQuantity = min.toString();
    } else if (Number(quantity) > max) {
      newQuantity = max.toString();
    }

    setProductQuantity(newQuantity);
  };

  const handleUpdateCart = (configs: SelectedConfiguration[] = [], replacedQnty?: number) => {
    const itemListName = getListName(product, gaValues?.channel);
    const itemPosition = getProductPosition(product.productId);

    const selectedSalesUnit =
      configs.find(({ name }) => name === SALES_UNIT_ID_FIELD)?.value ?? salesUnit;

    const replace = replaceCartItem({
      variables: {
        ...getAddToCartVariables({
          productId: product.productId,
          categoryId: product.categoryId,
          skuCode: product.skuCode,
          quantity: replacedQnty ?? minQty,
          configuration: getProductConfigurationVariables(configs),
          itemPosition,
          itemListName,
          salesUnit: selectedSalesUnit,
        }),
        cartLineId: lineToReplace,
      },
      onCompleted: () => {
        onReplaceCompleted?.(
          product.productId,
          cartData.nonExpressSection?.cartLines?.find(({ id }) => id === lineToReplace),
        );
        setShouldUpdate && setShouldUpdate(true);
      },
    });
    onReplace?.(replace);
  };

  const handleAddToCart = async (configs: SelectedConfiguration[] = [], replacedQnty?: number) => {
    const quantity = replacedQnty ?? (minQty || DEFAULT_QUANTITY);
    const selectedSalesUnit =
      configs.find(({ name }) => name === SALES_UNIT_ID_FIELD)?.value ?? salesUnit;

    setCartLoading(true);
    onAddToCart?.();
    await addToCart(
      {
        quantity,
        product,
        salesUnit: selectedSalesUnit,
        configuration: getProductConfigurationVariables(configs),
      },
      () => {
        setProductQuantity(quantity.toString());
      },
    );
    setCartLoading(false);
  };

  // TODO: separating event handlers and business logic
  // TODO: quantity should be an optional property in SelectedConfiguration - TUE-16491
  const handleReplaceFlow = (configs?: SelectedConfiguration[], replacedQnty?: number) => {
    if (!isReplaced) {
      return handleUpdateCart(configs, replacedQnty);
    }
    handleAddToCart(configs, replacedQnty);
  };

  const handleAdd = () => {
    handleReplaceFlow();
  };

  const shouldFireReplaceFlowForTileCustomOrder = !!onReplace;
  const handleReplaceForTileCustomOrder = (
    configs?: SelectedConfiguration[],
    replacedQnty?: number,
  ) => {
    if (!shouldFireReplaceFlowForTileCustomOrder) {
      return;
    }

    handleReplaceFlow(configs, replacedQnty);
  };

  const renderCounter = () => {
    return (
      <>
        {isCustomisableProduct ? (
          <TileCustomOrder
            className={cx(styles.custom_order, className)}
            initialValue={productCartInfo}
            product={product}
            productCount={Number(productQuantity)}
            isReorder={isReorder}
            onReplace={handleReplaceForTileCustomOrder}
            isV2={isV2}
          />
        ) : (
          <ProductTileCollapsableCounter
            className={cx(styles.quantity, className)}
            size="small"
            min={minQty}
            max={maxQty}
            buttonArialLabel={product.productName}
            step={product.quantity.quantityIncrement}
            onChange={handleQuantityChange}
            onBlur={handleBlur}
            onRemoveClick={handleClickRemove}
            onMinusClick={handleClickMinus}
            onPlusClick={handleClickPlus}
            onAdd={handleAdd}
            isCollapsedInitial={!productExistsInCart}
            value={productQuantity}
            isReorder={isReorder}
            isCollapseAnimate
            isRestricted={isRestrictedProduct(product)}
            addBtnRef={addBtnRef}
            uniqueProductId={uniqueProductId}
            addedProductId={addedProductId}
            setAddedProductId={setAddedProductId}
            lastAction={lastAction}
            setLastAction={setLastAction}
            cartLoading={cartLoading}
            setCartLoading={setCartLoading}
            isV2={isV2}
            isReplaceProduct
          />
        )}
      </>
    );
  };

  const displaySkeleton = externalLoading || loading || cartLoading || loadingDeleteFromCart;

  return (
    <ProductQuantityTooltip availability={product.availability} isV2={isV2}>
      {displaySkeleton ? (
        <Skeleton
          className={cx(styles.skeleton, className)}
          variant="circular"
          width={28}
          height={28}
        />
      ) : (
        renderCounter()
      )}
    </ProductQuantityTooltip>
  );
};
