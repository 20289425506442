import { Typography } from '@mui/material';
import cx from 'classnames';
import React, { useEffect } from 'react';
import debounce from 'lodash/debounce';
import { Product, ProductBasicPrice } from '@commons/product';
import { getAtpViewEvent } from '@modules/ga/eventBodyGetters/atpEvents';
import {
  SingleStoreCartLine as SingleStoreCartLineType,
  PriceIndicators as PriceIndicatorsType,
} from '@commons/cart';
import { warning as WarningIcon } from '@assets/icons/system';
import { alcoholRestrictions as UnavailableAlcoholIcon } from '@assets/icons/informational';
import { PriceIndicators } from '@components/PriceIndicators/PriceIndicators';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { useRemoveFromCartEvent } from '@modules/ga/hooks/useRemoveFromCart';
import { useReplaceModal } from '@context/ReplaceContext/ReplaceModalContext';
import { Grid } from '@components/UI/Grid/Grid';
import { ChosenProduct } from '@hooks/configurationModal/useConfigurationModal';
import { MODAL_TYPE } from '@components/ProductConfigurationModal/types';
import { getInitialValues } from '@components/OrderList/helpers/getInitialValues';
import { SingleStoreCartLine } from '../SingleStoreCartLine/SingleStoreCartLine';
import styles from './UnavailableCartLines.module.scss';

interface UnavailableCartLinesProps {
  title: React.ReactNode;
  subtotal?: ProductBasicPrice;
  cartLines?: SingleStoreCartLineType[];
  subtotalLabel: string | React.ReactNode;
  note?: string;
  className?: string;
  alcoholRestricted?: boolean;
  modifiedSection?: boolean;
  dataTestId?: string;
  shouldShowSubtotal?: boolean;
  priceIndicators: PriceIndicatorsType;
  isExpressOnlyCartLines?: boolean;
  openProductModal?: (productData: ChosenProduct) => void;
  isUnavailableCartlines?: boolean;
}

export const UnavailableCartLines = ({
  title,
  subtotal,
  note,
  cartLines = [],
  className,
  subtotalLabel,
  alcoholRestricted,
  modifiedSection = false,
  shouldShowSubtotal = false,
  dataTestId,
  priceIndicators,
  isExpressOnlyCartLines,
  openProductModal,
}: UnavailableCartLinesProps) => {
  const { trackRemoveFromCartExpress } = useRemoveFromCartEvent();
  const { handleReplace } = useReplaceModal();

  const handleDelete = (cartLine: SingleStoreCartLineType) => {
    trackRemoveFromCartExpress(cartLine);
    /* eslint-disable @typescript-eslint/naming-convention */
    sendGtmEvent({
      event: 'express-action',
      event_name: 'express_remove_unavailable_product_from_cart',
      ua_category: 'express shop',
      ua_action: 'express remove unavailable product from cart',
      ua_label: cartLine?.product?.productName ?? cartLine?.description ?? '',
    });
  };

  const tileIcon = (hasWineData: boolean) =>
    note || hasWineData ? (
      <UnavailableAlcoholIcon width={16} height={16} />
    ) : (
      <WarningIcon width={16} height={16} />
    );

  const testId = isExpressOnlyCartLines ? 'express-only-cart-tile' : 'express-cart-tile';

  const handleProductModal = (cartLine: SingleStoreCartLineType, modalType: MODAL_TYPE) => {
    if (openProductModal && cartLine.product) {
      openProductModal({
        cartLineId: cartLine.id,
        product: cartLine.product,
        variant: modalType,
        initialValues: getInitialValues(cartLine.confOptions, cartLine.quantitySelector, null),
      });
    }
  };

  const triggerAtpViewEvent = () => {
    const atpViewObj = {
      cartLines: cartLines
        .map((cartLine) => cartLine.product)
        .filter((product) => product !== null) as Product[],
      reason: isExpressOnlyCartLines
        ? 'only available with Express delivery'
        : 'unavailable for Express delivery',
      subtotal: subtotal?.value ?? 0,
    };
    sendGtmEvent(getAtpViewEvent(atpViewObj));
  };

  const debouncedAtpViewEvent = debounce(triggerAtpViewEvent, 100);

  useEffect(() => {
    debouncedAtpViewEvent();

    return () => {
      debouncedAtpViewEvent.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {cartLines.length > 0 && (
        <div
          data-testid={dataTestId}
          className={cx(styles.wrapper, className, {
            [styles.gray]: isExpressOnlyCartLines,
          })}
        >
          <Grid container columns={{ xs: 4, sm: 6, lg: 7 }}>
            {title && (
              <Grid item xs={4} sm={6} lg={5}>
                <div className={styles.title_wrapper}>
                  <Typography
                    component="p"
                    variant="smallBody"
                    className={cx(styles.unavailable_title)}
                  >
                    {title}
                  </Typography>
                </div>
              </Grid>
            )}
            {!!cartLines.length && (
              <Grid item xs={4} sm={6} lg={7}>
                <div
                  className={cx(styles.tiles_container, styles.unavailable, {
                    [styles.gray]: isExpressOnlyCartLines,
                  })}
                >
                  {cartLines.map((cartLine) => (
                    <SingleStoreCartLine
                      data-testid={testId}
                      key={cartLine.id}
                      cartLine={cartLine}
                      className={styles.cart_tile}
                      modifiedItem={modifiedSection}
                      unavailableIcon={tileIcon(false)}
                      onReplace={handleReplace}
                      onDelete={() => handleDelete(cartLine)}
                      alcoholRestricted={alcoholRestricted}
                      isExpressOnlyCartLine={isExpressOnlyCartLines}
                      handleProductModal={handleProductModal}
                    />
                  ))}
                </div>
              </Grid>
            )}
            {shouldShowSubtotal && (
              <Grid item xs={4} sm={6} lg={7}>
                <div className={styles.subtotal} data-testid="order-list-subtotal">
                  <Typography
                    variant="smallBody"
                    className={cx({
                      [styles.capitalize]: !title,
                    })}
                  >
                    {subtotalLabel}
                  </Typography>
                  <Typography
                    variant="body"
                    className={cx(styles.subtotal_value, {
                      [styles.green]: modifiedSection,
                    })}
                  >
                    {subtotal?.formattedPrice}
                    <PriceIndicators
                      data-testid="order-list-price-indicators"
                      values={priceIndicators}
                    />
                  </Typography>
                </div>
              </Grid>
            )}
            {note && (
              <Grid item xs={4} sm={6} lg={7}>
                <Typography variant="smallBody" className={styles.note}>
                  {note}
                </Typography>
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </>
  );
};
