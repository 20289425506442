import { memo, useMemo } from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { TileImage } from '@components/Tiles/components/TileImage/TileImage';
import { TileLabels } from '@components/Tiles/components/TileLabels/TileLabels';
import { Coupon } from '@components/UI/Coupon/Coupon';
import { Alert } from '@components/UI';
import { time as TimeIcon } from '@assets/icons/system';
import { ProductTile as IProductTile } from '@commons/product';
import { GroupScale } from '@components/GroupScale/GroupScale';
import { TileQuantity } from '@components/Tiles/components/TileQuantity/TileQuantity';
import { TilePrice } from '@components/Tiles/components/TilePrice/TilePrice';
import { TileName } from '@components/Tiles/components/TileName/TileName';
import { useLocalization } from '@hooks/useLocalization';
import { isEligibleForAddingToShoppingList } from '@utils/isEligibleForAddingToShoppingList';
import { routing } from '@constants/routing';
import { TrackViewItemList } from '@modules/ga/events/ecommerce/view-item-list/TrackViewItemList';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { ProductListLocationProvider, useProductListLocation } from '@modules/ga/context/location';
import { AddToShoppingListButton } from '@components/AddToShoppingListButton/AddToShoppingListButton';
import { TOnAddToBag } from '@modules/ga/type';
import { TileStandingOrderButton } from '@components/Tiles/components/TileStandingOrderButton/TileStandingOrderButton';
import { getBreadcrumbs } from '@modules/ga/utils/getBreadcrumbs';
import { CriteoFormatBeacon } from '@modules/criteo';
import { useProductTileAnalytics } from '@hooks/useProductTileAnalytics';
import { ReplaceProductProps } from '@components/OrderList/components/ReplaceModal/ReplaceModal';
import { GaValuesProps } from '@modules/ga/context/channel';
import { ProductTileQuantitySelector } from '../ProductTile/ProductTileQuantitySelector';
import styles from './ProductTileV2.module.scss';

export interface ProductTileProps {
  children?: never;
  className?: string;
  product: IProductTile;
  hideGroupScale?: boolean;
  isShowImage?: boolean;
  isReorder?: boolean;
  onAddToBag?: TOnAddToBag;
  onClick?: (productId?: string) => void;
  onClickCriteo?: () => void;
  nonFocusable?: boolean;
  freeItem?: boolean;
  hideShoppingListButton?: boolean;
  productCarouselType?: string;
  criteoFormatBeaconView?: CriteoFormatBeacon;
  clippedCouponId?: string;
  onClipCouponEvent?: (couponId: string) => void;
  onChange?: () => void;
  skipAddToCart?: boolean;
  isReorderItem?: boolean;
  replaceProductInfo?: ReplaceProductProps;
  gaValues?: GaValuesProps;
}

const shouldGetBreadcrumb = (pathname: string, isSponsored: boolean) => {
  return isSponsored && pathname.startsWith(routing.allSales);
};

const shouldRenderPromoCoupons = (
  hasGroupScale: boolean,
  hasCoupon: boolean,
  hasPreparationTime: boolean,
  isFocusable: boolean,
) => {
  return (hasGroupScale || hasCoupon || hasPreparationTime) && isFocusable;
};

const shouldRenderSOBtn = (isShowImage: boolean, isNotFreeItem: boolean) => {
  return isShowImage && isNotFreeItem;
};

export const ProductTileV2 = memo(
  ({
    className,
    product,
    isShowImage = true,
    hideGroupScale = false,
    isReorder,
    onClickCriteo,
    onClick,
    nonFocusable,
    freeItem = false,
    hideShoppingListButton = false,
    productCarouselType,
    criteoFormatBeaconView,
    clippedCouponId,
    onClipCouponEvent,
    onChange,
    skipAddToCart,
    isReorderItem,
    replaceProductInfo,
    gaValues,
  }: ProductTileProps) => {
    const router = useRouter();
    const { t } = useLocalization('productTile');
    const { getListName } = usePageListName();
    const { getLocation } = useProductListLocation();

    const { productRef, handleProductClick } = useProductTileAnalytics({
      product,
      onClick,
      onClickCriteo,
      criteoFormatBeaconView,
      gaValues,
    });

    const dataVariantProp = useMemo(() => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      return product.variantId ? { 'data-variant': product.variantId } : {};
    }, [product.variantId]);

    const listName = getListName(product, gaValues?.channel);
    const { unitSize, unitPrice, price, pricePerScaleUnit, roughPricePerUnit, defaultScaleUnit } =
      product;

    const hasGroupScale = useMemo(
      () => !hideGroupScale && product.groupScale?.grpId,
      [hideGroupScale, product.groupScale],
    );

    const hasCoupon = useMemo(() => Boolean(product.coupon?.couponId), [product.coupon]);

    const hasPromoCouponBlock = shouldRenderPromoCoupons(
      !!hasGroupScale,
      hasCoupon,
      product.preparationTime > 0,
      !nonFocusable,
    );

    const getProductLocation = () => {
      const breadcrumb = 'cat_' + getBreadcrumbs().split('/').join('');
      return shouldGetBreadcrumb(router.asPath, product.marketingTags.sponsored)
        ? breadcrumb
        : getLocation();
    };

    return (
      <ProductListLocationProvider location={getProductLocation()}>
        <TrackViewItemList productRef={productRef} product={product} listName={listName} />
        <div
          className={`product_tile_card ${cx(styles.product_card_wrapper, className)}`}
          ref={productRef}
          data-ga-tile-list={getListName(product, gaValues?.channel)}
          data-ga-tile-list-type="product-tile"
          data-ga-tile-id={product.productId}
          data-testid={`product-tile-${product.productId}`}
          {...dataVariantProp}
        >
          <div className={styles.product_card_image_wrapper}>
            {product.productImage.ref && isShowImage && (
              <TileImage
                img={{
                  ref: product.productImage.ref,
                  alt: product.productImage.alt ?? t('ariaLabels.tileImage'),
                }}
                productUrl={product.productPageUrl}
                hasWineData={product?.hasWineData}
                onProductClick={handleProductClick}
                expressEligible={product.featureTags.expressEligible}
                peakQuality={product.featureTags.topPick}
                isV2
                gaValues={gaValues}
              />
            )}

            <ProductTileQuantitySelector
              className={styles.product_card_quantityselector}
              product={product}
              productCarouselType={productCarouselType}
              isReorder={isReorder}
              nonFocusable={nonFocusable}
              freeItem={freeItem}
              onChange={onChange}
              skipAddToCart={skipAddToCart}
              isReorderItem={isReorderItem}
              isV2
              gaValues={gaValues}
              replaceProductInfo={replaceProductInfo}
            />

            <AddToShoppingListButton
              product={product}
              classNames={styles.product_card_shoppling_list}
              shouldRenderAddToShoppingListButton={
                isEligibleForAddingToShoppingList(product) && !hideShoppingListButton
              }
              nonFocusable={nonFocusable}
              gaValues={gaValues}
            />
          </div>

          <TileName
            name={product.productName}
            brand={product.brandName}
            url={product.productPageUrl}
            onProductClick={handleProductClick}
            nonFocusable={nonFocusable}
            isV2
            id={product.productId}
            gaValues={gaValues}
          />

          <TilePrice
            formattedCurrentPrice={product.formattedCurrentPrice}
            currentPrice={product.price}
            scaleUnit={product.scaleUnit}
            prevPrice={product?.wasPrice}
            minValueToOrder={product.quantity.minQuantity}
            savingString={product.savingString}
            discount={product.deal}
            freeItem={freeItem}
            isV2
          />

          <TileQuantity
            unitPrice={unitPrice}
            unitSize={unitSize}
            currency={price.currency}
            roughPricePerUnit={roughPricePerUnit}
            defaultScaleUnit={defaultScaleUnit}
            pricePerScaleUnit={pricePerScaleUnit}
            isV2
          />

          {hasPromoCouponBlock && (
            <div
              className={styles.product_card_promo_cntr}
              data-testid={t('ariaLabels.productInfo')}
              data-product-id={product.productId}
            >
              {hasGroupScale && (
                <div className={styles.product_card_promo} aria-label={t('ariaLabels.groupScales')}>
                  <GroupScale
                    {...product.groupScale}
                    grpPrices={product.grpPrices}
                    id={product.productId}
                    isV2
                  />
                </div>
              )}

              {hasCoupon && (
                <div
                  className={styles.product_card_coupon}
                  aria-label={t('ariaLabels.groupScales')}
                >
                  <Coupon
                    productId={product.productId}
                    title={product.coupon.description}
                    id={product.coupon.couponId}
                    isActive={product.coupon.isActive}
                    prompt={product.coupon.detailedDescription}
                    expirationDate={product.coupon.expirationDate}
                    clippedCouponId={clippedCouponId}
                    onClipCouponEvent={onClipCouponEvent}
                    fullWidth
                    isV2
                  />
                </div>
              )}

              <TileLabels preparationTime={product.preparationTime} isV2 />
            </div>
          )}

          {product.availability?.length > 0 && (
            <Alert type="warning" hasShowIcon={false} isFullWidth className={styles.alert}>
              <div className={styles.wrapper}>
                <TimeIcon className={styles.icon} />
                {product.availability?.map((warning, index) => (
                  <div key={index}>{warning}</div>
                ))}
              </div>
            </Alert>
          )}

          <TileLabels marketingTags={product.marketingTags} isV2 />

          <TileStandingOrderButton
            product={product}
            className={styles.standing_order_btn}
            shouldRenderSOBtn={shouldRenderSOBtn(isShowImage, !freeItem)}
            isV2
          />
        </div>
      </ProductListLocationProvider>
    );
  },
);

ProductTileV2.displayName = 'ProductTileV2';
