import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';
import cx from 'classnames';
import { Typography } from '@mui/material';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import {
  Product,
  ProductTile,
  ProductTileVariations,
  SelectedConfiguration,
} from '@commons/product';
import { Button, DropDown } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { InitialValues } from '@hooks/cart/useProductInfoFromCart';
import { VariationGroup } from '@api';
import {
  prepareSalesUnits,
  prepareVariations,
  prepareBundleVariations,
} from '@components/ProductConfigurationModal/utils';
import { SALES_UNIT_ID_FIELD } from '@constants/salesUnits';
import { PreparedDropdown } from '../../types';
import styles from './ConfigurationDropdowns.module.scss';
import { BundleDropdowns } from './BundleDropdowns';

interface OnChangeProps {
  salesUnit?: string;
  configs?: SelectedConfiguration[];
}

interface ConfigurationDropdownsProps {
  children?: never;
  variations: Product['variations'] | ProductTile['variations'];
  bundleVariations?: VariationGroup[];
  salesUnits: Product['salesUnits'];
  allowTwoItemsInLine?: boolean;
  initialValues?: InitialValues;
  hideComponent?: boolean;
  isBundle?: boolean;
  onChange: (props: OnChangeProps) => void;
  onMoreInfoClick?: (link: string) => void;
  onShowVariationInfo?: (id: string) => void;
}

export type ValidateHandle = {
  validate: () => void;
};

const ConfigurationDropdownsComponent: ForwardRefRenderFunction<
  ValidateHandle,
  ConfigurationDropdownsProps
> = (
  {
    variations,
    bundleVariations,
    salesUnits,
    onChange,
    initialValues,
    allowTwoItemsInLine,
    onMoreInfoClick,
    hideComponent,
    isBundle,
    onShowVariationInfo,
  }: ConfigurationDropdownsProps,
  ref,
) => {
  const configurationDropdownsRef = useRef<HTMLDivElement>(null);
  const { t } = useLocalization('product');
  const [currentConfigurations, setCurrentConfigurations] = useState<OnChangeProps>({
    configs: initialValues?.variables ?? [],
    salesUnit: initialValues?.salesUnit ?? '',
  });
  const [preparedDropdown, setPreparedDropdown] = useState<PreparedDropdown[]>([]);

  const validate = () => {
    let isValidatedSuccessfully = true;
    if (preparedDropdown.length) {
      setPreparedDropdown(
        preparedDropdown.map((dropdown) => {
          if (!dropdown.optional && !dropdown.selectedValue) {
            isValidatedSuccessfully = false;
            return { ...dropdown, hasError: true };
          }
          return dropdown;
        }),
      );
    }
    return isValidatedSuccessfully;
  };

  useImperativeHandle(ref, () => ({
    validate,
  }));

  useEffect(() => {
    let index;
    const firstErroredEl: PreparedDropdown | undefined = preparedDropdown.find((dropdown, i) => {
      index = i;
      return dropdown.hasError;
    });
    if (firstErroredEl) {
      const el = configurationDropdownsRef?.current?.querySelector<HTMLSelectElement>(
        `#select-${firstErroredEl.name}_${index}`,
      );
      el?.focus();
    }
  }, [preparedDropdown]);

  useEffect(() => {
    if (isBundle) {
      if (isEmpty(initialValues)) {
        setPreparedDropdown(prepareBundleVariations(bundleVariations));
      } else {
        const preparedBundleVariations = prepareBundleVariations(
          bundleVariations,
          initialValues?.variables,
        ) as unknown as ProductTileVariations[];
        setPreparedDropdown(prepareVariations(preparedBundleVariations, initialValues?.variables));
      }
    } else {
      const preparedVariations = prepareVariations(variations, initialValues?.variables);
      const preparedSalesUnits = prepareSalesUnits(
        salesUnits,
        SALES_UNIT_ID_FIELD,
        initialValues?.salesUnit,
      );
      const preparedResult = [];

      if (preparedSalesUnits) {
        preparedResult.push(preparedSalesUnits);
      }
      if (preparedVariations.length) {
        preparedResult.push(...preparedVariations);
      }
      setPreparedDropdown(preparedResult);
    }
  }, [
    initialValues,
    initialValues?.salesUnit,
    initialValues?.variables,
    salesUnits,
    variations,
    bundleVariations,
    isBundle,
  ]);

  const getConfigurations = (dropdowns: PreparedDropdown[]) => {
    const salesUnitId = dropdowns.find(({ name }) => name === SALES_UNIT_ID_FIELD)?.selectedValue;

    let configurationValues: Array<{ name: string; value: string }> = [];

    if (isBundle) {
      configurationValues = dropdowns
        .filter(({ name, selectedValue }) => name && selectedValue)
        .map(({ name, selectedValue }) => ({
          name: name as string,
          value: selectedValue as string,
        }));
    } else {
      configurationValues = dropdowns
        .filter(({ name, selectedValue }) => name && selectedValue && name !== SALES_UNIT_ID_FIELD)
        .map(({ name, selectedValue }) => ({
          name: name as string,
          value: selectedValue as string,
        }));
    }

    const newConfigurations: OnChangeProps = {};
    if (salesUnitId) {
      newConfigurations.salesUnit = salesUnitId;
    }
    if (configurationValues.length) {
      newConfigurations.configs = configurationValues;
    }
    if (!isEmpty(newConfigurations) && !isEqual(newConfigurations, currentConfigurations)) {
      setCurrentConfigurations(newConfigurations);
    }
    return newConfigurations;
  };

  const dropdownChangeHandler = (newValue: string, arrIndex: number) => {
    const newPreparedDropdown = preparedDropdown.map((prevValue, index) => {
      if (arrIndex === index) {
        return {
          ...prevValue,
          selectedValue: newValue,
          hasError: false,
          isOpen: isBundle ? false : undefined,
        };
      }
      return prevValue;
    });
    setPreparedDropdown(newPreparedDropdown);
    onChange(getConfigurations(newPreparedDropdown));
  };

  const checkIsShort = (index: number, arrLength: number) => {
    return allowTwoItemsInLine ? (!(index % 2) && arrLength - 1 !== index) || !!(index % 2) : false;
  };

  return (
    <div ref={configurationDropdownsRef}>
      {isBundle ? (
        <BundleDropdowns
          hideComponent={!!hideComponent}
          preparedDropdown={preparedDropdown}
          onMoreInfoClick={onMoreInfoClick}
          onShowVariationInfo={onShowVariationInfo}
          checkIsShort={checkIsShort}
          dropdownChangeHandler={dropdownChangeHandler}
        />
      ) : (
        <div className={styles.configurations} data-testid="configuration dropdowns">
          {preparedDropdown.map((variation, index) => {
            if (variation.values.length === 1 && !variation.label) return null;
            if (variation.values.length === 1) {
              return (
                <div
                  key={`${variation.name}_${index}`}
                  data-testid="string line"
                  className={cx(styles.configurations_string_item, {
                    [styles.halfLine]: checkIsShort(index, preparedDropdown.length),
                  })}
                >
                  {variation.label}: {variation.values[0].label}
                </div>
              );
            }

            const hasMoreLink = onMoreInfoClick && variation.descrMedia;

            return (
              <div
                className={cx(styles.configurations_dropdown_wrapper, {
                  [styles.halfLine]: checkIsShort(index, preparedDropdown.length),
                })}
                key={`${variation.name}_${index}`}
              >
                <DropDown
                  className={styles.configurations_dropdown}
                  aria-label={t('configurationModal.ariaLabels.select')}
                  value={variation.selectedValue || ''}
                  onChange={(value) => {
                    dropdownChangeHandler(value, index);
                  }}
                  options={
                    variation.values
                      .filter(({ name, label }) => !!name && !!label)
                      .map(({ name, label, cvp }) => ({
                        value: name,
                        label: Number(cvp?.replace(/\D/g, '')) > 0 ? `${label}  -  ${cvp}` : label,
                      })) as Array<{
                      value: string;
                      label: string;
                    }>
                  }
                  title={variation.label ?? t('configurationModal.defaultLabel')}
                  error={variation.hasError ? t('configurationModal.errors.required') : ''}
                  id={`${variation.name}_${index}`}
                />
                {hasMoreLink && (
                  <div
                    className={cx(styles.more_info, {
                      [styles.more_info_without_label]: !variation.underLabel,
                    })}
                    aria-label={t('configurationModal.ariaLabels.moreMessage')}
                  >
                    <Typography component="p" variant="body">
                      <span id="variation_supportText">{`${variation.underLabel} `}</span>
                      <Button
                        aria-label={t('configurationModal.ariaLabels.moreLink')}
                        className={styles.more_info_button}
                        variant="underline"
                        onClick={() => {
                          onMoreInfoClick(variation.descrMedia as string);
                        }}
                        id="variation_moreText"
                        aria-labelledby="variation_supportText variation_moreText"
                      >
                        {t('configurationModal.moreLink')}
                      </Button>
                    </Typography>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const ConfigurationDropdowns = forwardRef(ConfigurationDropdownsComponent);
