import { Divider, Typography } from '@mui/material';
import cx from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { getAtpViewEvent } from '@modules/ga/eventBodyGetters/atpEvents';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { Product } from '@commons/product';
import { CartLine } from '@commons/cart';
import { isMealBundle } from '@utils/productHelper';
import { CartTile } from '@components/Tiles/CartTile/CartTile';
import { MODAL_TYPE } from '@components/ProductConfigurationModal/types';
import { useReplaceModal } from '@context/ReplaceContext/ReplaceModalContext';
import { OUT_OF_STOCK, UNAVAILABLE } from '@constants/cart';
import { getInitialValues } from './helpers/getInitialValues';
import { OrderListBaseProps } from './types';
import { Subtotal } from './components/Subtotal/Subtotal';
import { Title } from './components/Title/Title';
import { TileIcon } from './components/TileIcon/TileIcon';
import styles from './OrderList.module.scss';

export const OrderList = ({
  title,
  subtotal,
  note,
  cartLines,
  className,
  orderListClassName,
  openProductModal,
  isUnavailable,
  isOutOfStock,
  subtotalLabel,
  alcoholRestricted,
  cartTotalPriceIndicators,
  modifiedSection = false,
  shouldShowSubtotal = false,
  dataTestId,
  renderAdditionalControlsForLineItem,
  inModifyMode,
  isFilledBag,
}: OrderListBaseProps) => {
  const { handleReplace: onReplace } = useReplaceModal();
  const orderListRef = useRef(null);
  const [deletedItemIndex, setDeletedItemIndex] = useState(-1);

  useEffect(() => {
    if (deletedItemIndex === cartLines.length) {
      const emptyBagBtn = document.getElementById('empty-bag-button');
      emptyBagBtn?.focus();
    }
  }, [deletedItemIndex]);

  const handleProductModal = (cartLine: CartLine, modalType: MODAL_TYPE) => {
    if (openProductModal) {
      openProductModal({
        cartLineId: cartLine.id,
        product: cartLine.product,
        variant: modalType,
        initialValues: getInitialValues(
          cartLine.confOptions,
          cartLine.quantitySelector,
          cartLine.selectedSalesUnit,
        ),
      });
    }
  };

  const handleDeleteEvent = (index: number) => {
    setDeletedItemIndex(index);
  };

  const modifiedAmount = cartLines.reduce((sum, { quantitySelector }) => {
    return sum + quantitySelector.quantity;
  }, 0);

  const isUnavailableSection = !!(isUnavailable || isOutOfStock);

  const triggerUnavailableAtpEvent = useCallback(() => {
    const unavailableEventObj = {
      cartLines: cartLines
        ?.map((cartLine) => cartLine?.product)
        .filter((product) => product !== null) as Product[],
      reason: UNAVAILABLE,
      subtotal: subtotal?.value,
    };
    sendGtmEvent(getAtpViewEvent(unavailableEventObj));
  }, [cartLines, subtotal]);

  const triggerOutOfStockAtpEvent = useCallback(() => {
    const outOfStockEventObj = {
      cartLines: cartLines
        ?.map((cartLine) => cartLine?.product)
        .filter((product) => product !== null) as Product[],
      reason: OUT_OF_STOCK,
      subtotal: subtotal?.value,
    };
    sendGtmEvent(getAtpViewEvent(outOfStockEventObj));
  }, [cartLines, subtotal]);

  const debouncedUnavailableEvent = debounce(triggerUnavailableAtpEvent, 100);
  const debouncedOutOfStockEvent = debounce(triggerOutOfStockAtpEvent, 100);

  useEffect(() => {
    if (isUnavailable && cartLines?.length) {
      debouncedUnavailableEvent();
    }

    return () => {
      debouncedUnavailableEvent.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOutOfStock && cartLines?.length) {
      debouncedOutOfStockEvent();
    }

    return () => {
      debouncedOutOfStockEvent.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      data-testid={dataTestId}
      className={cx(
        styles.wrapper,
        className,
        { [styles.unavailable_section]: isUnavailableSection },
        { [styles.gray]: isOutOfStock },
      )}
    >
      {title && (
        <div className={styles.title_wrapper}>
          <Title
            showTitle={!isUnavailable}
            isUnavailableSection={isUnavailableSection}
            title={title}
          />
        </div>
      )}
      {!!cartLines.length && (
        <div
          ref={orderListRef}
          className={cx(styles.tiles_container, {
            [styles.unavailable]: isUnavailable,
            [styles.out_of_stock]: isOutOfStock,
          })}
        >
          {cartLines.map((tile, index) => {
            const atpLine = isUnavailable || isOutOfStock ? tile.atpLine : undefined;
            const isBundle = isMealBundle(tile.product);
            const showDepartmentLabel =
              !modifiedSection &&
              !atpLine &&
              (!index ||
                (index > 0 && tile?.departmentLabel !== cartLines[index - 1].departmentLabel));
            return (
              <>
                {showDepartmentLabel && (
                  <div className={styles.department_title_wrapper}>
                    <Title
                      showTitle={true}
                      isUnavailableSection={false}
                      title={tile.departmentLabel}
                      departmentTitle={true}
                    />
                  </div>
                )}
                <CartTile
                  carTileIndex={index}
                  deletedItemIndex={deletedItemIndex}
                  handleDeleteEvent={handleDeleteEvent}
                  data-testid="cart-tile"
                  sampleProduct={tile.freeSample}
                  key={tile.id}
                  cartLineId={tile.id}
                  product={tile?.product}
                  discount={tile?.discount}
                  coupon={tile?.coupon}
                  quantitySelector={tile?.quantitySelector}
                  priceIndicators={tile?.priceIndicators}
                  currentPrice={tile?.displayPrice}
                  previousPrice={tile?.unscaledPrice}
                  variants={tile?.confOptions}
                  configurationDescription={tile?.configurationDescription}
                  className={styles.cart_tile}
                  cartTileClassName={orderListClassName}
                  handleProductModal={() => handleProductModal(tile, MODAL_TYPE.CART_MINI_PDP)}
                  handleEditModal={() => handleProductModal(tile, MODAL_TYPE.CART_EDIT)}
                  isUnavailable={isUnavailable}
                  isOutOfStock={isOutOfStock}
                  modifiedItem={modifiedSection}
                  unavailableIcon={
                    <TileIcon hasWineData={tile?.product?.hasWineData} note={note} />
                  }
                  atpLine={atpLine}
                  promoApplied={tile?.promotionApplied}
                  promoDescription={tile?.promotionDescription}
                  onReplace={onReplace}
                  alcoholRestricted={alcoholRestricted}
                  isBundle={isBundle}
                  selectedSalesUnitLabel={tile.selectedSalesUnit}
                  inModifyMode={inModifyMode}
                  isFilledBag={isFilledBag}
                >
                  {renderAdditionalControlsForLineItem &&
                    renderAdditionalControlsForLineItem(tile, isUnavailable)}
                </CartTile>
              </>
            );
          })}
        </div>
      )}

      {shouldShowSubtotal && (
        <div className={styles.subtotal} data-testid="order-list-subtotal">
          <Subtotal
            subtotalLabel={subtotalLabel}
            isModifiedSection={modifiedSection}
            modifiedAmount={modifiedAmount}
            subtotal={subtotal}
            cartTotalPriceIndicators={cartTotalPriceIndicators}
            addMarginBeforeValue={isUnavailableSection}
          />
        </div>
      )}

      {note && (
        <Typography variant="smallBody" component="p" className={styles.note}>
          {note}
        </Typography>
      )}

      <Divider className={styles.bottom_divider} />
    </div>
  );
};
